import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";
import CPFImmSook from 'assets/fonts/CPF_Imm_Sook_Regular.ttf';
import Run_while from 'assets/images/run_while.png';
import Run from 'assets/images/runner.png';
import Pin_light from 'assets/images/pin_light.png'
import Pin from 'assets/images/pin.png';

// color design tokens export
export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
        grey: {
          100: "#c2c2c2",
          200: "#5e5e5d",
        },
        while: {
          100: "#000000"
        },
        black: {
            100: "#ffffff"
        },
        icon: {
            100: Run_while,
            200: Pin_light
        },
      }
    : {
        grey: {
          100: "#5e5e5d",
          200: "#f5f5f5",
        },
        while: {
            100: "#fcfcfc"
        },
        black: {
            100: "#000000"
        },
        icon: {
            100: Run,
            200: Pin
        },
      }),
});

// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? { 
            background: {
              default: colors.while[100],
            },
          }
        : {
            // palette values for light mode
            background: {
              default: "#fcfcfc",
            },
          }),
    },
    typography: {
        fontFamily: "CPF_Imm_Sook"
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: {
            "@font-face": {
              fontFamily: "CPF_Imm_Sook",
              src: `url(${CPFImmSook}) format("truetype")`
            },
            body: {
              fontSize: "3rem",
              color: "purple"
            }
          }
        }
      }
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState("dark");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};
