import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Helmet } from "react-helmet";
import db from "firebase-config";
import Check from '../assets/images/check.png';
import No from '../assets/images/no.png';
import LensIcon from '@mui/icons-material/Lens';
import { useNavigate } from 'react-router-dom';

export default function NotiGiveawayFunction() {
    const navigate = useNavigate();
    const [check_list, setCheck_list] = useState([]);

    setTimeout(() => {
        navigate('/giveaway/p1');
    }, 30000);

    useEffect(() => {
        const getPostFromFirebase = [];
        const subscriber = db
            .collection("meskr_giveaway_tag_list")
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    getPostFromFirebase.push({
                        ...doc.data(),
                        id: doc.id,
                    });
                });

                setCheck_list(getPostFromFirebase);

                let permiss = getPostFromFirebase.find((user) => user.TAG.includes("_AdvancedTags.4117.GiveAway.Interlock.Permissive_1"));
                sessionStorage.setItem('permissive', permiss?.VALUE);
                let cloud = getPostFromFirebase.find((user) => user.TAG.includes("Simulator.4117.GiveAway.Multihead1.Line7_1.PI_Cloud_Enable"));
                sessionStorage.setItem('cloud_enable', cloud?.VALUE);
                let error = getPostFromFirebase.find((user) => user.TAG.includes("Further.4117.Multihead.Multihead1.Line7_1.0NoError"));
                sessionStorage.setItem('no_error', error?.VALUE);
                let infeed = getPostFromFirebase.find((user) => user.TAG.includes("Simulator.4117.GiveAway.Multihead1.Line7_1.PI_InfeedControl"));
                sessionStorage.setItem('infeed_control', infeed?.VALUE);
                let df = getPostFromFirebase.find((user) => user.TAG.includes("Simulator.4117.GiveAway.Multihead1.Line7_1.PI_DFWeight"));
                sessionStorage.setItem('df_weight', df?.VALUE);
                let target = getPostFromFirebase.find((user) => user.TAG.includes("Simulator.4117.GiveAway.Multihead1.Line7_1.PI_TargetWeight"));
                sessionStorage.setItem('target_weight', target?.VALUE);
                let upper = getPostFromFirebase.find((user) => user.TAG.includes("Simulator.4117.GiveAway.Multihead1.Line7_1.PI_UpperWeight"));
                sessionStorage.setItem('upper_weight', upper?.VALUE);
                let lower = getPostFromFirebase.find((user) => user.TAG.includes("Simulator.4117.GiveAway.Multihead1.Line7_1.PI_LowerWeight"));
                sessionStorage.setItem('lower_weight', lower?.VALUE);
                let sp = getPostFromFirebase.find((user) => user.TAG.includes("Simulator.4117.GiveAway.Multihead1.Line7_1.PI_Speed"));
                sessionStorage.setItem('speed', sp?.VALUE);
                let error_cw = getPostFromFirebase.find((user) => user.TAG.includes("Simulator.4117.ISHIDA.CheckWeight.Line7.0NoError"));
                sessionStorage.setItem('no_error_check_weight', error_cw?.VALUE);
                let ov_reject = getPostFromFirebase.find((user) => user.TAG.includes("Simulator.4117.GiveAway.Multihead1.Line7_1.PI_OverReject"));
                sessionStorage.setItem('over_reject', ov_reject?.VALUE);
                let pd_name = getPostFromFirebase.find((user) => user.TAG.includes("Simulator.4117.GiveAway.Multihead1.Line7_1.PI_ProductName"));
                sessionStorage.setItem('product_name', pd_name?.VALUE);
                let up_weigth_compare = getPostFromFirebase.find((user) => user.TAG.includes("Simulator.4117.GiveAway.Multihead1.Line7_1.PI_UpperWeight_Compare"));
                sessionStorage.setItem('upper_weight_compare', up_weigth_compare?.VALUE);
            });

        // return cleanup function
        return () => subscriber();
    }, [check_list]);


    const cloud_enable = check_list.find((user) => user.TAG.includes("Simulator.4117.GiveAway.Multihead1.Line7_1.PI_Cloud_Enable"));

    const no_error = check_list.find((user) => user.TAG.includes("Further.4117.Multihead.Multihead1.Line7_1.0NoError"));

    const infeed_control = check_list.find((user) => user.TAG.includes("Simulator.4117.GiveAway.Multihead1.Line7_1.PI_InfeedControl"));

    const df_weight = check_list.find((user) => user.TAG.includes("Simulator.4117.GiveAway.Multihead1.Line7_1.PI_DFWeight"));

    const target_weight = check_list.find((user) => user.TAG.includes("Simulator.4117.GiveAway.Multihead1.Line7_1.PI_TargetWeight"));

    const upper_weight = check_list.find((user) => user.TAG.includes("Simulator.4117.GiveAway.Multihead1.Line7_1.PI_UpperWeight"));

    const lower_weight = check_list.find((user) => user.TAG.includes("Simulator.4117.GiveAway.Multihead1.Line7_1.PI_LowerWeight"));

    const speed = check_list.find((user) => user.TAG.includes("Simulator.4117.GiveAway.Multihead1.Line7_1.PI_Speed"));

    const no_error_check_weight = check_list.find((user) => user.TAG.includes("Simulator.4117.ISHIDA.CheckWeight.Line7.0NoError"));

    const over_reject = check_list.find((user) => user.TAG.includes("Simulator.4117.GiveAway.Multihead1.Line7_1.PI_OverReject"));

    const product_name = check_list.find((user) => user.TAG.includes("Simulator.4117.GiveAway.Multihead1.Line7_1.PI_ProductName"));

    const upper_weight_compare = check_list.find((user) => user.TAG.includes("Simulator.4117.GiveAway.Multihead1.Line7_1.PI_UpperWeight_Compare"));

    const permissive = check_list.find((user) => user.TAG.includes("_AdvancedTags.4117.GiveAway.Interlock.Permissive_1"));

    //console.log(sessionStorage.getItem('permissive') == 'true')





    return (
        <>

            <Helmet>
                <title>Status Giveaway Function| MES Korat Fur2</title>
            </Helmet>

            <Grid
                container spacing={1}
                direction="row"
                alignItems="top"
                justifyContent="center"
                style={{ minHeight: '78vh', maxHeight: '200vh', flexGrow: 1, paddingLeft: 55, paddingTop: 10 }}
            >
                {/* Top row */}
                <Grid container sx={{ pt: 0 }} spacing={1} style={{ position: "relative", left: "0vw", top: "-1vw" }}>
                    <Grid item lg={11.5} md={11.5} sm={11.5} xl={11.5} xs={11.5} style={{ position: "relative", top: '0vw', left: '0vw', height: '1px' }}>

                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                            {(permissive?.VALUE == undefined ? sessionStorage.getItem('permissive') == 'true' : permissive?.VALUE) == true ? <img src={Check} height="25px" /> : <img src={No} height="25px" />}
                            <Typography style={{ color: (permissive?.VALUE == undefined ? sessionStorage.getItem('permissive') == 'true' : permissive?.VALUE) == true ? '#2e9281' : '#8c0e0e', fontWeight: "bold", fontSize: 30, position: "relative", top: '0.5vw' }} textAlign="center">
                                &nbsp;{(permissive?.VALUE == undefined ? sessionStorage.getItem('permissive') == 'true' : permissive?.VALUE) == true ? 'สถานะพร้อมผลิต' : 'สถานะไม่พร้อมผลิต'}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>


                <Grid container sx={{ pt: 0 }} spacing={1} style={{ position: "relative", left: "-0.75vw", top: "6vw" }}>
                    <Grid item lg={11.54} md={11.54} sm={11.54} xl={11.54} xs={11.54} style={{ position: "relative", top: '0vw', left: '0vw', border: "1px solid #cfcfcf", borderRadius: 6, height: '70%' }}>
                        <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="center" >
                            <LensIcon fontSize="small" stroke={(cloud_enable?.VALUE == undefined ? sessionStorage.getItem('cloud_enable') == 'true' : cloud_enable?.VALUE) == true ? '#00d084' : '#9e9e9e'} strokeWidth="2" sx={{ color: (cloud_enable?.VALUE == undefined ? sessionStorage.getItem('cloud_enable') == 'true' : cloud_enable?.VALUE) == true ? '#00d084' : '#9e9e9e' }} style={{ position: "relative", top: "-0.5vw", left: '1vw' }}></LensIcon>
                            <Typography style={{ color: (cloud_enable?.VALUE == undefined ? sessionStorage.getItem('cloud_enable') == 'true' : cloud_enable?.VALUE) == true ? '#2e9281' : '#9e9e9e', fontWeight: "bold", fontSize: 20, position: "relative", top: "-1vw", left: '1vw' }} textAlign="left">
                                &nbsp;Enable control giveaway function
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container sx={{ pt: 0 }} spacing={2} style={{ position: "relative", left: "0vw", top: "7vw" }}>
                    <Grid item lg={5.75} md={5.75} sm={5.75} xl={5.75} xs={5.75} style={{ position: "relative", top: '0vw', left: '0vw', padding: 3 }}>
                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ height: '125%', border: "1px solid #cfcfcf", borderRadius: 6 }}>
                            <Grid container spacing={2} >
                                <Grid id="top-row" container xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '2vw' }}>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '0vw' }}>
                                        <Typography style={{ color: '#ffffff', fontSize: 22, position: "relative", top: "0vw", fontWeight: 'bold' }} >
                                            Multihead
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid id="top-row" container xs={6} md={6} lg={6} xl={6} textAlign="center" style={{ position: "relative", top: '2vw', height: '60px', left: '5vw' }}>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '0vw' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="center" >
                                            <LensIcon fontSize="small" stroke={(no_error?.VALUE == undefined ? sessionStorage.getItem('no_error') == 'true' : no_error?.VALUE) == true ? '#00d084' : '#9e9e9e'} strokeWidth="2" sx={{ color: (no_error?.VALUE == undefined ? sessionStorage.getItem('no_error') == 'true' : no_error?.VALUE) == true ? '#00d084' : '#9e9e9e' }} style={{ position: "relative", top: "-0.5vw" }}></LensIcon>
                                            <Typography style={{ color: (no_error?.VALUE == undefined ? sessionStorage.getItem('no_error') == 'true' : no_error?.VALUE) == true ? '#ffffff' : '#9e9e9e', fontWeight: "bold", fontSize: 20, position: "relative", top: "-1vw" }} textAlign="left">
                                                &nbsp;เชื่อมต่อ LAN
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '0vw' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="center" >
                                            <LensIcon fontSize="small" stroke={(infeed_control?.VALUE == undefined ? sessionStorage.getItem('infeed_control') == 'true' : infeed_control?.VALUE) == true ? '#00d084' : '#9e9e9e'} strokeWidth="2" sx={{ color: (infeed_control?.VALUE == undefined ? sessionStorage.getItem('infeed_control') == 'true' : infeed_control?.VALUE) == true ? '#00d084' : '#9e9e9e' }} style={{ position: "relative", top: "-0.5vw" }}></LensIcon>
                                            <Typography style={{ color: (infeed_control?.VALUE == undefined ? sessionStorage.getItem('infeed_control') == 'true' : infeed_control?.VALUE) == true ? '#ffffff' : '#9e9e9e', fontWeight: "bold", fontSize: 20, position: "relative", top: "-1vw" }} textAlign="left">
                                                &nbsp;Infeed Control
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '0vw' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="center" >
                                            <LensIcon fontSize="small" stroke={(df_weight?.VALUE == undefined ? sessionStorage.getItem('df_weight') == 'true' : df_weight?.VALUE) == true ? '#00d084' : '#9e9e9e'} strokeWidth="2" sx={{ color: (df_weight?.VALUE == undefined ? sessionStorage.getItem('df_weight') == 'true' : df_weight?.VALUE) == true ? '#00d084' : '#9e9e9e' }} style={{ position: "relative", top: "-0.5vw" }}></LensIcon>
                                            <Typography style={{ color: (df_weight?.VALUE == undefined ? sessionStorage.getItem('df_weight') == 'true' : df_weight?.VALUE) == true ? '#ffffff' : '#9e9e9e', fontWeight: "bold", fontSize: 20, position: "relative", top: "-1vw" }} textAlign="left">
                                                &nbsp;น้ำหนักจาน DF
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '0vw' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="center" >
                                            <LensIcon fontSize="small" stroke={(target_weight?.VALUE == undefined ? sessionStorage.getItem('target_weight') == 'true' : target_weight?.VALUE) == true ? '#00d084' : '#9e9e9e'} strokeWidth="2" sx={{ color: (target_weight?.VALUE == undefined ? sessionStorage.getItem('target_weight') == 'true' : target_weight?.VALUE) == true ? '#00d084' : '#9e9e9e' }} style={{ position: "relative", top: "-0.5vw" }}></LensIcon>
                                            <Typography style={{ color: (target_weight?.VALUE == undefined ? sessionStorage.getItem('target_weight') == 'true' : target_weight?.VALUE) == true ? '#ffffff' : '#9e9e9e', fontWeight: "bold", fontSize: 20, position: "relative", top: "-1vw" }} textAlign="left">
                                                &nbsp;น้ำหนักเป้าหมาย
                                            </Typography>
                                        </Box>
                                    </Grid>

                                </Grid>

                                <Grid id="top-row" container xs={6} md={6} lg={6} xl={6} textAlign="center" style={{ position: "relative", top: '2.2vw', left: '4vw' }}>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '0vw' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="center" >
                                            <LensIcon fontSize="small" stroke={(upper_weight?.VALUE == undefined ? sessionStorage.getItem('upper_weight') == 'true' : upper_weight?.VALUE) == true ? '#00d084' : '#9e9e9e'} strokeWidth="2" sx={{ color: (upper_weight?.VALUE == undefined ? sessionStorage.getItem('upper_weight') == 'true' : upper_weight?.VALUE) == true ? '#00d084' : '#9e9e9e' }} style={{ position: "relative", top: "-0.5vw" }}></LensIcon>
                                            <Typography style={{ color: (upper_weight?.VALUE == undefined ? sessionStorage.getItem('upper_weight') == 'true' : upper_weight?.VALUE) == true ? '#ffffff' : '#9e9e9e', fontWeight: "bold", fontSize: 20, position: "relative", top: "-1vw" }} textAlign="left">
                                                &nbsp;น้ำหนักขอบบน
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '0vw' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="center" >
                                            <LensIcon fontSize="small" stroke={(lower_weight?.VALUE == undefined ? sessionStorage.getItem('lower_weight') == 'true' : lower_weight?.VALUE) == true ? '#00d084' : '#9e9e9e'} strokeWidth="2" sx={{ color: (lower_weight?.VALUE == undefined ? sessionStorage.getItem('lower_weight') == 'true' : lower_weight?.VALUE) == true ? '#00d084' : '#9e9e9e' }} style={{ position: "relative", top: "-0.5vw" }}></LensIcon>
                                            <Typography style={{ color: (lower_weight?.VALUE == undefined ? sessionStorage.getItem('lower_weight') == 'true' : lower_weight?.VALUE) == true ? '#ffffff' : '#9e9e9e', fontWeight: "bold", fontSize: 20, position: "relative", top: "-1vw" }} textAlign="left">
                                                &nbsp;น้ำหนักขอบล่าง
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '0vw' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="center" >
                                            <LensIcon fontSize="small" stroke={(speed?.VALUE == undefined ? sessionStorage.getItem('speed') == 'true' : speed?.VALUE) == true ? '#00d084' : '#9e9e9e'} strokeWidth="2" sx={{ color: (speed?.VALUE == undefined ? sessionStorage.getItem('speed') == 'true' : speed?.VALUE) == true ? '#00d084' : '#9e9e9e' }} style={{ position: "relative", top: "-0.5vw" }}></LensIcon>
                                            <Typography style={{ color: (speed?.VALUE == undefined ? sessionStorage.getItem('speed') == 'true' : speed?.VALUE) == true ? '#ffffff' : '#9e9e9e', fontWeight: "bold", fontSize: 20, position: "relative", top: "-1vw" }} textAlign="left">
                                                &nbsp;ความเร็วการชั่ง
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item lg={5.75} md={5.75} sm={5.75} xl={5.75} xs={5.75} style={{ position: "relative", top: '0vw', left: '0vw', padding: 3 }}>
                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ height: '125%', border: "1px solid #cfcfcf", borderRadius: 6 }}>
                            <Grid container spacing={1} >
                                <Grid id="top-row" container xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '0.8vw' }}>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '0vw' }}>
                                        <Typography style={{ color: '#ffffff', fontSize: 22, position: "relative", top: "0vw", fontWeight: 'bold' }} >
                                            Check weight
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid id="top-row" container xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '1.2vw', height: '60px', left: '5vw' }}>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '0vw' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="center" >
                                            <LensIcon fontSize="small" stroke={(no_error_check_weight?.VALUE == undefined ? sessionStorage.getItem('no_error_check_weight') == 'true' : no_error_check_weight?.VALUE) == true ? '#00d084' : '#9e9e9e'} strokeWidth="2" sx={{ color: no_error_check_weight?.VALUE == true ? '#00d084' : '#9e9e9e' }} style={{ position: "relative", top: "-0.5vw" }}></LensIcon>
                                            <Typography style={{ color: (no_error_check_weight?.VALUE == undefined ? sessionStorage.getItem('no_error_check_weight') == 'true' : no_error_check_weight?.VALUE) == true ? '#ffffff' : '#9e9e9e', fontWeight: "bold", fontSize: 20, position: "relative", top: "-1vw" }} textAlign="left">
                                                &nbsp;เชื่อมต่อ LAN
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '0vw' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="center" >
                                            <LensIcon fontSize="small" stroke={(over_reject?.VALUE == undefined ? sessionStorage.getItem('over_reject') == 'true' : over_reject?.VALUE) == true ? '#00d084' : '#9e9e9e'} strokeWidth="2" sx={{ color: (over_reject?.VALUE == undefined ? sessionStorage.getItem('over_reject') == 'true' : over_reject?.VALUE) == true ? '#00d084' : '#9e9e9e' }} style={{ position: "relative", top: "-0.5vw" }}></LensIcon>
                                            <Typography style={{ color: (over_reject?.VALUE == undefined ? sessionStorage.getItem('over_reject') == 'true' : over_reject?.VALUE) == true ? '#ffffff' : '#9e9e9e', fontWeight: "bold", fontSize: 20, position: "relative", top: "-1vw" }} textAlign="left">
                                                &nbsp;Reject ต่อเนื่อง 3 ถุง
                                            </Typography>
                                        </Box>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container sx={{ pt: 0 }} spacing={1} style={{ position: "relative", left: "-0.8vw", top: "13vw" }}>
                    <Grid item lg={11.54} md={11.54} sm={11.54} xl={11.54} xs={11.54} style={{ position: "relative", top: '0vw', left: '0vw', border: "1px solid #cfcfcf", borderRadius: 6, height: '80%' }}>
                        <Typography style={{ color: '#ffffff', fontWeight: "bold", fontSize: 22, position: "relative", top: "-1vw" }} textAlign="center">
                            Multihead และ Check weight
                        </Typography>

                        <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="center" >
                            <LensIcon fontSize="small" stroke={(product_name?.VALUE == undefined ? sessionStorage.getItem('product_name') == 'true' : product_name?.VALUE) == true ? '#00d084' : '#9e9e9e'} strokeWidth="2" sx={{ color: (product_name?.VALUE == undefined ? sessionStorage.getItem('product_name') == 'true' : product_name?.VALUE) == true ? '#00d084' : '#9e9e9e' }} style={{ position: "relative", top: "-2vw", left: '1vw' }}></LensIcon>
                            <Typography style={{ color: (product_name?.VALUE == undefined ? sessionStorage.getItem('product_name') == 'true' : product_name?.VALUE) == true ? '#ffffff' : '#9e9e9e', fontWeight: "bold", fontSize: 20, position: "relative", top: "-2.5vw", left: '1vw' }} textAlign="left">
                                &nbsp;ชื่อ SKU ตรงกัน
                            </Typography>
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="center" >
                            <LensIcon fontSize="small" stroke={(upper_weight_compare?.VALUE == undefined ? sessionStorage.getItem('upper_weight_compare') == 'true' : upper_weight_compare?.VALUE) == true ? '#00d084' : '#9e9e9e'} strokeWidth="2" sx={{ color: (upper_weight_compare?.VALUE == undefined ? sessionStorage.getItem('upper_weight_compare') == 'true' : upper_weight_compare?.VALUE) == true ? '#00d084' : '#9e9e9e' }} style={{ position: "relative", top: "-2vw", left: '1vw' }}></LensIcon>
                            <Typography style={{ color: (upper_weight_compare?.VALUE == undefined ? sessionStorage.getItem('upper_weight_compare') == 'true' : upper_weight_compare?.VALUE) == true ? '#ffffff' : '#9e9e9e', fontWeight: "bold", fontSize: 20, position: "relative", top: "-2.5vw", left: '1vw' }} textAlign="left">
                                &nbsp;น้ำหนักรวมสูงสุด เท่ากัน
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>






            </Grid>

        </>
    );
}