import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Box, Grid, IconButton, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Helmet } from "react-helmet";
import axios from 'axios';
import db from "firebase-config";
import moment from 'moment-timezone';
import { apiUrl } from "../constants";
import { useNavigate } from 'react-router-dom';
import { useContext } from "react";
import { ColorModeContext, tokens } from "../theme";
import BarChartRoomStatus from '../components/chart/BarChartRoomStatus';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'transparent',
  padding: theme.spacing(0.8),
  textAlign: 'center',
  border: '0.8px dashed #2e9281',
  borderRadius: 10,
  height: '102%'

}));

const ItemSummary = styled(Paper)(({ theme }) => ({
  backgroundColor: '#18443c',
  padding: theme.spacing(0.8),
  textAlign: 'center',
  //border: '0.5px dashed #4A4A49',
  borderRadius: 0,
  height: '100%'

}));

const ItemTotal = styled(Paper)(({ theme }) => ({
  backgroundColor: '#c4e4dc',
  padding: theme.spacing(0.3),
  textAlign: 'center',
  //border: '0.5px dashed #4A4A49',
  borderRadius: 5,
  height: '100%',
  //width: '102%',
}));

const ItemNumber = styled(Paper)(({ theme }) => ({
  backgroundColor: '#000000',
  padding: theme.spacing(0.5),
  textAlign: 'center',
  //border: '0.5px dashed #4A4A49',
  borderRadius: 5,
  height: '100%',
  //idth: '105%',
}));

const ItemTotalInstock = styled(Paper)(({ theme }) => ({
  backgroundColor: '#2e9281',
  padding: theme.spacing(0.5),
  textAlign: 'center',
  //border: '0.5px dashed #4A4A49',
  borderRadius: 5,
  //height: '90%',
  //idth: '105%',
}));

const ItemLine = styled(Paper)(({ theme }) => ({
  backgroundColor: '#000000',
  padding: theme.spacing(0.2),
  textAlign: 'center',
  //border: '0.5px dashed #4A4A49',
  borderRadius: 5,
  height: '50%',
}));

const ItemRoomStatus = styled(Paper)(({ theme }) => ({
  backgroundColor: '#000000',
  padding: theme.spacing(0.5),
  textAlign: 'center',
  //border: '0.5px dashed #4A4A49',
  borderRadius: 5,
  height: '25px',
}));

const ItemTotalBarrel = styled(Paper)(({ theme }) => ({
  backgroundColor: '#302c2c',
  padding: theme.spacing(0.2),
  textAlign: 'center',
  //border: '0.5px dashed #4A4A49',
  borderRadius: 5,
  height: '50%',
}));


export default function MarinationTrackingP2() {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const today = new Date();
  const hourago = new Date(today.getTime());
  const timestr = moment(hourago).tz('Asia/Bangkok').format('DD MMM YY');
  const timestrs = moment(hourago).tz('Asia/Bangkok').format('HH:mm:ss');

  const [marinate_page1, setMarinate_page1] = useState([])

  setTimeout(() => {
    navigate('/marination_tracking/p2');
  }, 30000);

  useEffect(() => {
    const getPostFromFirebase = [];
    const subscriber = db
      .collection("line_monitoring_preparation_zone_page1")
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {

          getPostFromFirebase.push({
            ...doc.data(),
            id: doc.id,
          });
        });

        setMarinate_page1(getPostFromFirebase);

        sessionStorage.setItem('overall_total_kg_instock_line7', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE7OVERALL_TOTAL_KG_IN_STOCK"))?.OVERALL_TOTAL_KG_IN_STOCK);
        sessionStorage.setItem('overall_total_kg_instock_line8', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE8OVERALL_TOTAL_KG_IN_STOCK"))?.OVERALL_TOTAL_KG_IN_STOCK);
        sessionStorage.setItem('overall_total_kg_instock_line9', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE9OVERALL_TOTAL_KG_IN_STOCK"))?.OVERALL_TOTAL_KG_IN_STOCK);
        sessionStorage.setItem('overall_total_kg_instock_line10', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE10OVERALL_TOTAL_KG_IN_STOCK"))?.OVERALL_TOTAL_KG_IN_STOCK);
        sessionStorage.setItem('overall_total_kg_instock_line11', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE11OVERALL_TOTAL_KG_IN_STOCK"))?.OVERALL_TOTAL_KG_IN_STOCK);
        sessionStorage.setItem('overall_total_kg_instock_line12', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE12OVERALL_TOTAL_KG_IN_STOCK"))?.OVERALL_TOTAL_KG_IN_STOCK);

        sessionStorage.setItem('total_kg_processing_line7_sum', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE7TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY"))?.TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY);
        sessionStorage.setItem('total_kg_processing_line8_sum', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE8TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY"))?.TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY);
        sessionStorage.setItem('total_kg_processing_line9_sum', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE9TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY"))?.TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY);
        sessionStorage.setItem('total_kg_processing_line10_sum', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE10TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY"))?.TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY);
        sessionStorage.setItem('total_kg_processing_line11_sum', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE11TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY"))?.TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY);
        sessionStorage.setItem('total_kg_processing_line12_sum', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE12TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY"))?.TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY);

        sessionStorage.setItem('sku_line7', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE7CURRENT_SKU"))?.CURRENT_SKU);
        sessionStorage.setItem('sku_line8', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE8CURRENT_SKU"))?.CURRENT_SKU);
        sessionStorage.setItem('sku_line9', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE9CURRENT_SKU"))?.CURRENT_SKU);
        sessionStorage.setItem('sku_line10', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE10CURRENT_SKU"))?.CURRENT_SKU);
        sessionStorage.setItem('sku_line11', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE11CURRENT_SKU"))?.CURRENT_SKU);
        sessionStorage.setItem('sku_line12', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE12CURRENT_SKU"))?.CURRENT_SKU);

        sessionStorage.setItem('batch_line7', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE7CURRENT_BATCH"))?.CURRENT_BATCH);
        sessionStorage.setItem('batch_line8', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE8CURRENT_BATCH"))?.CURRENT_BATCH);
        sessionStorage.setItem('batch_line9', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE9CURRENT_BATCH"))?.CURRENT_BATCH);
        sessionStorage.setItem('batch_line10', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE10CURRENT_BATCH"))?.CURRENT_BATCH);
        sessionStorage.setItem('batch_line11', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE11CURRENT_BATCH"))?.CURRENT_BATCH);
        sessionStorage.setItem('batch_line12', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE12CURRENT_BATCH"))?.CURRENT_BATCH);

        sessionStorage.setItem('total_barrel_line7', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE7TOTAL_BARREL"))?.TOTAL_BARREL);
        sessionStorage.setItem('total_barrel_line8', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE8TOTAL_BARREL"))?.TOTAL_BARREL);
        sessionStorage.setItem('total_barrel_line9', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE9TOTAL_BARREL"))?.TOTAL_BARREL);
        sessionStorage.setItem('total_barrel_line10', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE10TOTAL_BARREL"))?.TOTAL_BARREL);
        sessionStorage.setItem('total_barrel_line11', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE11TOTAL_BARREL"))?.TOTAL_BARREL);
        sessionStorage.setItem('total_barrel_line12', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE12TOTAL_BARREL"))?.TOTAL_BARREL);

        sessionStorage.setItem('total_kg_line7', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE7TOTAL_KG"))?.TOTAL_KG);
        sessionStorage.setItem('total_kg_line8', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE8TOTAL_KG"))?.TOTAL_KG);
        sessionStorage.setItem('total_kg_line9', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE9TOTAL_KG"))?.TOTAL_KG);
        sessionStorage.setItem('total_kg_line10', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE10TOTAL_KG"))?.TOTAL_KG);
        sessionStorage.setItem('total_kg_line11', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE11TOTAL_KG"))?.TOTAL_KG);
        sessionStorage.setItem('total_kg_line12', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE12TOTAL_KG"))?.TOTAL_KG);

        sessionStorage.setItem('total_barrel_processing_line7', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE7TOTAL_BARREL_PROCESSING_AND_COMPLETED"))?.TOTAL_BARREL_PROCESSING_AND_COMPLETED);
        sessionStorage.setItem('total_barrel_processing_line8', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE8TOTAL_BARREL_PROCESSING_AND_COMPLETED"))?.TOTAL_BARREL_PROCESSING_AND_COMPLETED);
        sessionStorage.setItem('total_barrel_processing_line9', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE9TOTAL_BARREL_PROCESSING_AND_COMPLETED"))?.TOTAL_BARREL_PROCESSING_AND_COMPLETED);
        sessionStorage.setItem('total_barrel_processing_line10', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE10TOTAL_BARREL_PROCESSING_AND_COMPLETED"))?.TOTAL_BARREL_PROCESSING_AND_COMPLETED);
        sessionStorage.setItem('total_barrel_processing_line11', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE11TOTAL_BARREL_PROCESSING_AND_COMPLETED"))?.TOTAL_BARREL_PROCESSING_AND_COMPLETED);
        sessionStorage.setItem('total_barrel_processing_line12', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE12TOTAL_BARREL_PROCESSING_AND_COMPLETED"))?.TOTAL_BARREL_PROCESSING_AND_COMPLETED);

        sessionStorage.setItem('total_kg_processing_line7', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE7TOTAL_KG_PROCESSING_AND_COMPLETED"))?.TOTAL_KG_PROCESSING_AND_COMPLETED);
        sessionStorage.setItem('total_kg_processing_line8', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE8TOTAL_KG_PROCESSING_AND_COMPLETED"))?.TOTAL_KG_PROCESSING_AND_COMPLETED);
        sessionStorage.setItem('total_kg_processing_line9', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE9TOTAL_KG_PROCESSING_AND_COMPLETED"))?.TOTAL_KG_PROCESSING_AND_COMPLETED);
        sessionStorage.setItem('total_kg_processing_line10', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE10TOTAL_KG_PROCESSING_AND_COMPLETED"))?.TOTAL_KG_PROCESSING_AND_COMPLETED);
        sessionStorage.setItem('total_kg_processing_line11', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE11TOTAL_KG_PROCESSING_AND_COMPLETED"))?.TOTAL_KG_PROCESSING_AND_COMPLETED);
        sessionStorage.setItem('total_kg_processing_line12', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE12TOTAL_KG_PROCESSING_AND_COMPLETED"))?.TOTAL_KG_PROCESSING_AND_COMPLETED);

        sessionStorage.setItem('total_barrel_instock_line7', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE7TOTAL_BARREL_IN_STOCK"))?.TOTAL_BARREL_IN_STOCK);
        sessionStorage.setItem('total_barrel_instock_line8', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE8TOTAL_BARREL_IN_STOCK"))?.TOTAL_BARREL_IN_STOCK);
        sessionStorage.setItem('total_barrel_instock_line9', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE9TOTAL_BARREL_IN_STOCK"))?.TOTAL_BARREL_IN_STOCK);
        sessionStorage.setItem('total_barrel_instock_line10', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE10TOTAL_BARREL_IN_STOCK"))?.TOTAL_BARREL_IN_STOCK);
        sessionStorage.setItem('total_barrel_instock_line11', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE11TOTAL_BARREL_IN_STOCK"))?.TOTAL_BARREL_IN_STOCK);
        sessionStorage.setItem('total_barrel_instock_line12', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE12TOTAL_BARREL_IN_STOCK"))?.TOTAL_BARREL_IN_STOCK);

        sessionStorage.setItem('total_kg_instock_line7', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE7TOTAL_KG_IN_STOCK"))?.TOTAL_KG_IN_STOCK);
        sessionStorage.setItem('total_kg_instock_line8', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE8TOTAL_KG_IN_STOCK"))?.TOTAL_KG_IN_STOCK);
        sessionStorage.setItem('total_kg_instock_line9', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE9TOTAL_KG_IN_STOCK"))?.TOTAL_KG_IN_STOCK);
        sessionStorage.setItem('total_kg_instock_line10', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE10TOTAL_KG_IN_STOCK"))?.TOTAL_KG_IN_STOCK);
        sessionStorage.setItem('total_kg_instock_line11', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE11TOTAL_KG_IN_STOCK"))?.TOTAL_KG_IN_STOCK);
        sessionStorage.setItem('total_kg_instock_line12', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE12TOTAL_KG_IN_STOCK"))?.TOTAL_KG_IN_STOCK);

        sessionStorage.setItem('overall_total_barrel_instock_line7', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE7OVERALL_TOTAL_BARREL_IN_STOCK"))?.OVERALL_TOTAL_BARREL_IN_STOCK);
        sessionStorage.setItem('overall_total_barrel_instock_line8', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE8OVERALL_TOTAL_BARREL_IN_STOCK"))?.OVERALL_TOTAL_BARREL_IN_STOCK);
        sessionStorage.setItem('overall_total_barrel_instock_line9', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE9OVERALL_TOTAL_BARREL_IN_STOCK"))?.OVERALL_TOTAL_BARREL_IN_STOCK);
        sessionStorage.setItem('overall_total_barrel_instock_line10', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE10OVERALL_TOTAL_BARREL_IN_STOCK"))?.OVERALL_TOTAL_BARREL_IN_STOCK);
        sessionStorage.setItem('overall_total_barrel_instock_line11', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE11OVERALL_TOTAL_BARREL_IN_STOCK"))?.OVERALL_TOTAL_BARREL_IN_STOCK);
        sessionStorage.setItem('overall_total_barrel_instock_line12', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE12OVERALL_TOTAL_BARREL_IN_STOCK"))?.OVERALL_TOTAL_BARREL_IN_STOCK);


      });

    // return cleanup function
    return () => subscriber();
  }, [marinate_page1]);


  const overall_total_kg_instock_line7 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE7OVERALL_TOTAL_KG_IN_STOCK"));

  const overall_total_kg_instock_line8 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE8OVERALL_TOTAL_KG_IN_STOCK"));

  const overall_total_kg_instock_line9 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE9OVERALL_TOTAL_KG_IN_STOCK"));

  const overall_total_kg_instock_line10 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE10OVERALL_TOTAL_KG_IN_STOCK"));

  const overall_total_kg_instock_line11 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE11OVERALL_TOTAL_KG_IN_STOCK"));

  const overall_total_kg_instock_line12 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE12OVERALL_TOTAL_KG_IN_STOCK"));


  const total_kg_processing_line7_sum = marinate_page1 === undefined ? 0 : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE7TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY"));

  const total_kg_processing_line8_sum = marinate_page1 === undefined ? 0 : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE8TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY"));

  const total_kg_processing_line9_sum = marinate_page1 === undefined ? 0 : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE9TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY"));

  const total_kg_processing_line10_sum = marinate_page1 === undefined ? 0 : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE10TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY"));

  const total_kg_processing_line11_sum = marinate_page1 === undefined ? 0 : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE11TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY"));

  const total_kg_processing_line12_sum = marinate_page1 === undefined ? 0 : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE12TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY"));


  const sku_line7 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE7CURRENT_SKU"));

  const sku_line8 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE8CURRENT_SKU"));

  const sku_line9 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE9CURRENT_SKU"));

  const sku_line10 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE10CURRENT_SKU"));

  const sku_line11 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE11CURRENT_SKU"));

  const sku_line12 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE12CURRENT_SKU"));


  const batch_line7 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE7CURRENT_BATCH"));

  const batch_line8 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE8CURRENT_BATCH"));

  const batch_line9 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE9CURRENT_BATCH"));

  const batch_line10 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE10CURRENT_BATCH"));

  const batch_line11 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE11CURRENT_BATCH"));

  const batch_line12 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE12CURRENT_BATCH"));


  const total_barrel_line7 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE7TOTAL_BARREL"));

  const total_barrel_line8 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE8TOTAL_BARREL"));

  const total_barrel_line9 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE9TOTAL_BARREL"));

  const total_barrel_line10 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE10TOTAL_BARREL"));

  const total_barrel_line11 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE11TOTAL_BARREL"));

  const total_barrel_line12 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE12TOTAL_BARREL"));


  const total_kg_line7 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE7TOTAL_KG"));

  const total_kg_line8 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE8TOTAL_KG"));

  const total_kg_line9 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE9TOTAL_KG"));

  const total_kg_line10 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE10TOTAL_KG"));

  const total_kg_line11 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE11TOTAL_KG"));

  const total_kg_line12 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE12TOTAL_KG"));


  const total_barrel_processing_line7 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE7TOTAL_BARREL_PROCESSING_AND_COMPLETED"));

  const total_barrel_processing_line8 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE8TOTAL_BARREL_PROCESSING_AND_COMPLETED"));

  const total_barrel_processing_line9 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE9TOTAL_BARREL_PROCESSING_AND_COMPLETED"));

  const total_barrel_processing_line10 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE10TOTAL_BARREL_PROCESSING_AND_COMPLETED"));

  const total_barrel_processing_line11 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE11TOTAL_BARREL_PROCESSING_AND_COMPLETED"));

  const total_barrel_processing_line12 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE12TOTAL_BARREL_PROCESSING_AND_COMPLETED"));


  const total_kg_processing_line7 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE7TOTAL_KG_PROCESSING_AND_COMPLETED"));

  const total_kg_processing_line8 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE8TOTAL_KG_PROCESSING_AND_COMPLETED"));

  const total_kg_processing_line9 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE9TOTAL_KG_PROCESSING_AND_COMPLETED"));

  const total_kg_processing_line10 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE10TOTAL_KG_PROCESSING_AND_COMPLETED"));

  const total_kg_processing_line11 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE11TOTAL_KG_PROCESSING_AND_COMPLETED"));

  const total_kg_processing_line12 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE12TOTAL_KG_PROCESSING_AND_COMPLETED"));


  const total_barrel_instock_line7 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE7TOTAL_BARREL_IN_STOCK"));

  const total_barrel_instock_line8 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE8TOTAL_BARREL_IN_STOCK"));

  const total_barrel_instock_line9 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE9TOTAL_BARREL_IN_STOCK"));

  const total_barrel_instock_line10 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE10TOTAL_BARREL_IN_STOCK"));

  const total_barrel_instock_line11 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE11TOTAL_BARREL_IN_STOCK"));

  const total_barrel_instock_line12 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE12TOTAL_BARREL_IN_STOCK"));


  const total_kg_instock_line7 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE7TOTAL_KG_IN_STOCK"));

  const total_kg_instock_line8 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE8TOTAL_KG_IN_STOCK"));

  const total_kg_instock_line9 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE9TOTAL_KG_IN_STOCK"));

  const total_kg_instock_line10 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE10TOTAL_KG_IN_STOCK"));

  const total_kg_instock_line11 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE11TOTAL_KG_IN_STOCK"));

  const total_kg_instock_line12 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE12TOTAL_KG_IN_STOCK"));


  const sum_total_kg_processing = total_kg_processing_line7_sum?.TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY + total_kg_processing_line8_sum?.TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY + total_kg_processing_line9_sum?.TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY + total_kg_processing_line10_sum?.TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY + total_kg_processing_line11_sum?.TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY + total_kg_processing_line12_sum?.TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY

  const sum_total_kg_processing_old = Number(sessionStorage.getItem('total_kg_processing_line7_sum')) + Number(sessionStorage.getItem('total_kg_processing_line8_sum')) + Number(sessionStorage.getItem('total_kg_processing_line9_sum')) + Number(sessionStorage.getItem('total_kg_processing_line10_sum')) + Number(sessionStorage.getItem('total_kg_processing_line11_sum')) + Number(sessionStorage.getItem('total_kg_processing_line12_sum'))


  const sum_overall_total_kg_instock = overall_total_kg_instock_line7?.OVERALL_TOTAL_KG_IN_STOCK + overall_total_kg_instock_line8?.OVERALL_TOTAL_KG_IN_STOCK + overall_total_kg_instock_line9?.OVERALL_TOTAL_KG_IN_STOCK + overall_total_kg_instock_line10?.OVERALL_TOTAL_KG_IN_STOCK + overall_total_kg_instock_line11?.OVERALL_TOTAL_KG_IN_STOCK + overall_total_kg_instock_line12?.OVERALL_TOTAL_KG_IN_STOCK

  const sum_overall_total_kg_instock_old = Number(sessionStorage.getItem('overall_total_kg_instock_line7')) + Number(sessionStorage.getItem('overall_total_kg_instock_line8')) + Number(sessionStorage.getItem('overall_total_kg_instock_line9')) + Number(sessionStorage.getItem('overall_total_kg_instock_line10')) + Number(sessionStorage.getItem('overall_total_kg_instock_line11')) + Number(sessionStorage.getItem('overall_total_kg_instock_line12'))


  const sum_instock_processing = Math.round(sum_total_kg_processing) + Math.round(sum_overall_total_kg_instock)

  const sum_instock_processing_old = Math.round(sum_total_kg_processing_old) + Math.round(sum_overall_total_kg_instock_old)



  const overall_total_barrel_instock_line7 = marinate_page1 === undefined ? 0 : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE7OVERALL_TOTAL_BARREL_IN_STOCK"));

  const overall_total_barrel_instock_line8 = marinate_page1 === undefined ? 0 : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE8OVERALL_TOTAL_BARREL_IN_STOCK"));

  const overall_total_barrel_instock_line9 = marinate_page1 === undefined ? 0 : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE9OVERALL_TOTAL_BARREL_IN_STOCK"));

  const overall_total_barrel_instock_line10 = marinate_page1 === undefined ? 0 : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE10OVERALL_TOTAL_BARREL_IN_STOCK"));

  const overall_total_barrel_instock_line11 = marinate_page1 === undefined ? 0 : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE11OVERALL_TOTAL_BARREL_IN_STOCK"));

  const overall_total_barrel_instock_line12 = marinate_page1 === undefined ? 0 : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE12OVERALL_TOTAL_BARREL_IN_STOCK"));


  const sum_overall_total_barrel_instock = overall_total_barrel_instock_line7?.OVERALL_TOTAL_BARREL_IN_STOCK + overall_total_barrel_instock_line8?.OVERALL_TOTAL_BARREL_IN_STOCK + overall_total_barrel_instock_line9?.OVERALL_TOTAL_BARREL_IN_STOCK + overall_total_barrel_instock_line10?.OVERALL_TOTAL_BARREL_IN_STOCK + overall_total_barrel_instock_line11?.OVERALL_TOTAL_BARREL_IN_STOCK + overall_total_barrel_instock_line12?.OVERALL_TOTAL_BARREL_IN_STOCK

  const sum_overall_total_barrel_instock_old = Number(sessionStorage.getItem('overall_total_barrel_instock_line7')) + Number(sessionStorage.getItem('overall_total_barrel_instock_line8')) + Number(sessionStorage.getItem('overall_total_barrel_instock_line9')) + Number(sessionStorage.getItem('overall_total_barrel_instock_line10')) + Number(sessionStorage.getItem('overall_total_barrel_instock_line11')) + Number(sessionStorage.getItem('overall_total_barrel_instock_line12'))



  return (
    <>
      <Helmet>
        <title>Marination Tracking | MES Korat Fur2</title>
      </Helmet>
      <Grid
        container spacing={1}
        direction="row"
        alignItems="top"
        justifyContent="center"
        style={{ minHeight: '78vh', maxHeight: '200vh', flexGrow: 1, paddingLeft: 15, paddingRight: 2, paddingTop: 45 }}
      >
        {/* Top row */}
        <Grid container rowSpacing={0.5} columnSpacing={{ xs: 1, sm: 0.5, md: 0.5 }}>
          <Grid item xs={3} style={{ height: '284px' }}>
            <ItemSummary>
              <Grid item xs={12} textAlign="center" style={{ paddingTop: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                  <Typography style={{ color: colors.black[100], fontSize: 28, fontWeight: 'bold', lineHeight: "10px", position: "relative", top: "0vw" }} >
                    Total&nbsp;
                  </Typography>
                  <Typography style={{ color: colors.black[100], fontSize: 20, fontWeight: 'bold', lineHeight: "10px", paddingTop: 1 }} >
                    (kg)
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} textAlign="center" style={{ paddingTop: 10, paddingRight: 0 }}>
                <ItemTotal>
                  <Grid item xs={12} textAlign="center" style={{ paddingTop: 0 }}>
                    <Typography style={{ color: colors.while[100], fontSize: 26, fontWeight: 'bold', lineHeight: "17px", position: "relative", top: "0vw" }} >
                      {total_kg_processing_line7_sum?.TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY === undefined ? sum_instock_processing_old.toLocaleString() : sum_instock_processing.toLocaleString()}&nbsp;
                    </Typography>
                  </Grid>
                  <Grid item xs={12} textAlign="center" style={{ paddingTop: 2 }}>
                    <ItemNumber>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                        <Typography style={{ color: '#b58514', fontSize: 20, lineHeight: "17px", fontWeight: 'bold' }} >
                          {(total_kg_processing_line7_sum?.TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY === undefined || total_kg_processing_line8_sum?.TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY === undefined || total_kg_processing_line9_sum?.TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY === undefined || total_kg_processing_line10_sum?.TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY === undefined || total_kg_processing_line11_sum?.TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY === undefined || total_kg_processing_line12_sum?.TOTAL_KG_PROCESSING_AND_COMPLETED_SUMMARY === undefined)
                            ? Math.round(sum_total_kg_processing_old).toLocaleString() : Math.round(sum_total_kg_processing).toLocaleString()}
                        </Typography>
                        <Typography style={{ color: '#b58514', fontSize: 20, fontWeight: 'bold', lineHeight: "17px", position: "relative", top: "0vw" }} >
                          &nbsp;:&nbsp;
                        </Typography>
                        <Typography style={{ color: '#2e9281', fontSize: 20, lineHeight: "17px", fontWeight: 'bold' }} >
                          {(overall_total_kg_instock_line7?.OVERALL_TOTAL_KG_IN_STOCK === undefined || overall_total_kg_instock_line8?.OVERALL_TOTAL_KG_IN_STOCK === undefined || overall_total_kg_instock_line9?.OVERALL_TOTAL_KG_IN_STOCK === undefined || overall_total_kg_instock_line10?.OVERALL_TOTAL_KG_IN_STOCK === undefined || overall_total_kg_instock_line11?.OVERALL_TOTAL_KG_IN_STOCK === undefined || overall_total_kg_instock_line12?.OVERALL_TOTAL_KG_IN_STOCK === undefined)
                            ? Math.round(sum_overall_total_kg_instock_old).toLocaleString() : Math.round(sum_overall_total_kg_instock).toLocaleString()}
                        </Typography>
                      </Box>
                    </ItemNumber>
                  </Grid>

                </ItemTotal>
              </Grid>

              <Grid item xs={12} textAlign="center" style={{ paddingTop: 10, paddingRight: 0 }}>
                <Grid item xs={12} style={{ paddingTop: 5 }}>
                  <Grid container rowSpacing={0.2} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                    <Grid item xs={6} style={{ paddingRight: 3 }}>
                      <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                        <Typography style={{ color: colors.black[100], fontSize: 16, lineHeight: "15px", paddingTop: 1 }} >
                          Line
                        </Typography>
                        <Typography style={{ color: '#84d8c8', fontSize: 18, lineHeight: "15px", paddingTop: 0, fontWeight: 'bold' }} >
                          7
                        </Typography>
                      </Box>
                      <ItemLine>
                        <Typography style={{ color: colors.black[100], fontSize: 22, fontWeight: 'bold', lineHeight: "18px", position: "relative", top: "0vw" }} textAlign="right">
                          {overall_total_kg_instock_line7?.OVERALL_TOTAL_KG_IN_STOCK === undefined ? (sessionStorage.getItem('overall_total_kg_instock_line7') === 'undefined' || sessionStorage.getItem('overall_total_kg_instock_line7') === '' ? '-' : Math.round(Number(sessionStorage.getItem('overall_total_kg_instock_line7'))).toLocaleString()) : Math.round(overall_total_kg_instock_line7?.OVERALL_TOTAL_KG_IN_STOCK).toLocaleString()}
                        </Typography>
                      </ItemLine>
                    </Grid>

                    <Grid item xs={6} >
                      <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                        <Typography style={{ color: colors.black[100], fontSize: 16, lineHeight: "15px", paddingTop: 1 }} >
                          Line
                        </Typography>
                        <Typography style={{ color: '#f48c94', fontSize: 18, lineHeight: "15px", paddingTop: 0, fontWeight: 'bold' }} >
                          8
                        </Typography>
                      </Box>
                      <ItemLine>
                        <Typography style={{ color: colors.black[100], fontSize: 22, fontWeight: 'bold', lineHeight: "18px", position: "relative", top: "0vw" }} textAlign="right">
                          {overall_total_kg_instock_line8?.OVERALL_TOTAL_KG_IN_STOCK === undefined ? (sessionStorage.getItem('overall_total_kg_instock_line8') === 'undefined' || sessionStorage.getItem('overall_total_kg_instock_line8') === '' ? '-' : Math.round(Number(sessionStorage.getItem('overall_total_kg_instock_line8'))).toLocaleString()) : Math.round(overall_total_kg_instock_line8?.OVERALL_TOTAL_KG_IN_STOCK).toLocaleString()}
                        </Typography>
                      </ItemLine>
                    </Grid>

                    <Grid item xs={6} style={{ paddingRight: 3 }}>
                      <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                        <Typography style={{ color: colors.black[100], fontSize: 16, lineHeight: "15px", paddingTop: 1 }} >
                          Line
                        </Typography>
                        <Typography style={{ color: '#fbb2a4', fontSize: 18, lineHeight: "15px", paddingTop: 0, fontWeight: 'bold' }} >
                          9
                        </Typography>
                      </Box>
                      <ItemLine>
                        <Typography style={{ color: colors.black[100], fontSize: 22, fontWeight: 'bold', lineHeight: "18px", position: "relative", top: "0vw" }} textAlign="right">
                          {overall_total_kg_instock_line9?.OVERALL_TOTAL_KG_IN_STOCK === undefined ? (sessionStorage.getItem('overall_total_kg_instock_line9') === 'undefined' || sessionStorage.getItem('overall_total_kg_instock_line9') === '' ? '-' : Math.round(Number(sessionStorage.getItem('overall_total_kg_instock_line9'))).toLocaleString()) : Math.round(overall_total_kg_instock_line9?.OVERALL_TOTAL_KG_IN_STOCK).toLocaleString()}
                        </Typography>
                      </ItemLine>
                    </Grid>

                    <Grid item xs={6} >
                      <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                        <Typography style={{ color: colors.black[100], fontSize: 16, lineHeight: "15px", paddingTop: 1 }} >
                          Line
                        </Typography>
                        <Typography style={{ color: '#a4aad4', fontSize: 18, lineHeight: "15px", paddingTop: 0, fontWeight: 'bold' }} >
                          10
                        </Typography>
                      </Box>
                      <ItemLine>
                        <Typography style={{ color: colors.black[100], fontSize: 22, fontWeight: 'bold', lineHeight: "18px", position: "relative", top: "0vw" }} textAlign="right">
                          {overall_total_kg_instock_line10?.OVERALL_TOTAL_KG_IN_STOCK === undefined ? (sessionStorage.getItem('overall_total_kg_instock_line10') === 'undefined' || sessionStorage.getItem('overall_total_kg_instock_line10') === '' ? '-' : Math.round(Number(sessionStorage.getItem('overall_total_kg_instock_line10'))).toLocaleString()) : Math.round(overall_total_kg_instock_line10?.OVERALL_TOTAL_KG_IN_STOCK).toLocaleString()}
                        </Typography>
                      </ItemLine>
                    </Grid>

                    <Grid item xs={6} style={{ paddingRight: 3 }}>
                      <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                        <Typography style={{ color: colors.black[100], fontSize: 16, lineHeight: "15px", paddingTop: 1 }} >
                          Line
                        </Typography>
                        <Typography style={{ color: '#ddd564', fontSize: 18, lineHeight: "15px", paddingTop: 0, fontWeight: 'bold' }} >
                          11
                        </Typography>
                      </Box>
                      <ItemLine>
                        <Typography style={{ color: colors.black[100], fontSize: 22, fontWeight: 'bold', lineHeight: "18px", position: "relative", top: "0vw" }} textAlign="right">
                          {overall_total_kg_instock_line11?.OVERALL_TOTAL_KG_IN_STOCK === undefined ? (sessionStorage.getItem('overall_total_kg_instock_line11') === 'undefined' || sessionStorage.getItem('overall_total_kg_instock_line11') === '' ? '-' : Math.round(Number(sessionStorage.getItem('overall_total_kg_instock_line11'))).toLocaleString()) : Math.round(overall_total_kg_instock_line11?.OVERALL_TOTAL_KG_IN_STOCK).toLocaleString()}
                        </Typography>
                      </ItemLine>
                    </Grid>

                    <Grid item xs={6} >
                      <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                        <Typography style={{ color: colors.black[100], fontSize: 16, lineHeight: "15px", paddingTop: 1 }} >
                          Line
                        </Typography>
                        <Typography style={{ color: '#94cbcc', fontSize: 18, lineHeight: "15px", paddingTop: 0, fontWeight: 'bold' }} >
                          12
                        </Typography>
                      </Box>
                      <ItemLine>
                        <Typography style={{ color: colors.black[100], fontSize: 22, fontWeight: 'bold', lineHeight: "18px", position: "relative", top: "0vw" }} textAlign="right">
                          {overall_total_kg_instock_line12?.OVERALL_TOTAL_KG_IN_STOCK === undefined ? (sessionStorage.getItem('overall_total_kg_instock_line12') === 'undefined' || sessionStorage.getItem('overall_total_kg_instock_line12') === '' ? '-' : Math.round(Number(sessionStorage.getItem('overall_total_kg_instock_line12'))).toLocaleString()) : Math.round(overall_total_kg_instock_line12?.OVERALL_TOTAL_KG_IN_STOCK).toLocaleString()}
                        </Typography>
                      </ItemLine>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} textAlign="center" style={{ paddingTop: 15, paddingRight: 0 }}>
                <Typography style={{ color: colors.black[100], fontSize: 18, fontWeight: 'bold', lineHeight: "10px", position: "relative", top: "0vw" }} >
                  Room Status
                </Typography>
                <Grid item xs={12} textAlign="center" style={{ paddingTop: 8 }}>
                  <ItemRoomStatus>
                    <BarChartRoomStatus
                      total_instock={(overall_total_barrel_instock_line7?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined || overall_total_barrel_instock_line8?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined || overall_total_barrel_instock_line9?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined || overall_total_barrel_instock_line10?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined || overall_total_barrel_instock_line11?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined || overall_total_barrel_instock_line12?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined)
                        ? Math.round(sum_overall_total_barrel_instock_old) : Math.round(sum_overall_total_barrel_instock)}
                      line7={overall_total_barrel_instock_line7?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined ? (sessionStorage.getItem('overall_total_barrel_instock_line7') === 'undefined' || sessionStorage.getItem('overall_total_barrel_instock_line7') === '' ? 0 : Math.round(Number(sessionStorage.getItem('overall_total_barrel_instock_line7')))) : Math.round(overall_total_barrel_instock_line7?.OVERALL_TOTAL_BARREL_IN_STOCK)}
                      line8={overall_total_barrel_instock_line8?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined ? (sessionStorage.getItem('overall_total_barrel_instock_line8') === 'undefined' || sessionStorage.getItem('overall_total_barrel_instock_line8') === '' ? 0 : Math.round(Number(sessionStorage.getItem('overall_total_barrel_instock_line8')))) : Math.round(overall_total_barrel_instock_line8?.OVERALL_TOTAL_BARREL_IN_STOCK)}
                      line9={overall_total_barrel_instock_line9?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined ? (sessionStorage.getItem('overall_total_barrel_instock_line9') === 'undefined' || sessionStorage.getItem('overall_total_barrel_instock_line9') === '' ? 0 : Math.round(Number(sessionStorage.getItem('overall_total_barrel_instock_line9')))) : Math.round(overall_total_barrel_instock_line9?.OVERALL_TOTAL_BARREL_IN_STOCK)}
                      line10={overall_total_barrel_instock_line10?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined ? (sessionStorage.getItem('overall_total_barrel_instock_line10') === 'undefined' || sessionStorage.getItem('overall_total_barrel_instock_line10') === '' ? 0 : Math.round(Number(sessionStorage.getItem('overall_total_barrel_instock_line10')))) : Math.round(overall_total_barrel_instock_line10?.OVERALL_TOTAL_BARREL_IN_STOCK)}
                      line11={overall_total_barrel_instock_line11?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined ? (sessionStorage.getItem('overall_total_barrel_instock_line11') === 'undefined' || sessionStorage.getItem('overall_total_barrel_instock_line11') === '' ? 0 : Math.round(Number(sessionStorage.getItem('overall_total_barrel_instock_line11')))) : Math.round(overall_total_barrel_instock_line11?.OVERALL_TOTAL_BARREL_IN_STOCK)}
                      line12={overall_total_barrel_instock_line12?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined ? (sessionStorage.getItem('overall_total_barrel_instock_line12') === 'undefined' || sessionStorage.getItem('overall_total_barrel_instock_line12') === '' ? 0 : Math.round(Number(sessionStorage.getItem('overall_total_barrel_instock_line12')))) : Math.round(overall_total_barrel_instock_line12?.OVERALL_TOTAL_BARREL_IN_STOCK)}
                      remaining={(overall_total_barrel_instock_line7?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined || overall_total_barrel_instock_line8?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined || overall_total_barrel_instock_line9?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined || overall_total_barrel_instock_line10?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined || overall_total_barrel_instock_line11?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined || overall_total_barrel_instock_line12?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined)
                        ? 96 - Math.round(sum_overall_total_barrel_instock_old) : 96 - Math.round(sum_overall_total_barrel_instock)}
                      actual={(overall_total_barrel_instock_line7?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined || overall_total_barrel_instock_line8?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined || overall_total_barrel_instock_line9?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined || overall_total_barrel_instock_line10?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined || overall_total_barrel_instock_line11?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined || overall_total_barrel_instock_line12?.OVERALL_TOTAL_BARREL_IN_STOCK === undefined)
                        ? Math.round(sum_overall_total_barrel_instock_old) : Math.round(sum_overall_total_barrel_instock)}
                      // bag={70}
                      // remaining_bag={10}
                      height='270%'
                    />
                  </ItemRoomStatus>
                </Grid>
              </Grid>

            </ItemSummary>
          </Grid>
          <Grid item xs={9}>
            <Grid container rowSpacing={0.8} columnSpacing={{ xs: 0, sm: 0, md: 0 }} >
              <Grid item xs={4} style={{ paddingRight: 3, height: '142px' }} >
                <Item>
                  <Grid item xs={12} textAlign="left" style={{ paddingTop: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                      <Typography style={{ color: '#2e9281', fontSize: 18, lineHeight: "10px", fontWeight: 'bold' }} >
                        L
                      </Typography>
                      <Typography style={{ color: '#2e9281', fontSize: 18, lineHeight: "10px", fontWeight: 'bold' }} >
                        07&nbsp;
                      </Typography>
                      <Typography style={{ color: colors.black[100], fontSize: 18, lineHeight: "10px", position: "relative", top: "0vw" }} >
                        {sku_line7?.CURRENT_SKU === undefined || sku_line7?.CURRENT_SKU === '' ? (sessionStorage.getItem('sku_line7') === 'undefined' || sessionStorage.getItem('sku_line7') === '' ? '' : sessionStorage.getItem('sku_line7')) : sku_line7?.CURRENT_SKU}
                      </Typography>
                    </Box>
                  </Grid>

                  {batch_line7?.CURRENT_BATCH === undefined && sessionStorage.getItem('batch_line7') === 'undefined'
                    ? ''
                    : batch_line7?.CURRENT_BATCH === '' || sessionStorage.getItem('batch_line7') === '' ? '' :
                      <Grid item xs={12} textAlign="center" style={{ paddingTop: 10, paddingLeft: 10, paddingRight: 10 }}>
                        <ItemSummary>
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                            <Typography style={{ color: colors.black[100], fontSize: 22, lineHeight: "10px", fontWeight: 'bold' }} >
                              Batch :&nbsp;
                            </Typography>
                            <Typography style={{ color: colors.black[100], fontSize: 22, lineHeight: "10px", fontWeight: 'bold' }} >
                              {batch_line7?.CURRENT_BATCH === undefined || batch_line7?.CURRENT_BATCH === '' ? (sessionStorage.getItem('batch_line7') === 'undefined' || sessionStorage.getItem('batch_line7') === '' ? '' : sessionStorage.getItem('batch_line7')) : batch_line7?.CURRENT_BATCH}
                            </Typography>
                          </Box>
                        </ItemSummary>
                      </Grid>
                  }

                  {total_barrel_line7?.TOTAL_BARREL === undefined && sessionStorage.getItem('total_barrel_line7') === 'undefined'
                    ? ''
                    : total_barrel_line7?.TOTAL_BARREL === '' || sessionStorage.getItem('total_barrel_line7') === '' ? '' :
                      <Grid item xs={12} textAlign="center" style={{ paddingTop: 7 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                          <Typography style={{ color: colors.black[100], fontSize: 26, lineHeight: "17px", fontWeight: 'bold' }} >
                            {total_barrel_line7?.TOTAL_BARREL === undefined || total_barrel_line7?.TOTAL_BARREL === '' ? (sessionStorage.getItem('total_barrel_line7') === 'undefined' || sessionStorage.getItem('total_barrel_line7') === '' ? '' : sessionStorage.getItem('total_barrel_line7')) : total_barrel_line7?.TOTAL_BARREL}&nbsp;
                          </Typography>
                          <Typography style={{ color: colors.grey[100], fontSize: 16, lineHeight: "17px", paddingTop: 5 }} >
                            Bin&nbsp;
                          </Typography>
                          <Typography style={{ color: colors.black[100], fontSize: 26, lineHeight: "17px", fontWeight: 'bold' }} >
                            {total_kg_line7?.TOTAL_KG === undefined || total_kg_line7?.TOTAL_KG === '' ? (sessionStorage.getItem('total_kg_line7') === 'undefined' || sessionStorage.getItem('total_kg_line7') === '' ? '' : Math.round(Number(sessionStorage.getItem('total_kg_line7'))).toLocaleString()) : Math.round(total_kg_line7?.TOTAL_KG).toLocaleString()}&nbsp;
                          </Typography>
                          <Typography style={{ color: colors.grey[100], fontSize: 16, lineHeight: "17px", paddingTop: 5 }} >
                            kg&nbsp;
                          </Typography>
                        </Box>
                      </Grid>
                  }

                  {total_barrel_processing_line7?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === undefined && sessionStorage.getItem('total_barrel_processing_line7') === 'undefined'
                    ? ''
                    : total_barrel_processing_line7?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === '' || sessionStorage.getItem('total_barrel_processing_line7') === '' ? '' :
                      <Grid item xs={12} textAlign="center" style={{ paddingTop: 5 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                          <Typography style={{ color: '#b58514', fontSize: 22, lineHeight: "17px", fontWeight: 'bold' }} >
                            {total_barrel_processing_line7?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === undefined || total_barrel_processing_line7?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === '' ? (sessionStorage.getItem('total_barrel_processing_line7') === 'undefined' || sessionStorage.getItem('total_barrel_processing_line7') === '' ? '' : sessionStorage.getItem('total_barrel_processing_line7')) : total_barrel_processing_line7?.TOTAL_BARREL_PROCESSING_AND_COMPLETED} :&nbsp;
                          </Typography>
                          <Typography style={{ color: '#b58514', fontSize: 22, lineHeight: "17px", fontWeight: 'bold' }} >
                            {total_kg_processing_line7?.TOTAL_KG_PROCESSING_AND_COMPLETED === undefined || total_kg_processing_line7?.TOTAL_KG_PROCESSING_AND_COMPLETED === '' ? (sessionStorage.getItem('total_kg_processing_line7') === 'undefined' || sessionStorage.getItem('total_kg_processing_line7') === '' ? '' : Math.round(Number(sessionStorage.getItem('total_kg_processing_line7'))).toLocaleString()) : Math.round(total_kg_processing_line7?.TOTAL_KG_PROCESSING_AND_COMPLETED).toLocaleString()}&nbsp;
                          </Typography>
                        </Box>
                      </Grid>
                  }

                  {total_barrel_instock_line7?.TOTAL_BARREL_IN_STOCK === undefined && sessionStorage.getItem('total_barrel_instock_line7') === 'undefined'
                    ? ''
                    : total_barrel_instock_line7?.TOTAL_BARREL_IN_STOCK === '' || sessionStorage.getItem('total_barrel_instock_line7') === '' ? '' :
                      <Grid item xs={12} textAlign="center" style={{ paddingTop: 5, paddingLeft: 10, paddingRight: 10 }}>
                        <ItemTotalBarrel>
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                            <Typography style={{ color: '#2e9281', fontSize: 24, lineHeight: "20px", fontWeight: 'bold' }} >
                              {total_barrel_instock_line7?.TOTAL_BARREL_IN_STOCK === undefined || total_barrel_instock_line7?.TOTAL_BARREL_IN_STOCK === '' ? (sessionStorage.getItem('total_barrel_instock_line7') === 'undefined' || sessionStorage.getItem('total_barrel_instock_line7') === '' ? '' : sessionStorage.getItem('total_barrel_instock_line7')) : total_barrel_instock_line7?.TOTAL_BARREL_IN_STOCK} :&nbsp;
                            </Typography>
                            <Typography style={{ color: '#2e9281', fontSize: 24, lineHeight: "20px", fontWeight: 'bold' }} >
                              {total_kg_instock_line7?.TOTAL_KG_IN_STOCK === undefined || total_kg_instock_line7?.TOTAL_KG_IN_STOCK === '' ? (sessionStorage.getItem('total_kg_instock_line7') === 'undefined' || sessionStorage.getItem('total_kg_instock_line7') === '' ? '' : Math.round(Number(sessionStorage.getItem('total_kg_instock_line7'))).toLocaleString()) : Math.round(total_kg_instock_line7?.TOTAL_KG_IN_STOCK).toLocaleString()}&nbsp;
                            </Typography>
                          </Box>
                        </ItemTotalBarrel>
                      </Grid>
                  }

                </Item>
              </Grid>
              <Grid item xs={4} style={{ paddingRight: 3, height: '142px' }}>
                <Item>
                  <Grid item xs={12} textAlign="left" style={{ paddingTop: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                      <Typography style={{ color: '#2e9281', fontSize: 18, lineHeight: "10px", fontWeight: 'bold' }} >
                        L
                      </Typography>
                      <Typography style={{ color: '#2e9281', fontSize: 18, lineHeight: "10px", fontWeight: 'bold' }} >
                        08&nbsp;
                      </Typography>
                      <Typography style={{ color: colors.black[100], fontSize: 18, lineHeight: "10px", position: "relative", top: "0vw" }} >
                        {sku_line8?.CURRENT_SKU === undefined ? (sessionStorage.getItem('sku_line8') === 'undefined' || sessionStorage.getItem('sku_line8') === '' ? '' : sessionStorage.getItem('sku_line8')) : sku_line8?.CURRENT_SKU}
                      </Typography>
                    </Box>
                  </Grid>

                  {batch_line8?.CURRENT_BATCH === undefined && sessionStorage.getItem('batch_line8') === 'undefined'
                    ? ''
                    : batch_line8?.CURRENT_BATCH === '' || sessionStorage.getItem('batch_line8') === '' ? '' :
                      <Grid item xs={12} textAlign="center" style={{ paddingTop: 10, paddingLeft: 10, paddingRight: 10 }}>
                        <ItemSummary>
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                            <Typography style={{ color: colors.black[100], fontSize: 22, lineHeight: "10px", fontWeight: 'bold' }} >
                              Batch :&nbsp;
                            </Typography>
                            <Typography style={{ color: colors.black[100], fontSize: 22, lineHeight: "10px", fontWeight: 'bold' }} >
                              {batch_line8?.CURRENT_BATCH === undefined || batch_line8?.CURRENT_BATCH === '' ? (sessionStorage.getItem('batch_line8') === 'undefined' || sessionStorage.getItem('batch_line8') === '' ? '' : sessionStorage.getItem('batch_line8')) : batch_line8?.CURRENT_BATCH}
                            </Typography>
                          </Box>
                        </ItemSummary>
                      </Grid>
                  }

                  {total_barrel_line8?.TOTAL_BARREL === undefined && sessionStorage.getItem('total_barrel_line8') === 'undefined'
                    ? ''
                    : total_barrel_line8?.TOTAL_BARREL === '' || sessionStorage.getItem('total_barrel_line8') === '' ? '' :
                      <Grid item xs={12} textAlign="center" style={{ paddingTop: 7 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                          <Typography style={{ color: colors.black[100], fontSize: 26, lineHeight: "17px", fontWeight: 'bold' }} >
                            {total_barrel_line8?.TOTAL_BARREL === undefined || total_barrel_line8?.TOTAL_BARREL === '' ? (sessionStorage.getItem('total_barrel_line8') === 'undefined' || sessionStorage.getItem('total_barrel_line8') === '' ? '' : sessionStorage.getItem('total_barrel_line8')) : total_barrel_line8?.TOTAL_BARREL}&nbsp;
                          </Typography>
                          <Typography style={{ color: colors.grey[100], fontSize: 16, lineHeight: "17px", paddingTop: 5 }} >
                            Bin&nbsp;
                          </Typography>
                          <Typography style={{ color: colors.black[100], fontSize: 26, lineHeight: "17px", fontWeight: 'bold' }} >
                            {total_kg_line8?.TOTAL_KG === undefined || total_kg_line8?.TOTAL_KG === '' ? (sessionStorage.getItem('total_kg_line8') === 'undefined' || sessionStorage.getItem('total_kg_line8') === '' ? '' : Math.round(Number(sessionStorage.getItem('total_kg_line8'))).toLocaleString()) : Math.round(total_kg_line8?.TOTAL_KG).toLocaleString()}&nbsp;
                          </Typography>
                          <Typography style={{ color: colors.grey[100], fontSize: 16, lineHeight: "17px", paddingTop: 5 }} >
                            kg&nbsp;
                          </Typography>
                        </Box>
                      </Grid>
                  }
                  {total_barrel_processing_line8?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === undefined && sessionStorage.getItem('total_barrel_processing_line8') === 'undefined'
                    ? ''
                    : total_barrel_processing_line8?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === '' || sessionStorage.getItem('total_barrel_processing_line8') === '' ? '' :
                      <Grid item xs={12} textAlign="center" style={{ paddingTop: 5 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                          <Typography style={{ color: '#b58514', fontSize: 22, lineHeight: "17px", fontWeight: 'bold' }} >
                            {total_barrel_processing_line8?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === undefined || total_barrel_processing_line8?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === '' ? (sessionStorage.getItem('total_barrel_processing_line8') === 'undefined' || sessionStorage.getItem('total_barrel_processing_line8') === '' ? '' : sessionStorage.getItem('total_barrel_processing_line8')) : total_barrel_processing_line8?.TOTAL_BARREL_PROCESSING_AND_COMPLETED} :&nbsp;
                          </Typography>
                          <Typography style={{ color: '#b58514', fontSize: 22, lineHeight: "17px", fontWeight: 'bold' }} >
                            {total_kg_processing_line8?.TOTAL_KG_PROCESSING_AND_COMPLETED === undefined || total_kg_processing_line8?.TOTAL_KG_PROCESSING_AND_COMPLETED === '' ? (sessionStorage.getItem('total_kg_processing_line8') === 'undefined' || sessionStorage.getItem('total_kg_processing_line8') === '' ? '' : Math.round(Number(sessionStorage.getItem('total_kg_processing_line8'))).toLocaleString()) : Math.round(total_kg_processing_line8?.TOTAL_KG_PROCESSING_AND_COMPLETED).toLocaleString()}&nbsp;
                          </Typography>
                        </Box>
                      </Grid>
                  }

                  {total_barrel_instock_line8?.TOTAL_BARREL_IN_STOCK === undefined && sessionStorage.getItem('total_barrel_instock_line8') === 'undefined'
                    ? ''
                    : total_barrel_instock_line8?.TOTAL_BARREL_IN_STOCK === '' || sessionStorage.getItem('total_barrel_instock_line8') === '' ? '' :
                      <Grid item xs={12} textAlign="center" style={{ paddingTop: 5, paddingLeft: 10, paddingRight: 10 }}>
                        <ItemTotalBarrel>
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                            <Typography style={{ color: '#2e9281', fontSize: 24, lineHeight: "20px", fontWeight: 'bold' }} >
                              {total_barrel_instock_line8?.TOTAL_BARREL_IN_STOCK === undefined || total_barrel_instock_line8?.TOTAL_BARREL_IN_STOCK === '' ? (sessionStorage.getItem('total_barrel_instock_line8') === 'undefined' || sessionStorage.getItem('total_barrel_instock_line8') === '' ? '' : sessionStorage.getItem('total_barrel_instock_line8')) : total_barrel_instock_line8?.TOTAL_BARREL_IN_STOCK} :&nbsp;
                            </Typography>
                            <Typography style={{ color: '#2e9281', fontSize: 24, lineHeight: "20px", fontWeight: 'bold' }} >
                              {total_kg_instock_line8?.TOTAL_KG_IN_STOCK === undefined || total_kg_instock_line8?.TOTAL_KG_IN_STOCK === '' ? (sessionStorage.getItem('total_kg_instock_line8') === 'undefined' || sessionStorage.getItem('total_kg_instock_line8') === '' ? '' : Math.round(Number(sessionStorage.getItem('total_kg_instock_line8'))).toLocaleString()) : Math.round(total_kg_instock_line8?.TOTAL_KG_IN_STOCK).toLocaleString()}&nbsp;
                            </Typography>
                          </Box>
                        </ItemTotalBarrel>
                      </Grid>
                  }

                </Item>
              </Grid>
              <Grid item xs={4} style={{ paddingRight: 3, height: '142px' }}>
                <Item>
                  <Grid item xs={12} textAlign="left" style={{ paddingTop: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                      <Typography style={{ color: '#2e9281', fontSize: 18, lineHeight: "10px", fontWeight: 'bold' }} >
                        L
                      </Typography>
                      <Typography style={{ color: '#2e9281', fontSize: 18, lineHeight: "10px", fontWeight: 'bold' }} >
                        09&nbsp;
                      </Typography>
                      <Typography style={{ color: colors.black[100], fontSize: 18, lineHeight: "10px", position: "relative", top: "0vw" }} >
                        {sku_line9?.CURRENT_SKU === undefined ? (sessionStorage.getItem('sku_line9') === 'undefined' || sessionStorage.getItem('sku_line9') === '' ? '' : sessionStorage.getItem('sku_line9')) : sku_line9?.CURRENT_SKU}
                      </Typography>
                    </Box>
                  </Grid>

                  {batch_line9?.CURRENT_BATCH === undefined && sessionStorage.getItem('batch_line9') === 'undefined'
                    ? ''
                    : batch_line9?.CURRENT_BATCH === '' || sessionStorage.getItem('batch_line9') === '' ? '' :
                      <Grid item xs={12} textAlign="center" style={{ paddingTop: 10, paddingLeft: 10, paddingRight: 10 }}>
                        <ItemSummary>
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                            <Typography style={{ color: colors.black[100], fontSize: 22, lineHeight: "10px", fontWeight: 'bold' }} >
                              Batch :&nbsp;
                            </Typography>
                            <Typography style={{ color: colors.black[100], fontSize: 22, lineHeight: "10px", fontWeight: 'bold' }} >
                              {batch_line9?.CURRENT_BATCH === undefined || batch_line9?.CURRENT_BATCH === '' ? (sessionStorage.getItem('batch_line9') === 'undefined' || sessionStorage.getItem('batch_line9') === '' ? '' : sessionStorage.getItem('batch_line9')) : batch_line9?.CURRENT_BATCH}
                            </Typography>
                          </Box>
                        </ItemSummary>
                      </Grid>
                  }

                  {total_barrel_line9?.TOTAL_BARREL === undefined && sessionStorage.getItem('total_barrel_line9') === 'undefined'
                    ? ''
                    : total_barrel_line9?.TOTAL_BARREL === '' || sessionStorage.getItem('total_barrel_line9') === '' ? '' :
                      <Grid item xs={12} textAlign="center" style={{ paddingTop: 7 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                          <Typography style={{ color: colors.black[100], fontSize: 26, lineHeight: "17px", fontWeight: 'bold' }} >
                            {total_barrel_line9?.TOTAL_BARREL === undefined || total_barrel_line9?.TOTAL_BARREL === '' ? (sessionStorage.getItem('total_barrel_line9') === 'undefined' || sessionStorage.getItem('total_barrel_line9') === '' ? '' : sessionStorage.getItem('total_barrel_line9')) : total_barrel_line7?.TOTAL_BARREL}&nbsp;
                          </Typography>
                          <Typography style={{ color: colors.grey[100], fontSize: 16, lineHeight: "17px", paddingTop: 5 }} >
                            Bin&nbsp;
                          </Typography>
                          <Typography style={{ color: colors.black[100], fontSize: 26, lineHeight: "17px", fontWeight: 'bold' }} >
                            {total_kg_line9?.TOTAL_KG === undefined || total_kg_line9?.TOTAL_KG === '' ? (sessionStorage.getItem('total_kg_line9') === 'undefined' || sessionStorage.getItem('total_kg_line9') === '' ? '' : Math.round(Number(sessionStorage.getItem('total_kg_line9'))).toLocaleString()) : Math.round(total_kg_line9?.TOTAL_KG).toLocaleString()}&nbsp;
                          </Typography>
                          <Typography style={{ color: colors.grey[100], fontSize: 16, lineHeight: "17px", paddingTop: 5 }} >
                            kg&nbsp;
                          </Typography>
                        </Box>
                      </Grid>
                  }

                  {total_barrel_processing_line9?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === undefined && sessionStorage.getItem('total_barrel_processing_line9') === 'undefined'
                    ? ''
                    : total_barrel_processing_line9?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === '' || sessionStorage.getItem('total_barrel_processing_line9') === '' ? '' :
                      <Grid item xs={12} textAlign="center" style={{ paddingTop: 5 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                          <Typography style={{ color: '#b58514', fontSize: 22, lineHeight: "17px", fontWeight: 'bold' }} >
                            {total_barrel_processing_line9?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === undefined || total_barrel_processing_line9?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === '' ? (sessionStorage.getItem('total_barrel_processing_line9') === 'undefined' || sessionStorage.getItem('total_barrel_processing_line9') === '' ? '' : sessionStorage.getItem('total_barrel_processing_line9')) : total_barrel_processing_line9?.TOTAL_BARREL_PROCESSING_AND_COMPLETED} :&nbsp;
                          </Typography>
                          <Typography style={{ color: '#b58514', fontSize: 22, lineHeight: "17px", fontWeight: 'bold' }} >
                            {total_kg_processing_line9?.TOTAL_KG_PROCESSING_AND_COMPLETED === undefined || total_kg_processing_line9?.TOTAL_KG_PROCESSING_AND_COMPLETED === '' ? (sessionStorage.getItem('total_kg_processing_line9') === 'undefined' || sessionStorage.getItem('total_kg_processing_line9') === '' ? '' : Math.round(Number(sessionStorage.getItem('total_kg_processing_line9'))).toLocaleString()) : Math.round(total_kg_processing_line9?.TOTAL_KG_PROCESSING_AND_COMPLETED).toLocaleString()}&nbsp;
                          </Typography>
                        </Box>
                      </Grid>
                  }

                  {total_barrel_instock_line9?.TOTAL_BARREL_IN_STOCK === undefined && sessionStorage.getItem('total_barrel_instock_line9') === 'undefined'
                    ? ''
                    : total_barrel_instock_line9?.TOTAL_BARREL_IN_STOCK === '' || sessionStorage.getItem('total_barrel_instock_line9') === '' ? '' :
                      <Grid item xs={12} textAlign="center" style={{ paddingTop: 5, paddingLeft: 10, paddingRight: 10 }}>
                        <ItemTotalBarrel>
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                            <Typography style={{ color: '#2e9281', fontSize: 24, lineHeight: "20px", fontWeight: 'bold' }} >
                              {total_barrel_instock_line9?.TOTAL_BARREL_IN_STOCK === undefined || total_barrel_instock_line9?.TOTAL_BARREL_IN_STOCK === '' ? (sessionStorage.getItem('total_barrel_instock_line9') === 'undefined' || sessionStorage.getItem('total_barrel_instock_line9') === '' ? '' : sessionStorage.getItem('total_barrel_instock_line9')) : total_barrel_instock_line9?.TOTAL_BARREL_IN_STOCK} :&nbsp;
                            </Typography>
                            <Typography style={{ color: '#2e9281', fontSize: 24, lineHeight: "20px", fontWeight: 'bold' }} >
                              {total_kg_instock_line9?.TOTAL_KG_IN_STOCK === undefined || total_kg_instock_line9?.TOTAL_KG_IN_STOCK === '' ? (sessionStorage.getItem('total_kg_instock_line9') === 'undefined' || sessionStorage.getItem('total_kg_instock_line9') === '' ? '' : Math.round(Number(sessionStorage.getItem('total_kg_instock_line9'))).toLocaleString()) : Math.round(total_kg_instock_line9?.TOTAL_KG_IN_STOCK).toLocaleString()}&nbsp;
                            </Typography>
                          </Box>
                        </ItemTotalBarrel>
                      </Grid>
                  }

                </Item>
              </Grid>
              <Grid item xs={4} style={{ paddingRight: 3, height: '142px' }}>
                <Item>
                  <Grid item xs={12} textAlign="left" style={{ paddingTop: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                      <Typography style={{ color: '#2e9281', fontSize: 18, lineHeight: "10px", fontWeight: 'bold' }} >
                        L
                      </Typography>
                      <Typography style={{ color: '#2e9281', fontSize: 18, lineHeight: "10px", fontWeight: 'bold' }} >
                        10&nbsp;
                      </Typography>
                      <Typography style={{ color: colors.black[100], fontSize: 18, lineHeight: "10px", position: "relative", top: "0vw" }} >
                        {sku_line10?.CURRENT_SKU === undefined ? (sessionStorage.getItem('sku_line10') === 'undefined' || sessionStorage.getItem('sku_line10') === '' ? '' : sessionStorage.getItem('sku_line10')) : sku_line10?.CURRENT_SKU}
                      </Typography>
                    </Box>
                  </Grid>

                  {batch_line10?.CURRENT_BATCH === undefined && sessionStorage.getItem('batch_line10') === 'undefined'
                    ? ''
                    : batch_line10?.CURRENT_BATCH === '' || sessionStorage.getItem('batch_line10') === '' ? '' :
                      <Grid item xs={12} textAlign="center" style={{ paddingTop: 10, paddingLeft: 10, paddingRight: 10 }}>
                        <ItemSummary>
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                            <Typography style={{ color: colors.black[100], fontSize: 22, lineHeight: "10px", fontWeight: 'bold' }} >
                              Batch :&nbsp;
                            </Typography>
                            <Typography style={{ color: colors.black[100], fontSize: 22, lineHeight: "10px", fontWeight: 'bold' }} >
                              {batch_line10?.CURRENT_BATCH === undefined || batch_line10?.CURRENT_BATCH === '' ? (sessionStorage.getItem('batch_line10') === 'undefined' || sessionStorage.getItem('batch_line10') === '' ? '' : sessionStorage.getItem('batch_line10')) : batch_line10?.CURRENT_BATCH}
                            </Typography>
                          </Box>
                        </ItemSummary>
                      </Grid>
                  }

                  {total_barrel_line10?.TOTAL_BARREL === undefined && sessionStorage.getItem('total_barrel_line10') === 'undefined'
                    ? ''
                    : total_barrel_line10?.TOTAL_BARREL === '' || sessionStorage.getItem('total_barrel_line10') === '' ? '' :
                      <Grid item xs={12} textAlign="center" style={{ paddingTop: 7 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                          <Typography style={{ color: colors.black[100], fontSize: 26, lineHeight: "17px", fontWeight: 'bold' }} >
                            {total_barrel_line10?.TOTAL_BARREL === undefined || total_barrel_line10?.TOTAL_BARREL === '' ? (sessionStorage.getItem('total_barrel_line10') === 'undefined' || sessionStorage.getItem('total_barrel_line10') === '' ? '' : sessionStorage.getItem('total_barrel_line10')) : total_barrel_line10?.TOTAL_BARREL}&nbsp;
                          </Typography>
                          <Typography style={{ color: colors.grey[100], fontSize: 16, lineHeight: "17px", paddingTop: 5 }} >
                            Bin&nbsp;
                          </Typography>
                          <Typography style={{ color: colors.black[100], fontSize: 26, lineHeight: "17px", fontWeight: 'bold' }} >
                            {total_kg_line10?.TOTAL_KG === undefined || total_kg_line10?.TOTAL_KG === '' ? (sessionStorage.getItem('total_kg_line10') === 'undefined' || sessionStorage.getItem('total_kg_line10') === '' ? '' : Math.round(Number(sessionStorage.getItem('total_kg_line10'))).toLocaleString()) : Math.round(total_kg_line10?.TOTAL_KG).toLocaleString()}&nbsp;
                          </Typography>
                          <Typography style={{ color: colors.grey[100], fontSize: 16, lineHeight: "17px", paddingTop: 5 }} >
                            kg&nbsp;
                          </Typography>
                        </Box>
                      </Grid>
                  }

                  {total_barrel_processing_line10?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === undefined && sessionStorage.getItem('total_barrel_processing_line10') === 'undefined'
                    ? ''
                    : total_barrel_processing_line10?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === '' || sessionStorage.getItem('total_barrel_processing_line10') === '' ? '' :
                      <Grid item xs={12} textAlign="center" style={{ paddingTop: 5 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                          <Typography style={{ color: '#b58514', fontSize: 22, lineHeight: "17px", fontWeight: 'bold' }} >
                            {total_barrel_processing_line10?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === undefined || total_barrel_processing_line10?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === '' ? (sessionStorage.getItem('total_barrel_processing_line10') === 'undefined' || sessionStorage.getItem('total_barrel_processing_line10') === '' ? '' : sessionStorage.getItem('total_barrel_processing_line10')) : total_barrel_processing_line10?.TOTAL_BARREL_PROCESSING_AND_COMPLETED} :&nbsp;
                          </Typography>
                          <Typography style={{ color: '#b58514', fontSize: 22, lineHeight: "17px", fontWeight: 'bold' }} >
                            {total_kg_processing_line10?.TOTAL_KG_PROCESSING_AND_COMPLETED === undefined || total_kg_processing_line10?.TOTAL_KG_PROCESSING_AND_COMPLETED === '' ? (sessionStorage.getItem('total_kg_processing_line10') === 'undefined' || sessionStorage.getItem('total_kg_processing_line10') === '' ? '' : Math.round(Number(sessionStorage.getItem('total_kg_processing_line10'))).toLocaleString()) : Math.round(total_kg_processing_line10?.TOTAL_KG_PROCESSING_AND_COMPLETED).toLocaleString()}&nbsp;
                          </Typography>
                        </Box>
                      </Grid>
                  }

                  {total_barrel_instock_line10?.TOTAL_BARREL_IN_STOCK === undefined && sessionStorage.getItem('total_barrel_instock_line10') === 'undefined'
                    ? ''
                    : total_barrel_instock_line10?.TOTAL_BARREL_IN_STOCK === '' || sessionStorage.getItem('total_barrel_instock_line10') === '' ? '' :
                      <Grid item xs={12} textAlign="center" style={{ paddingTop: 5, paddingLeft: 10, paddingRight: 10 }}>
                        <ItemTotalBarrel>
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                            <Typography style={{ color: '#2e9281', fontSize: 24, lineHeight: "20px", fontWeight: 'bold' }} >
                              {total_barrel_instock_line10?.TOTAL_BARREL_IN_STOCK === undefined || total_barrel_instock_line10?.TOTAL_BARREL_IN_STOCK === '' ? (sessionStorage.getItem('total_barrel_instock_line10') === 'undefined' || sessionStorage.getItem('total_barrel_instock_line10') === '' ? '' : sessionStorage.getItem('total_barrel_instock_line10')) : total_barrel_instock_line10?.TOTAL_BARREL_IN_STOCK} :&nbsp;
                            </Typography>
                            <Typography style={{ color: '#2e9281', fontSize: 24, lineHeight: "20px", fontWeight: 'bold' }} >
                              {total_kg_instock_line10?.TOTAL_KG_IN_STOCK === undefined || total_kg_instock_line10?.TOTAL_KG_IN_STOCK === '' ? (sessionStorage.getItem('total_kg_instock_line10') === 'undefined' || sessionStorage.getItem('total_kg_instock_line10') === '' ? '' : Math.round(Number(sessionStorage.getItem('total_kg_instock_line10'))).toLocaleString()) : Math.round(total_kg_instock_line10?.TOTAL_KG_IN_STOCK).toLocaleString()}&nbsp;
                            </Typography>
                          </Box>
                        </ItemTotalBarrel>
                      </Grid>
                  }

                </Item>
              </Grid>
              <Grid item xs={4} style={{ paddingRight: 3, height: '142px' }}>
                <Item>
                  <Grid item xs={12} textAlign="left" style={{ paddingTop: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                      <Typography style={{ color: '#2e9281', fontSize: 18, lineHeight: "10px", fontWeight: 'bold' }} >
                        L
                      </Typography>
                      <Typography style={{ color: '#2e9281', fontSize: 18, lineHeight: "10px", fontWeight: 'bold' }} >
                        11&nbsp;
                      </Typography>
                      <Typography style={{ color: colors.black[100], fontSize: 18, lineHeight: "10px", position: "relative", top: "0vw" }} >
                        {sku_line11?.CURRENT_SKU === undefined ? (sessionStorage.getItem('sku_line11') === 'undefined' || sessionStorage.getItem('sku_line11') === '' ? '' : sessionStorage.getItem('sku_line11')) : sku_line11?.CURRENT_SKU}
                      </Typography>
                    </Box>
                  </Grid>

                  {batch_line11?.CURRENT_BATCH === undefined && sessionStorage.getItem('batch_line11') === 'undefined'
                    ? ''
                    : batch_line11?.CURRENT_BATCH === '' || sessionStorage.getItem('batch_line11') === '' ? '' :
                      <Grid item xs={12} textAlign="center" style={{ paddingTop: 10, paddingLeft: 10, paddingRight: 10 }}>
                        <ItemSummary>
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                            <Typography style={{ color: colors.black[100], fontSize: 22, lineHeight: "10px", fontWeight: 'bold' }} >
                              Batch :&nbsp;
                            </Typography>
                            <Typography style={{ color: colors.black[100], fontSize: 22, lineHeight: "10px", fontWeight: 'bold' }} >
                              {batch_line11?.CURRENT_BATCH === undefined || batch_line11?.CURRENT_BATCH === '' ? (sessionStorage.getItem('batch_line11') === 'undefined' || sessionStorage.getItem('batch_line11') === '' ? '' : sessionStorage.getItem('batch_line11')) : batch_line11?.CURRENT_BATCH}
                            </Typography>
                          </Box>
                        </ItemSummary>
                      </Grid>
                  }

                  {total_barrel_line11?.TOTAL_BARREL === undefined && sessionStorage.getItem('total_barrel_line11') === 'undefined'
                    ? ''
                    : total_barrel_line11?.TOTAL_BARREL === '' || sessionStorage.getItem('total_barrel_line11') === '' ? '' :
                      <Grid item xs={12} textAlign="center" style={{ paddingTop: 7 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                          <Typography style={{ color: colors.black[100], fontSize: 26, lineHeight: "17px", fontWeight: 'bold' }} >
                            {total_barrel_line11?.TOTAL_BARREL === undefined || total_barrel_line11?.TOTAL_BARREL === '' ? (sessionStorage.getItem('total_barrel_line11') === 'undefined' || sessionStorage.getItem('total_barrel_line11') === '' ? '' : sessionStorage.getItem('total_barrel_line11')) : total_barrel_line11?.TOTAL_BARREL}&nbsp;
                          </Typography>
                          <Typography style={{ color: colors.grey[100], fontSize: 16, lineHeight: "17px", paddingTop: 5 }} >
                            Bin&nbsp;
                          </Typography>
                          <Typography style={{ color: colors.black[100], fontSize: 26, lineHeight: "17px", fontWeight: 'bold' }} >
                            {total_kg_line11?.TOTAL_KG === undefined || total_kg_line11?.TOTAL_KG === '' ? (sessionStorage.getItem('total_kg_line11') === 'undefined' || sessionStorage.getItem('total_kg_line11') === '' ? '' : Math.round(Number(sessionStorage.getItem('total_kg_line11'))).toLocaleString()) : Math.round(total_kg_line11?.TOTAL_KG).toLocaleString()}&nbsp;
                          </Typography>
                          <Typography style={{ color: colors.grey[100], fontSize: 16, lineHeight: "17px", paddingTop: 5 }} >
                            kg&nbsp;
                          </Typography>
                        </Box>
                      </Grid>
                  }

                  {total_barrel_processing_line11?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === undefined && sessionStorage.getItem('total_barrel_processing_line11') === 'undefined'
                    ? ''
                    : total_barrel_processing_line11?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === '' || sessionStorage.getItem('total_barrel_processing_line11') === '' ? '' :
                      <Grid item xs={12} textAlign="center" style={{ paddingTop: 5 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                          <Typography style={{ color: '#b58514', fontSize: 22, lineHeight: "17px", fontWeight: 'bold' }} >
                            {total_barrel_processing_line11?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === undefined || total_barrel_processing_line11?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === '' ? (sessionStorage.getItem('total_barrel_processing_line11') === 'undefined' || sessionStorage.getItem('total_barrel_processing_line11') === '' ? '' : sessionStorage.getItem('total_barrel_processing_line11')) : total_barrel_processing_line11?.TOTAL_BARREL_PROCESSING_AND_COMPLETED} :&nbsp;
                          </Typography>
                          <Typography style={{ color: '#b58514', fontSize: 22, lineHeight: "17px", fontWeight: 'bold' }} >
                            {total_kg_processing_line11?.TOTAL_KG_PROCESSING_AND_COMPLETED === undefined || total_kg_processing_line11?.TOTAL_KG_PROCESSING_AND_COMPLETED === '' ? (sessionStorage.getItem('total_kg_processing_line11') === 'undefined' || sessionStorage.getItem('total_kg_processing_line11') === '' ? '' : Math.round(Number(sessionStorage.getItem('total_kg_processing_line11'))).toLocaleString()) : Math.round(total_kg_processing_line11?.TOTAL_KG_PROCESSING_AND_COMPLETED).toLocaleString()}&nbsp;
                          </Typography>
                        </Box>
                      </Grid>
                  }

                  {total_barrel_instock_line11?.TOTAL_BARREL_IN_STOCK === undefined && sessionStorage.getItem('total_barrel_instock_line11') === 'undefined'
                    ? ''
                    : total_barrel_instock_line11?.TOTAL_BARREL_IN_STOCK === '' || sessionStorage.getItem('total_barrel_instock_line11') === '' ? '' :
                      <Grid item xs={12} textAlign="center" style={{ paddingTop: 5, paddingLeft: 10, paddingRight: 10 }}>
                        <ItemTotalBarrel>
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                            <Typography style={{ color: '#2e9281', fontSize: 24, lineHeight: "20px", fontWeight: 'bold' }} >
                              {total_barrel_instock_line11?.TOTAL_BARREL_IN_STOCK === undefined || total_barrel_instock_line11?.TOTAL_BARREL_IN_STOCK === '' ? (sessionStorage.getItem('total_barrel_instock_line11') === 'undefined' || sessionStorage.getItem('total_barrel_instock_line11') === '' ? '' : sessionStorage.getItem('total_barrel_instock_line11')) : total_barrel_instock_line11?.TOTAL_BARREL_IN_STOCK} :&nbsp;
                            </Typography>
                            <Typography style={{ color: '#2e9281', fontSize: 24, lineHeight: "20px", fontWeight: 'bold' }} >
                              {total_kg_instock_line11?.TOTAL_KG_IN_STOCK === undefined || total_kg_instock_line11?.TOTAL_KG_IN_STOCK === '' ? (sessionStorage.getItem('total_kg_instock_line11') === 'undefined' || sessionStorage.getItem('total_kg_instock_line11') === '' ? '' : Math.round(Number(sessionStorage.getItem('total_kg_instock_line11'))).toLocaleString()) : Math.round(total_kg_instock_line11?.TOTAL_KG_IN_STOCK).toLocaleString()}&nbsp;
                            </Typography>
                          </Box>
                        </ItemTotalBarrel>
                      </Grid>
                  }

                </Item>
              </Grid>
              <Grid item xs={4} style={{ paddingRight: 3, height: '142px' }}>
                <Item>
                  <Grid item xs={12} textAlign="left" style={{ paddingTop: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                      <Typography style={{ color: '#2e9281', fontSize: 18, lineHeight: "10px", fontWeight: 'bold' }} >
                        L
                      </Typography>
                      <Typography style={{ color: '#2e9281', fontSize: 18, lineHeight: "10px", fontWeight: 'bold' }} >
                        12&nbsp;
                      </Typography>
                      <Typography style={{ color: colors.black[100], fontSize: 18, lineHeight: "10px", position: "relative", top: "0vw" }} >
                        {sku_line12?.CURRENT_SKU === undefined ? (sessionStorage.getItem('sku_line12') === 'undefined' || sessionStorage.getItem('sku_line12') === '' ? '' : sessionStorage.getItem('sku_line12')) : sku_line12?.CURRENT_SKU}
                      </Typography>
                    </Box>
                  </Grid>

                  {batch_line12?.CURRENT_BATCH === undefined && sessionStorage.getItem('batch_line12') === 'undefined'
                    ? ''
                    : batch_line12?.CURRENT_BATCH === '' || sessionStorage.getItem('batch_line12') === '' ? '' :
                      <Grid item xs={12} textAlign="center" style={{ paddingTop: 10, paddingLeft: 10, paddingRight: 10 }}>
                        <ItemSummary>
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                            <Typography style={{ color: colors.black[100], fontSize: 22, lineHeight: "10px", fontWeight: 'bold' }} >
                              Batch :&nbsp;
                            </Typography>
                            <Typography style={{ color: colors.black[100], fontSize: 22, lineHeight: "10px", fontWeight: 'bold' }} >
                              {batch_line12?.CURRENT_BATCH === undefined || batch_line12?.CURRENT_BATCH === '' ? (sessionStorage.getItem('batch_line12') === 'undefined' || sessionStorage.getItem('batch_line12') === '' ? '' : sessionStorage.getItem('batch_line12')) : batch_line12?.CURRENT_BATCH}
                            </Typography>
                          </Box>
                        </ItemSummary>
                      </Grid>
                  }

                  {total_barrel_line12?.TOTAL_BARREL === undefined && sessionStorage.getItem('total_barrel_line12') === 'undefined'
                    ? ''
                    : total_barrel_line12?.TOTAL_BARREL === '' || sessionStorage.getItem('total_barrel_line12') === '' ? '' :
                      <Grid item xs={12} textAlign="center" style={{ paddingTop: 7 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                          <Typography style={{ color: colors.black[100], fontSize: 26, lineHeight: "17px", fontWeight: 'bold' }} >
                            {total_barrel_line12?.TOTAL_BARREL === undefined || total_barrel_line12?.TOTAL_BARREL === '' ? (sessionStorage.getItem('total_barrel_line12') === 'undefined' || sessionStorage.getItem('total_barrel_line12') === '' ? '' : sessionStorage.getItem('total_barrel_line12')) : total_barrel_line12?.TOTAL_BARREL}&nbsp;
                          </Typography>
                          <Typography style={{ color: colors.grey[100], fontSize: 16, lineHeight: "17px", paddingTop: 5 }} >
                            Bin&nbsp;
                          </Typography>
                          <Typography style={{ color: colors.black[100], fontSize: 26, lineHeight: "17px", fontWeight: 'bold' }} >
                            {total_kg_line12?.TOTAL_KG === undefined || total_kg_line12?.TOTAL_KG === '' ? (sessionStorage.getItem('total_kg_line12') === 'undefined' || sessionStorage.getItem('total_kg_line12') === '' ? '' : Math.round(Number(sessionStorage.getItem('total_kg_line12'))).toLocaleString()) : Math.round(total_kg_line12?.TOTAL_KG).toLocaleString()}&nbsp;
                          </Typography>
                          <Typography style={{ color: colors.grey[100], fontSize: 16, lineHeight: "17px", paddingTop: 5 }} >
                            kg&nbsp;
                          </Typography>
                        </Box>
                      </Grid>
                  }

                  {total_barrel_processing_line12?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === undefined && sessionStorage.getItem('total_barrel_processing_line12') === 'undefined'
                    ? ''
                    : total_barrel_processing_line12?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === '' || sessionStorage.getItem('total_barrel_processing_line12') === '' ? '' :
                      <Grid item xs={12} textAlign="center" style={{ paddingTop: 5 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                          <Typography style={{ color: '#b58514', fontSize: 22, lineHeight: "17px", fontWeight: 'bold' }} >
                            {total_barrel_processing_line12?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === undefined || total_barrel_processing_line12?.TOTAL_BARREL_PROCESSING_AND_COMPLETED === '' ? (sessionStorage.getItem('total_barrel_processing_line12') === 'undefined' || sessionStorage.getItem('total_barrel_processing_line12') === '' ? '' : sessionStorage.getItem('total_barrel_processing_line12')) : total_barrel_processing_line12?.TOTAL_BARREL_PROCESSING_AND_COMPLETED} :&nbsp;
                          </Typography>
                          <Typography style={{ color: '#b58514', fontSize: 22, lineHeight: "17px", fontWeight: 'bold' }} >
                            {total_kg_processing_line12?.TOTAL_KG_PROCESSING_AND_COMPLETED === undefined || total_kg_processing_line12?.TOTAL_KG_PROCESSING_AND_COMPLETED === '' ? (sessionStorage.getItem('total_kg_processing_line12') === 'undefined' || sessionStorage.getItem('total_kg_processing_line12') === '' ? '' : Math.round(Number(sessionStorage.getItem('total_kg_processing_line12'))).toLocaleString()) : Math.round(total_kg_processing_line12?.TOTAL_KG_PROCESSING_AND_COMPLETED).toLocaleString()}&nbsp;
                          </Typography>
                        </Box>
                      </Grid>
                  }

                  {total_barrel_instock_line12?.TOTAL_BARREL_IN_STOCK === undefined && sessionStorage.getItem('total_barrel_instock_line12') === 'undefined'
                    ? ''
                    : total_barrel_instock_line12?.TOTAL_BARREL_IN_STOCK === '' || sessionStorage.getItem('total_barrel_instock_line12') === '' ? '' :
                      <Grid item xs={12} textAlign="center" style={{ paddingTop: 5, paddingLeft: 10, paddingRight: 10 }}>
                        <ItemTotalBarrel>
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                            <Typography style={{ color: '#2e9281', fontSize: 24, lineHeight: "20px", fontWeight: 'bold' }} >
                              {total_barrel_instock_line12?.TOTAL_BARREL_IN_STOCK === undefined || total_barrel_instock_line12?.TOTAL_BARREL_IN_STOCK === '' ? (sessionStorage.getItem('total_barrel_instock_line12') === 'undefined' || sessionStorage.getItem('total_barrel_instock_line12') === '' ? '' : sessionStorage.getItem('total_barrel_instock_line12')) : total_barrel_instock_line12?.TOTAL_BARREL_IN_STOCK} :&nbsp;
                            </Typography>
                            <Typography style={{ color: '#2e9281', fontSize: 24, lineHeight: "20px", fontWeight: 'bold' }} >
                              {total_kg_instock_line12?.TOTAL_KG_IN_STOCK === undefined || total_kg_instock_line12?.TOTAL_KG_IN_STOCK === '' ? (sessionStorage.getItem('total_kg_instock_line12') === 'undefined' || sessionStorage.getItem('total_kg_instock_line12') === '' ? '' : Math.round(Number(sessionStorage.getItem('total_kg_instock_line12'))).toLocaleString()) : Math.round(total_kg_instock_line12?.TOTAL_KG_IN_STOCK).toLocaleString()}&nbsp;
                            </Typography>
                          </Box>
                        </ItemTotalBarrel>
                      </Grid>
                  }

                </Item>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </>
  );
}