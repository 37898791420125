import React, { useEffect, useState } from "react";
import { Box, Grid, Card, Container } from "@mui/material";
import { Helmet } from "react-helmet";
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import LensIcon from '@mui/icons-material/Lens';
import db from "firebase-config";
import Check from '../assets/images/check.png';
import No from '../assets/images/no.png';
import Switch_on from '../assets/images/switch-on.png';
import Switch_off from '../assets/images/switch-off.png';
import { Link as RouterLink, useNavigate } from 'react-router-dom';




function CorrelationTest() {
    const navigate = useNavigate();
    const [check_list, setCheck_list] = useState([]);


    useEffect(() => {
        const getPostFromFirebase = [];
        const subscriber = db
            .collection("meskr_giveaway_tag_list")
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    getPostFromFirebase.push({
                        ...doc.data(),
                        id: doc.id,
                    });
                });

                setCheck_list(getPostFromFirebase);
            });

        // return cleanup function
        return () => subscriber();
    }, [check_list]);


    const cloud_enable = check_list.find((user) => user.TAG.includes("Simulator.4117.GiveAway.Multihead1.Line7_1.PI_Cloud_Enable"));

    const no_error = check_list.find((user) => user.TAG.includes("Further.4117.Multihead.Multihead1.Line7_1.0NoError"));

    const infeed_control = check_list.find((user) => user.TAG.includes("Simulator.4117.GiveAway.Multihead1.Line7_1.PI_InfeedControl"));

    const df_weight = check_list.find((user) => user.TAG.includes("Simulator.4117.GiveAway.Multihead1.Line7_1.PI_DFWeight"));

    const target_weight = check_list.find((user) => user.TAG.includes("Simulator.4117.GiveAway.Multihead1.Line7_1.PI_TargetWeight"));

    const upper_weight = check_list.find((user) => user.TAG.includes("Simulator.4117.GiveAway.Multihead1.Line7_1.PI_UpperWeight"));

    const lower_weight = check_list.find((user) => user.TAG.includes("Simulator.4117.GiveAway.Multihead1.Line7_1.PI_LowerWeight"));

    const speed = check_list.find((user) => user.TAG.includes("Simulator.4117.GiveAway.Multihead1.Line7_1.PI_Speed"));

    const no_error_check_weight = check_list.find((user) => user.TAG.includes("Simulator.4117.ISHIDA.CheckWeight.Line7.0NoError"));

    const over_reject = check_list.find((user) => user.TAG.includes("Simulator.4117.GiveAway.Multihead1.Line7_1.PI_OverReject"));

    const product_name = check_list.find((user) => user.TAG.includes("Simulator.4117.GiveAway.Multihead1.Line7_1.PI_ProductName"));

    const upper_weight_compare = check_list.find((user) => user.TAG.includes("Simulator.4117.GiveAway.Multihead1.Line7_1.PI_UpperWeight_Compare"));

    const multihead_status = check_list.find((user) => user.TAG.includes("Further.4117.Multihead.Multihead1.Line7_1.CMD_C1_MACHINE_OPERATION"));

    const permissive = check_list.find((user) => user.TAG.includes("_AdvancedTags.4117.GiveAway.Interlock.Permissive_1"));


    useEffect(() => {
        if (`${localStorage.getItem('rb_Admin')}` == 'false' && `${localStorage.getItem('rb_Staff')}` == 'false') {
            navigate('/check_list', { replace: true });
        } else if (`${localStorage.getItem('rb_Admin')}` == 'false' && `${localStorage.getItem('rb_Staff')}` == 'true') {
            navigate('/preparation', { replace: true });
        }
    }, []);



    return (
        <>
            <Helmet>
                <title>Check list | MES Korat Fur2</title>
            </Helmet>
            <Container maxWidth={false} sx={{ p: 0 }}>
                <Grid
                    container
                    style={{ paddingTop: 60 }}
                >
                    {/* Top row display date */}

                    <Grid item lg={12} md={12} sm={12} xl={12} xs={12} sx={{ display: 'flex', justifyContent: 'center' }} >
                        <Typography style={{ fontWeight: "bold", fontSize: 36, position: "relative", left: 0, paddingTop: 0 }} textAlign="center">
                            สถานะความพร้อมการผลิต
                        </Typography>
                    </Grid>


                    <Grid item lg={12} md={12} sm={12} xl={12} xs={12} sx={{ display: 'flex', justifyContent: 'center' }} >
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                            <Typography style={{ position: "relative", paddingLeft: 0 }}>
                                {permissive?.VALUE == true ? <img src={Check} height="50px" /> : <img src={No} height="50px" />}&nbsp;&nbsp;
                            </Typography>
                            <Typography style={{ color: permissive?.VALUE == true ? '#00d084' : '#e53935', fontWeight: "bold", fontSize: 60, position: "relative", left: 0, paddingTop: 0 }} textAlign="center">
                                {permissive?.VALUE == true ? 'Ready' : 'Not Ready'}&nbsp;&nbsp;
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center' }}>

                        <Grid item lg={8} md={8} xl={8} xs={12} sx={{ display: 'flex', justifyContent: 'left', border: "1px solid #cfcfcf" }}>

                            <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                                &nbsp;&nbsp;<LensIcon fontSize="small" stroke={cloud_enable?.VALUE == true ? '#00d084' : '#9e9e9e'} strokeWidth="2" sx={{ color: cloud_enable?.VALUE == true ? '#00d084' : '#9e9e9e' }} style={{ position: "relative", top: "0.2vw" }}></LensIcon>
                                <Typography style={{ fontSize: 22, fontFamily: "CPF_Imm_Sook", color: cloud_enable?.VALUE == true ? '#2e9281' : '#9e9e9e' }} >
                                    &nbsp;&nbsp;Enable control giveaway function
                                </Typography>

                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center' }}>

                        <Grid item xs={12} md={4} xl={4} lg={4} sx={{ border: "1px solid #cfcfcf" }} style={{ position: "relative", top: "2vw" }}>
                            <Grid item xs={12} md={12} xl={12} lg={12} textAlign="left" >
                                <Typography style={{ color: '#2e9281', fontSize: 25, fontWeight: 'bold', position: "relative", top: "0vw", left: "0vw" }}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Multihead
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} xl={12} lg={12} textAlign="left" >
                                <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                                    &nbsp;&nbsp;<LensIcon fontSize="small" stroke={no_error?.VALUE == true ? '#00d084' : '#9e9e9e'} strokeWidth="2" sx={{ color: no_error?.VALUE == true ? '#00d084' : '#9e9e9e' }} style={{ position: "relative", top: "0.2vw" }}></LensIcon>
                                    <Typography style={{ fontSize: 22, fontFamily: "CPF_Imm_Sook", color: no_error?.VALUE == true ? '#000000' : '#9e9e9e' }} >
                                        &nbsp;&nbsp;1. เครื่องเชื่อมต่อ Internet =
                                    </Typography>
                                    <Typography style={{ fontSize: 22, fontFamily: "CPF_Imm_Sook", color: no_error?.VALUE == true ? '#2e9281' : '#9e9e9e' }} >
                                        &nbsp;&nbsp;สำเร็จ
                                    </Typography>
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                                    &nbsp;&nbsp;<LensIcon fontSize="small" stroke={infeed_control?.VALUE == true ? '#00d084' : '#9e9e9e'} strokeWidth="2" sx={{ color: infeed_control?.VALUE == true ? '#00d084' : '#9e9e9e' }} style={{ position: "relative", top: "0.2vw" }}></LensIcon>
                                    <Typography style={{ fontSize: 22, fontFamily: "CPF_Imm_Sook", color: infeed_control?.VALUE == true ? '#000000' : '#9e9e9e' }} >
                                        &nbsp;&nbsp;2. Infeed control =
                                    </Typography>
                                    <Typography style={{ fontSize: 22, fontFamily: "CPF_Imm_Sook", color: infeed_control?.VALUE == true ? '#2e9281' : '#9e9e9e' }} >
                                        &nbsp;&nbsp;เปิด
                                    </Typography>
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                                    &nbsp;&nbsp;<LensIcon fontSize="small" stroke={df_weight?.VALUE == true ? '#00d084' : '#9e9e9e'} strokeWidth="2" sx={{ color: df_weight?.VALUE == true ? '#00d084' : '#9e9e9e' }} style={{ position: "relative", top: "0.2vw" }}></LensIcon>
                                    <Typography style={{ fontSize: 22, fontFamily: "CPF_Imm_Sook", color: df_weight?.VALUE == true ? '#000000' : '#9e9e9e' }} >
                                        &nbsp;&nbsp;3. น้ำหนักจาน DF (g) =
                                    </Typography>
                                    <Typography style={{ fontSize: 22, fontFamily: "CPF_Imm_Sook", color: df_weight?.VALUE == true ? '#2e9281' : '#9e9e9e' }} >
                                        &nbsp;&nbsp;Master Sku
                                    </Typography>
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                                    &nbsp;&nbsp;<LensIcon fontSize="small" stroke={target_weight?.VALUE == true ? '#00d084' : '#9e9e9e'} strokeWidth="2" sx={{ color: target_weight?.VALUE == true ? '#00d084' : '#9e9e9e' }} style={{ position: "relative", top: "0.2vw" }}></LensIcon>
                                    <Typography style={{ fontSize: 22, fontFamily: "CPF_Imm_Sook", color: target_weight?.VALUE == true ? '#000000' : '#9e9e9e' }} >
                                        &nbsp;&nbsp;4. น้ำหนักเป้าหมาย (g) =
                                    </Typography>
                                    <Typography style={{ fontSize: 22, fontFamily: "CPF_Imm_Sook", color: target_weight?.VALUE == true ? '#2e9281' : '#9e9e9e' }} >
                                        &nbsp;&nbsp;Master Sku
                                    </Typography>
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                                    &nbsp;&nbsp;<LensIcon fontSize="small" stroke={upper_weight?.VALUE == true ? '#00d084' : '#9e9e9e'} strokeWidth="2" sx={{ color: upper_weight?.VALUE == true ? '#00d084' : '#9e9e9e' }} style={{ position: "relative", top: "0.2vw" }}></LensIcon>
                                    <Typography style={{ fontSize: 22, fontFamily: "CPF_Imm_Sook", color: upper_weight?.VALUE == true ? '#000000' : '#9e9e9e' }} >
                                        &nbsp;&nbsp;5. น้ำหนักขอบบน (g) =
                                    </Typography>
                                    <Typography style={{ fontSize: 22, fontFamily: "CPF_Imm_Sook", color: upper_weight?.VALUE == true ? '#2e9281' : '#9e9e9e' }} >
                                        &nbsp;&nbsp;Master Sku
                                    </Typography>
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                                    &nbsp;&nbsp;<LensIcon fontSize="small" stroke={lower_weight?.VALUE == true ? '#00d084' : '#9e9e9e'} strokeWidth="2" sx={{ color: lower_weight?.VALUE == true ? '#00d084' : '#9e9e9e' }} style={{ position: "relative", top: "0.2vw" }}></LensIcon>
                                    <Typography style={{ fontSize: 22, fontFamily: "CPF_Imm_Sook", color: lower_weight?.VALUE == true ? '#000000' : '#9e9e9e' }} >
                                        &nbsp;&nbsp;6. น้ำหนักขอบล่าง (g) =
                                    </Typography>
                                    <Typography style={{ fontSize: 22, fontFamily: "CPF_Imm_Sook", color: lower_weight?.VALUE == true ? '#2e9281' : '#9e9e9e' }} >
                                        &nbsp;&nbsp;ปิด
                                    </Typography>
                                    <Typography style={{ fontSize: 22, fontFamily: "CPF_Imm_Sook", color: lower_weight?.VALUE == true ? '#000000' : '#9e9e9e' }} >
                                        &nbsp;&nbsp;หรือ=
                                    </Typography>
                                    <Typography style={{ fontSize: 22, fontFamily: "CPF_Imm_Sook", color: lower_weight?.VALUE == true ? '#2e9281' : '#9e9e9e' }} >
                                        &nbsp;&nbsp;0
                                    </Typography>
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                                    &nbsp;&nbsp;<LensIcon fontSize="small" stroke={speed?.VALUE == true ? '#00d084' : '#9e9e9e'} strokeWidth="2" sx={{ color: speed?.VALUE == true ? '#00d084' : '#9e9e9e' }} style={{ position: "relative", top: "0.2vw" }}></LensIcon>
                                    <Typography style={{ fontSize: 22, fontFamily: "CPF_Imm_Sook", color: speed?.VALUE == true ? '#000000' : '#9e9e9e' }} >
                                        &nbsp;&nbsp;7. ความเร็วรอบ (wpm) =
                                    </Typography>
                                    <Typography style={{ fontSize: 22, fontFamily: "CPF_Imm_Sook", color: speed?.VALUE == true ? '#2e9281' : '#9e9e9e' }} >
                                        &nbsp;&nbsp;Master Sku
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={4} xl={4} lg={4} textAlign="center" style={{ position: "relative", top: "1vw" }}>
                            <Grid item xs={12} md={12} xl={12} lg={12} textAlign="center" sx={{ border: "1px solid #cfcfcf" }}>
                                <Grid item xs={12} textAlign="left" >
                                    <Typography style={{ color: '#2e9281', fontSize: 25, fontWeight: 'bold', position: "relative", top: "0vw", left: "0vw" }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Check weight
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} textAlign="left" >
                                    <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                                        &nbsp;&nbsp;<LensIcon fontSize="small" stroke={no_error_check_weight?.VALUE == true ? '#00d084' : '#9e9e9e'} strokeWidth="2" sx={{ color: no_error_check_weight?.VALUE == true ? '#00d084' : '#9e9e9e' }} style={{ position: "relative", top: "0.2vw" }}></LensIcon>
                                        <Typography style={{ fontSize: 22, fontFamily: "CPF_Imm_Sook", color: no_error_check_weight?.VALUE == true ? '#000000' : '#9e9e9e' }} >
                                            &nbsp;&nbsp;1. เครื่องเชื่อมต่อ Internet =
                                        </Typography>
                                        <Typography style={{ fontSize: 22, fontFamily: "CPF_Imm_Sook", color: no_error_check_weight?.VALUE == true ? '#2e9281' : '#9e9e9e' }} >
                                            &nbsp;&nbsp;สำเร็จ
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                                        &nbsp;&nbsp;<LensIcon fontSize="small" stroke={over_reject?.VALUE == true ? '#00d084' : '#9e9e9e'} strokeWidth="2" sx={{ color: over_reject?.VALUE == true ? '#00d084' : '#9e9e9e' }} style={{ position: "relative", top: "0.2vw" }}></LensIcon>
                                        <Typography style={{ fontSize: 22, fontFamily: "CPF_Imm_Sook", color: over_reject?.VALUE == true ? '#000000' : '#9e9e9e' }} >
                                            &nbsp;&nbsp;2. Reject ต่อเนื่อง =
                                        </Typography>
                                        <Typography style={{ fontSize: 22, fontFamily: "CPF_Imm_Sook", color: over_reject?.VALUE == true ? '#2e9281' : '#9e9e9e' }} >
                                            &nbsp;&nbsp;3 ถุง
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={12} xl={12} lg={12} textAlign="center" sx={{ border: "1px solid #cfcfcf" }} style={{ position: "relative", top: "1.5vw" }}>
                                <Grid item xs={12} textAlign="left" >
                                    <Typography style={{ color: '#2e9281', fontSize: 25, fontWeight: 'bold', position: "relative", top: "0vw", left: "0vw" }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Multihead และ Check weight
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} textAlign="left" >
                                    <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                                        &nbsp;&nbsp;<LensIcon fontSize="small" stroke={product_name?.VALUE == true ? '#00d084' : '#9e9e9e'} strokeWidth="2" sx={{ color: product_name?.VALUE == true ? '#00d084' : '#9e9e9e' }} style={{ position: "relative", top: "0.2vw" }}></LensIcon>
                                        <Typography style={{ fontSize: 22, fontFamily: "CPF_Imm_Sook", color: product_name?.VALUE == true ? '#000000' : '#9e9e9e' }} >
                                            &nbsp;&nbsp;1. ชื่อ SKU =
                                        </Typography>
                                        <Typography style={{ fontSize: 22, fontFamily: "CPF_Imm_Sook", color: product_name?.VALUE == true ? '#2e9281' : '#9e9e9e' }} >
                                            &nbsp;&nbsp;ตรงกัน
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                                        &nbsp;&nbsp;<LensIcon fontSize="small" stroke={upper_weight_compare?.VALUE == true ? '#00d084' : '#9e9e9e'} strokeWidth="2" sx={{ color: upper_weight_compare?.VALUE == true ? '#00d084' : '#9e9e9e' }} style={{ position: "relative", top: "0.2vw" }}></LensIcon>
                                        <Typography style={{ fontSize: 22, fontFamily: "CPF_Imm_Sook", color: upper_weight_compare?.VALUE == true ? '#000000' : '#9e9e9e' }} >
                                            &nbsp;&nbsp;2. น้ำหนักรวม/ถุง สูงสุด (g) =
                                        </Typography>
                                        <Typography style={{ fontSize: 22, fontFamily: "CPF_Imm_Sook", color: upper_weight_compare?.VALUE == true ? '#2e9281' : '#9e9e9e' }} >
                                            &nbsp;&nbsp;เท่ากัน
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Grid item xs={12} md={8} xl={8} lg={8} style={{ position: "relative", top: "7vw", background: "#cfcfcf" }} textAlign="center" >
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                <Typography style={{ position: "relative", paddingLeft: 0 }}>
                                    {multihead_status?.VALUE == 1 ? <img src={Switch_on} height="70px" /> : <img src={Switch_off} height="70px" />}&nbsp;&nbsp;
                                </Typography>
                                <Typography style={{ color: "#000000", fontWeight: "bold", fontSize: 36, position: "relative", left: 0, paddingTop: 0 }} textAlign="center">
                                    Multihead Status
                                </Typography>
                            </Box>

                        </Grid>
                    </Grid>

                </Grid>
            </Container>
        </>
    );
}

export default CorrelationTest;