import React, { useEffect, useState } from "react";
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { DarkMode } from '@mui/icons-material';
import { useMsal } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";
import { blueGrey, deepOrange, grey, teal } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import HomeSVG from "assets/images/svg/house.svg";
import MonitorSVG from "assets/images/svg/monitors.svg";
import Conveyor from "assets/images/svg/conveyors.svg";
import Phone from "assets/images/svg/phone.svg";
import Weight from "assets/images/svg/weight.svg";
import Update from "assets/images/svg/update.svg";
import Report from "assets/images/svg/report.svg";
import Target from "assets/images/svg/target1.svg"
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment-timezone';
import { apiUrl } from "constants";
import LogoutIcon from '@mui/icons-material/Logout';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Circular from '../model/Circular';
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Container, Grid,
  Button,
  Menu,
  MenuItem,
  Fade,
  Typography,
  Avatar,
  Tooltip
} from '@material-ui/core';
import FactoryIcon from '@mui/icons-material/Factory';
import PropTypes from 'prop-types';


const drawerWidth = 200;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));



const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);



const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  listItemIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  drawer: {
    width: drawerWidth,
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(5)
  },
  drawerPaper: {
    width: drawerWidth
  }
}));

const pages = ['Home', 'Monitor', 'Production', 'Giveaway', 'TargetLine7', 'on mobile', 'ReportLine7'];



function Sidebar({ onMobileNavOpen, ...rest }) {
  const settings = `${localStorage.getItem('settings')}`.split(",")
  //console.log(`${localStorage.getItem('settings')}`.split(","))
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useStyles();
  const [mode, setMode] = React.useState('light');
  const [open, setOpen] = React.useState(false);
  const [detail, setDetail] = useState({
    SCHEDULE_START_DATE: "",
    ACCUM_WORK_TIME: ""
  });
  const [updatePO, setUpdatePO] = useState();
  const [notifyUpdatePOSuccessful, setNotifyUpdatePOSuccessful] = useState(false);
  const [notifyUpdatePOFailed, setNotifyUpdatePOFailed] = useState(false);
  const [done, setDone] = useState(false);

  const today = new Date();
  const hourago = new Date(today.getTime());
  const timestr = moment(hourago).tz('Asia/Bangkok').format('YYYY-MM-DD');
  const time = moment(hourago).tz('Asia/Bangkok').format('HH:mm:ss');



  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (e) => {
    setAnchorElNav(null);
    switch (e.target.innerHTML) {
      case 'Home':
        navigate(`/home`, { replace: true });
        break;
      case 'Monitor':
        navigate(`/monitor`, { replace: true });
        break;
      case 'Production':
        navigate(`/production`, { replace: true });
        break;
      /*case 'Giveaway':
        navigate(`/giveaway`, { replace: true });
        break;*/
      /*case 'on mobile':
        window.open("https://immjai-linemonitor.cpfmes.com/preparation");
        break;*/
      case 'ReportLine7':
        navigate(`/reportLine7`, { replace: true });
        break;

      default:
        break;
    }
  };

  // จะทำงานเมื่อuser click menuในdropdownของAvatarชื่อuserที่มุมขวาของnav
  const handleCloseUserMenu = (e) => {
    setAnchorElUser(null);
    // หลังจากคลิ๊กจะredirectไปตามpathที่วางไว้
    switch (e.target.innerHTML) {
      case 'Logout':
        localStorage.clear();
        navigate('/login', { replace: true });
        break;
      case 'Account':
        navigate('/settings', { replace: true });
        break;
      case 'Profile':
        navigate(`/user-edit?id=${localStorage.getItem('rb_UserId')}`, { replace: true });
        break;
      case 'Home':
        navigate(`/home`, { replace: true });
        break;
      case 'Giveaway':
        navigate(`/check_list`, { replace: true });
        break;
      case 'Preparation Zone':
        navigate(`/preparation`, { replace: true });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    async function fetchMyAPI() {
      //console.log('get_data ------>')
      var get_data = `${apiUrl.GET_ALL_FACTORY_DATA}${timestr}/` // date format must be YYYY-MM-DD, eg. 2022-06-16
      axios.get(get_data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
          "X-xss-protection": "1; mode=block;",
          "X-frame-options": "SAMEORIGIN",
          "X-content-type-options": "nosniff",
          "Cache-Control": "no-cache, private",
          "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
          "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
        }
      }
      )
        .then(response => {
          //console.log(response.data)
          if (response.status == 200) {
            //console.log(response.data.DETAIL[0].WORK_CENTER);
            setDetail({
              SCHEDULE_START_DATE: response.data.DETAIL[0].SCHEDULE_START_DATE,
              ACCUM_WORK_TIME: response.data.DETAIL[0].ACCUM_WORK_TIME,
            })

          }
        })
        .catch(error => {
          //console.log('------------------->')
          console.log('error: ', error)

        })
    }
    fetchMyAPI()

    const interval = setInterval(() => fetchMyAPI(), 10 * 60 * 1000)
    return () => {
      clearInterval(interval);
    }

  }, []);

  //console.log("d",detail)

  const handleUpdatePO = async () => {
    //var token = localStorage.getItem("rb_ac_token");
    setDone(true);
    await axios({
      method: 'post', //you can set what request you want to be
      url: `${apiUrl.UPDATE_SAP_PO}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
        "X-xss-protection": "1; mode=block;",
        "X-frame-options": "SAMEORIGIN",
        "X-content-type-options": "nosniff",
        "Cache-Control": "no-cache, private",
        "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
        "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
      }
    })
      .then(response => {
        // console.log(response)
        if (response.status == 200) {
          console.log("update PO");
          setUpdatePO(1);
          setNotifyUpdatePOSuccessful(true);
          setDone(false);
        }
        else {
          setUpdatePO(2)
        }
      })
      .catch(error => {
        setDone(false);
        console.log('update po error: ', error);
      })
  }



  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...(mode === 'light'
            ? {
              primary: teal,
              background: {
                default: teal[200],
                paper: teal[200],
              },
              divider: teal[50],
              text: {
                primary: grey[900],
                secondary: grey[800],
              },
              typography: {
                button: {
                  fontWeight: 600
                },
                fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
                body1: {
                  fontSize: '1rem',
                  fontWeight: 400,
                  lineHeight: 1.5
                },
                body2: {
                  fontSize: '0.875rem',
                  fontWeight: 400,
                  lineHeight: 1.57
                },
                subtitle1: {
                  fontSize: '1rem',
                  fontWeight: 500,
                  lineHeight: 1.75
                },
                subtitle2: {
                  fontSize: '0.875rem',
                  fontWeight: 500,
                  lineHeight: 1.57
                },
                overline: {
                  fontSize: '0.75rem',
                  fontWeight: 600,
                  letterSpacing: '0.5px',
                  lineHeight: 2.5,
                  textTransform: 'uppercase'
                },
                caption: {
                  fontSize: '0.75rem',
                  fontWeight: 400,
                  lineHeight: 1.66
                },
                h1: {
                  fontWeight: 700,
                  fontSize: '3.5rem',
                  lineHeight: 1.375
                },
                h2: {
                  fontWeight: 700,
                  fontSize: '3rem',
                  lineHeight: 1.375
                },
                h3: {
                  fontWeight: 700,
                  fontSize: '2.25rem',
                  lineHeight: 1.375
                },
                h4: {
                  fontWeight: 700,
                  fontSize: '2rem',
                  lineHeight: 1.375
                },
                h5: {
                  fontWeight: 600,
                  fontSize: '1.5rem',
                  lineHeight: 1.375
                },
                h6: {
                  fontWeight: 600,
                  fontSize: '1.125rem',
                  lineHeight: 1.375
                },
                h7: {
                  fontWeight: 600,
                  fontSize: '1.0rem',
                  lineHeight: 1.375
                }
              }
            }
            : {
              primary: blueGrey,
              divider: blueGrey[700],
              background: {
                default: deepOrange[900],
                paper: blueGrey[900],
              },
              text: {
                primary: '#fff',
                secondary: grey[500],
              },
              typography: {
                button: {
                  fontWeight: 600
                },
                fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
                body1: {
                  fontSize: '1rem',
                  fontWeight: 400,
                  lineHeight: 1.5
                },
                body2: {
                  fontSize: '0.875rem',
                  fontWeight: 400,
                  lineHeight: 1.57
                },
                subtitle1: {
                  fontSize: '1rem',
                  fontWeight: 500,
                  lineHeight: 1.75
                },
                subtitle2: {
                  fontSize: '0.875rem',
                  fontWeight: 500,
                  lineHeight: 1.57
                },
                overline: {
                  fontSize: '0.75rem',
                  fontWeight: 600,
                  letterSpacing: '0.5px',
                  lineHeight: 2.5,
                  textTransform: 'uppercase'
                },
                caption: {
                  fontSize: '0.75rem',
                  fontWeight: 400,
                  lineHeight: 1.66
                },
                h1: {
                  fontWeight: 700,
                  fontSize: '3.5rem',
                  lineHeight: 1.375
                },
                h2: {
                  fontWeight: 700,
                  fontSize: '3rem',
                  lineHeight: 1.375
                },
                h3: {
                  fontWeight: 700,
                  fontSize: '2.25rem',
                  lineHeight: 1.375
                },
                h4: {
                  fontWeight: 700,
                  fontSize: '2rem',
                  lineHeight: 1.375
                },
                h5: {
                  fontWeight: 600,
                  fontSize: '1.5rem',
                  lineHeight: 1.375
                },
                h6: {
                  fontWeight: 600,
                  fontSize: '1.125rem',
                  lineHeight: 1.375
                },
                h7: {
                  fontWeight: 600,
                  fontSize: '1.0rem',
                  lineHeight: 1.375
                }
              }
            }),
        },
        components: {
          MuiButton: {
            defaultProps: {
              disableElevation: true
            },
            styleOverrides: {
              root: {
                textTransform: 'none'
              },
              sizeSmall: {
                padding: '6px 16px'
              },
              sizeMedium: {
                padding: '8px 20px'
              },
              sizeLarge: {
                padding: '11px 24px'
              },
              textSizeSmall: {
                padding: '7px 12px'
              },
              textSizeMedium: {
                padding: '9px 16px'
              },
              textSizeLarge: {
                padding: '12px 16px'
              }
            }
          },
          MuiButtonBase: {
            defaultProps: {
              disableRipple: true
            }
          },
          MuiCardContent: {
            styleOverrides: {
              root: {
                padding: '32px 24px',
                '&:last-child': {
                  paddingBottom: '32px'
                }
              },
            }
          },
          MuiCardHeader: {
            defaultProps: {
              titleTypographyProps: {
                variant: 'h6'
              },
              subheaderTypographyProps: {
                variant: 'body2'
              }
            },
            styleOverrides: {
              root: {
                padding: '32px 24px'
              }
            }
          },
          MuiCssBaseline: {
            styleOverrides: {
              '*': {
                boxSizing: 'border-box',
                margin: 0,
                padding: 0
              },
              html: {
                MozOsxFontSmoothing: 'grayscale',
                WebkitFontSmoothing: 'antialiased',
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100%',
                width: '100%'
              },
              body: {
                display: 'flex',
                flex: '1 1 auto',
                flexDirection: 'column',
                minHeight: '100%',
                width: '100%'
              },
              '#__next': {
                display: 'flex',
                flex: '1 1 auto',
                flexDirection: 'column',
                height: '100%',
                width: '100%'
              }
            }
          },
          MuiOutlinedInput: {
            styleOverrides: {
              notchedOutline: {
                borderColor: '#E6E8F0'
              }
            }
          },
          MuiTableHead: {
            styleOverrides: {
              root: {
                backgroundColor: '#000000',
                '.MuiTableCell-root': {
                  color: '#FFF'
                },
                borderBottom: 'none',
                '& .MuiTableCell-root': {
                  borderBottom: 'none',
                  fontSize: '12px',
                  fontWeight: 600,
                  lineHeight: 1,
                  letterSpacing: 0.5,
                  textTransform: 'uppercase',
                  border: '1px solid rgba(255,255,255,0.05)'
                },
                '& .MuiTableCell-paddingCheckbox': {
                  paddingTop: 4,
                  paddingBottom: 4
                }
              }
            }
          },
        },

      }),
    [mode],
  );


  return (
    <>
      <ThemeProvider theme={theme}>
        <AppBar celevation={0} {...rest}>

          <Container maxWidth="xl">
            <Toolbar disableGutters sx={{ height: 64 }}>
              <Typography
                fontSize='22px'
                fontWeight="bold"
                fontFamily='CPF_Imm_Sook'
                noWrap
                component="div"
                sx={{ marginRight: 25, display: { xs: 'none', md: 'flex' } }}
                style={{ position: "relative", left: "50px" }}
              >
                MES Korat Further 2&nbsp;&nbsp;{detail.SCHEDULE_START_DATE}&nbsp;&nbsp;{time}&nbsp;&nbsp; เวลาการทำงานสะสม&nbsp;&nbsp;{detail.ACCUM_WORK_TIME}
              </Typography>

              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  {pages.map((page) => (
                    <MenuItem key={page}  style={{fontSize: 18, fontFamily: 'CPF_Imm_Sook'}} onClick={handleCloseNavMenu}>
                      <Typography textAlign="center" style={{fontSize: 18, fontFamily: 'CPF_Imm_Sook'}}>{page}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              <Typography
                fontSize='16px'
                fontWeight="bold"
                noWrap
                component="div"
                sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
              >

                {/*<FactoryIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />*/}
                MES Korat Further 2
              </Typography>

              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                <Drawer variant="permanent">
                  <DrawerHeader>

                  </DrawerHeader>
                  <Divider />
                  <List>
                    {/* Homepage*/}
                    <ListItem key="homepage" disablePadding sx={{ display: 'block' }}>
                      <RouterLink to={"/home"} style={{ textDecoration: 'none' }}>
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                          }}

                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              height: '20px',
                              mr: open ? 3 : 'auto',
                              justifyContent: 'center',
                              fontSize: '13px'
                            }}
                            className={classes.listItemIcon}
                          >
                            <img src={HomeSVG} height="24px" />
                            Home
                          </ListItemIcon>
                          <ListItemText primary="Home" sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                      </RouterLink>
                    </ListItem>
                  </List>
                  <Divider />

                  <Divider />
                  <List>
                    {/* Monitor */}
                    <ListItem key="monitor" disablePadding sx={{ display: 'block' }}>
                      <RouterLink to={"/monitor"} style={{ textDecoration: 'none' }}>
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                          }}

                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              height: '20px',
                              mr: open ? 3 : 'auto',
                              justifyContent: 'center',
                              fontSize: '13px'
                            }}
                            className={classes.listItemIcon}
                          >
                            <img src={MonitorSVG} height="24px" />
                            Monitor
                          </ListItemIcon>
                          <ListItemText primary="monitor" sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                      </RouterLink>
                    </ListItem>
                  </List>
                  <Divider />

                  <Divider />
                  <List>
                    {/* Production*/}
                    <ListItem key="production" disablePadding sx={{ display: 'block' }}>
                      <RouterLink to={"/production"} style={{ textDecoration: 'none' }}>
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              height: '20px',
                              mr: open ? 3 : 'auto',
                              justifyContent: 'center',
                              fontSize: '13px'
                            }}
                            className={classes.listItemIcon}
                          >
                            <img src={Conveyor} height="24px" />
                            Production
                          </ListItemIcon>
                          <ListItemText primary="production" sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                      </RouterLink>
                    </ListItem>
                  </List>
                  <Divider />

                  {/*<Divider />
                  <List>
                    <ListItem key="giveaway" disablePadding sx={{ display: 'block' }}>
                      <RouterLink to={"/giveaway"} style={{ textDecoration: 'none' }}>
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              height: '20px',
                              mr: open ? 3 : 'auto',
                              justifyContent: 'center',
                              fontSize: '13px'
                            }}
                            className={classes.listItemIcon}
                          >
                            <img src={Weight} height="24px" />
                            Giveaway
                          </ListItemIcon>
                          <ListItemText primary="giveaway" sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                      </RouterLink>
                    </ListItem>
                  </List>
                  <Divider />*/}

                  {/*<Divider />
                  <List>

                    <ListItem key="OnMobile" disablePadding sx={{ display: 'block' }} onClick={() => window.open("https://immjai-linemonitor.cpfmes.com/preparation")}> { http://3.1.94.92:1001/preparation}
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            height: '20px',
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                            fontSize: '13px'
                          }}
                          className={classes.listItemIcon}
                        >
                          <img src={Phone} height="24px" />
                          on mobile
                        </ListItemIcon>
                        <ListItemText primary="production" sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>
                  </List>
                        <Divider />*/}

                  <Divider />
                  <List>
                    {/*Report*/}
                    <ListItem key="reportLine7" disablePadding sx={{ display: 'block' }}>
                      <RouterLink to={"/reportLine7"} style={{ textDecoration: 'none' }}>
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              height: '20px',
                              mr: open ? 3 : 'auto',
                              justifyContent: 'center',
                              fontSize: '13px'
                            }}
                            className={classes.listItemIcon}
                          >
                            <img src={Report} height="24px" />
                            Report Line7
                          </ListItemIcon>
                          <ListItemText primary="reportLine7" sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                      </RouterLink>
                    </ListItem>
                  </List>
                  <Divider />


                  <Divider />
                  <List>
                    {/*Update PO*/}
                    <ListItem key="updatePO" disablePadding sx={{ display: 'block' }}>
                      {done ? <Circular /> : <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 2.5,
                        }}
                        onClick={handleUpdatePO}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            height: '20px',
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                            fontSize: '13px'
                          }}
                          className={classes.listItemIcon}
                        >
                          <img src={Update} height="24px" />
                          Update PO
                        </ListItemIcon>
                        <ListItemText primary="updatePO" sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>}
                    </ListItem>
                  </List>
                  <Divider />


                  {/* log out*/}
                  {/*<List>
                    <ListItem key="logout" disablePadding sx={{ display: 'block' }}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 2.5,
                        }}
                        onClick={() => handleLogout(instance)}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            height: '20px',
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                            fontSize: '11px'
                          }}
                          className={classes.listItemIcon}
                        >
                          <LogoutIcon />
                          Log out
                        </ListItemIcon>
                        <ListItemText primary="Log out" sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>
                    <Divider />
                  </List>*/}
                </Drawer>
              </Box>

              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt={localStorage.getItem("rb_email").toUpperCase()} src="/static/images/avatar/2.jpg" />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  style={{fontSize: 18, fontFamily: 'CPF_Imm_Sook'}}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem key={setting} accessKey={setting} style={{fontSize: 18, fontFamily: 'CPF_Imm_Sook'}} onClick={e => handleCloseUserMenu(e)}>
                      <ListItemText textAlign="center" style={{fontSize: 18, fontFamily: 'CPF_Imm_Sook'}} >{setting}</ListItemText>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Toolbar>
          </Container>


        </AppBar>
      </ThemeProvider>

      {updatePO == 1 ?
        <div style={{ marginTop: 20 }}>
          <Dialog
            open={notifyUpdatePOSuccessful}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description" minWidth='200px' align='center'>
                Successfully update PO
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button style={{ fontFamily: 'Monospace', backgroundColor: '#009688' }} variant="contained" onClick={() => { setNotifyUpdatePOSuccessful(false); }}>OK</Button>
            </DialogActions>
          </Dialog>

        </div>
        : console.log()
      }
      {updatePO == 2 ?
        <div style={{ marginTop: 20 }}>
          <Dialog
            open={notifyUpdatePOFailed}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description" minWidth='200px' align='center'>
                Failed to update PO, Plead try again
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button style={{ fontFamily: 'Monospace', backgroundColor: '#009688' }} aligh='Center' variant="contained" onClick={() => { setNotifyUpdatePOFailed(false); }}>OK</Button>
            </DialogActions>
          </Dialog>
        </div>
        : console.log()
      }

    </>
  );
}

Sidebar.propTypes = {
  onMobileNavOpen: PropTypes.func
}

export default Sidebar
