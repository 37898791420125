import React, { useEffect, useState } from "react";
import { Box, Grid, Card } from "@mui/material";
import { Helmet } from "react-helmet";
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import authorize from '../assets/images/svg/authorize.png';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { apiUrl } from "../constants";
import db from "firebase-config";
import LensIcon from '@mui/icons-material/Lens';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

const filter = createFilterOptions();
var token_url = apiUrl.TOKEN;



function Unlock() {
    const navigate = useNavigate();
    const [seleteUserName, setSeleteUserName] = useState(null);
    const [openSelectUserName, setOpenSelectUserName] = useState(false);
    const [seleteAuthorize, setSeleteAuthorize] = useState(null);
    const [openSelectAuthorize, setOpenSelectAuthorize] = useState(false);
    const [openConfirmChangeAuthorize, setOpenConfirmChangeAuthorize] = useState();
    const [unlockSuccessful, setUnlockSuccessful] = useState();
    const [notifySubmittedUnlockSuccessful, setNotifySubmittedUnlockSuccessful] = useState(false);
    const [notifySubmittedUnlockFailed, setNotifySubmittedUnlockFailed] = useState(false);


    const [seleteNote, setSeleteNote] = useState(null);
    const [enable_giveaway, setEnable_giveaway] = useState([]);
    const [User, setUser] = useState([]);


    const handleOpenSelectUserName = () => {
        setOpenSelectUserName(true);
    };

    const handleCloseSelectUserName = () => {
        setOpenSelectUserName(false);
    };

    const handleOpenSelectAuthorize = () => {
        setOpenSelectAuthorize(true);
    };

    const handleCloseSelectAuthorize = () => {
        setOpenSelectAuthorize(false);
    };

    const handleClickConfirmChangeAuthorize = () => {
        setOpenConfirmChangeAuthorize(true);
    };

    const handleCloseConfirmChangeAuthorize = () => {
        setOpenConfirmChangeAuthorize(false);
    };




    const handleSelectionInUnlockform = (event) => {
        if (event.target.name === "user") {
            setSeleteUserName(event.target.value);
        }
        if (event.target.name === "status") {
            setSeleteAuthorize(event.target.value);
        }
        if (event.target.name === "note") {
            setSeleteNote(event.target.value);
        }
    };

    // ฟังก์ชั่นเช็คtoken
    const checkToken = async () => {
        let access_exp = localStorage.getItem("rb_ac_exp");
        let refresh_exp = localStorage.getItem("rb_rf_exp");
        //console.log('access_exp: ' + access_exp);
        //console.log('refresh_exp: ' + refresh_exp);
        if (refresh_exp < Date.now() / 1000) {
            console.log("refresh is expiried");
            // alert('Please log in again')
            localStorage.clear();
            window.location = "/Login";
        } else if (access_exp < Date.now() / 1000) {
            const refresh = localStorage.getItem("rb_rf_token");

            let data = {
                refresh: refresh,
            };
            let result = await axios.post(token_url, data);
            //console.log("got new access_token!");
            localStorage.setItem("rb_ac_token", result.data.access);
        }
    };

    useEffect(() => {
        async function fetchMyAPI() {
            // check access token expiry
            checkToken();
            //console.log('get_allkorat ------>')
            var get_user = `${apiUrl.GET_USER}` // date format must be YYYY-MM-DD, eg. 2022-06-16
            await axios.get(get_user, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
                    "X-xss-protection": "1; mode=block;",
                    "X-frame-options": "SAMEORIGIN",
                    "X-content-type-options": "nosniff",
                    "Cache-Control": "no-cache, private",
                    "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
                    "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
                }
            }
            )
                .then(response => {
                    //console.log(response)
                    if (response.status == 200) {
                        setUser(response.data.filter(i => i.email === localStorage.getItem('rb_email')))
                    }
                })
                .catch(error => {
                    console.log('error: ', error)

                })
        }
        fetchMyAPI()
    }, []);

    const handleUnlock = async () => {
        console.log({
            "plant_id": "4117",
            "line": "LINE7",
            "user": User.map(x => x.first_name) + " " + User.map(x => x.last_name),
            "status": (get_enable?.VALUE == true ? 'ปิดการใช้งาน' : 'เปิดการใช้งาน') == 'เปิดการใช้งาน' ? true : false,
            "note": seleteNote
        });

        await axios.post(apiUrl.UNLOCK_GIVEAWAY, {
            "plant_id": "4117",
            "line": "LINE7",
            "user": User.map(x => x.first_name) + " " + User.map(x => x.last_name),
            "status": (get_enable?.VALUE == true ? 'ปิดการใช้งาน' : 'เปิดการใช้งาน') == 'เปิดการใช้งาน' ? true : false,
            "note": seleteNote
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
                "X-xss-protection": "1; mode=block;",
                "X-frame-options": "SAMEORIGIN",
                "X-content-type-options": "nosniff",
                "Cache-Control": "no-cache, private",
                "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
                "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
            }
        })
            .then(response => {
                // console.log(response)
                if (response.status == 200) {
                    setSeleteUserName();
                    setSeleteAuthorize();
                    setSeleteNote();
                    setUnlockSuccessful(1)
                    setNotifySubmittedUnlockSuccessful(true);
                }
                else {
                    setUnlockSuccessful(2)
                    setNotifySubmittedUnlockFailed(true);
                }
            })
            .catch(error => {
                console.log('unlock error: ', error);
            })
    }



    useEffect(() => {
        const getPostFromFirebase = [];
        const subscriber = db
            .collection("meskr_giveaway_tag_list")
            .where("TAG", "==", "Simulator.4117.GiveAway.Multihead1.Line7_1.PI_Cloud_Enable")
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    getPostFromFirebase.push({
                        ...doc.data(),
                        id: doc.id,
                    });
                });
                setEnable_giveaway(getPostFromFirebase);
            });

        // return cleanup function
        return () => subscriber();
    }, [enable_giveaway]);


    useEffect(() => {
        if (`${localStorage.getItem('rb_Admin')}` == 'false' && `${localStorage.getItem('rb_Staff')}` == 'false') {
            navigate('/unlock', { replace: true });
        } else if (`${localStorage.getItem('rb_Admin')}` == 'false' && `${localStorage.getItem('rb_Staff')}` == 'true') {
            navigate('/preparation', { replace: true });
        }
    }, []);


    const get_enable = enable_giveaway.find((tag) => tag.TAG.includes("Simulator.4117.GiveAway.Multihead1.Line7_1.PI_Cloud_Enable"));



    return (
        <>
            <Helmet>
                <title>Unlock | MES Korat Fur2</title>
            </Helmet>
            <Grid
                container spacing={1}
                direction="row"
                alignItems="top"
                justifyContent="center"
                style={{ minHeight: '78vh', maxHeight: '100vh', flexGrow: 1, paddingLeft: 25, paddingTop: 75 }}
            >
                {/* Top row display date */}
                <Grid xs={12} >
                    <Box>
                        <Typography style={{ color: "#000000", fontWeight: "bold", fontSize: "30px", position: "relative", paddingLeft: 0, paddingTop: 10 }} textAlign="center">
                            {get_enable?.VALUE == true ? 'ปิดการใช้งาน Giveaway' : 'เปิดการใช้งาน Giveaway'}
                        </Typography>
                        <Typography style={{ position: "relative", paddingLeft: 0, paddingTop: 10 }} textAlign="center">
                            <img src={authorize} height="150px" />
                        </Typography>
                        {enable_giveaway.map((item) => {
                            return <Typography style={{ fontWeight: "bold", fontSize: "16px", position: "relative", left: 0, paddingTop: 10 }} textAlign="center">
                                <LensIcon fontSize="medium" stroke={item.VALUE == true ? '#00d084' : '#9e9e9e'} strokeWidth="2" sx={{ color: item.VALUE == true ? '#00d084' : '#9e9e9e' }} style={{ position: "relative", paddingLeft: 0, top: 5 }}></LensIcon>
                                &nbsp;{item.VALUE == true ? 'เปิดการใช้งาน' : 'ปิดการใช้งาน'}
                            </Typography>
                        })}
                    </Box>

                    <Box>
                        <Typography style={{ fontSize: '16px', fontWeight: 'medium', paddingLeft: 0, paddingTop: 30 }}>
                            ชื่อ
                        </Typography>
                        <TextField
                            //required
                            id="outlined-required"
                            //type="number"
                            name="user"
                            value={User.map(x => x.first_name) + " " + User.map(x => x.last_name)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleSelectionInUnlockform}
                            sx={{ width: '100%' }}
                        />
                    </Box>

                    <Box>
                        <Typography style={{ fontSize: '16px', fontWeight: 'medium', paddingLeft: 0, paddingTop: 20 }}>
                            การใช้งาน
                        </Typography>
                        <TextField
                            //required
                            id="outlined-required"
                            //type="number"
                            name="status"
                            value={get_enable?.VALUE == true ? 'ปิดการใช้งาน' : 'เปิดการใช้งาน'}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleSelectionInUnlockform}
                            sx={{ width: '100%' }}
                        />
                    </Box>

                    <Box>
                        <Typography style={{ fontSize: '16px', fontWeight: 'medium', position: "relative", paddingLeft: 0, paddingTop: 20 }}>
                            {get_enable?.VALUE == true ? 'เหตุผลที่ต้องการปิดการใช้งาน' : 'เหตุผลที่ต้องการเปิดการใช้งาน'}
                        </Typography>
                        <TextField
                            //required
                            id="outlined-required"
                            //type="number"
                            name="note"
                            value={seleteNote}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleSelectionInUnlockform}
                            sx={{ width: '100%' }}
                        />
                    </Box>

                    <Box style={{ position: "relative", paddingLeft: 0, paddingTop: 30 }}>
                        <Button disabled={!seleteNote} fullWidth style={{ fontSize: '16px', fontWeight: "bold", backgroundColor: !seleteNote ? '#cfcfcf' : '#3cbea8' }} variant="contained" onClick={() => { handleClickConfirmChangeAuthorize(); }}>ยืนยัน</Button>
                    </Box>
                </Grid>
            </Grid>

            {/* pop up start tumbler  */}
            <Dialog
                open={openConfirmChangeAuthorize}
                onClose={handleCloseConfirmChangeAuthorize}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    ยืนยัน
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {get_enable?.VALUE == true ? 'ปิดการใช้งาน Giveaway?' : 'เปิดการใช้งาน Giveaway?'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button style={{ minWidth: '110px', backgroundColor: '#575755' }} variant="contained" onClick={() => { setOpenConfirmChangeAuthorize(false); }}>ยกเลิก</Button>
                    <Button style={{ minWidth: '110px', backgroundColor: '#009688' }} variant="contained" onClick={() => { setOpenConfirmChangeAuthorize(false); handleUnlock(); }}>ยืนยัน</Button>
                </DialogActions>
            </Dialog>

            {unlockSuccessful == 1 ?
                <div style={{ marginTop: 20 }}>
                    <Dialog
                        open={notifySubmittedUnlockSuccessful}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description" minWidth='200px' align='center'>
                                {get_enable?.VALUE == true ? 'ปิดการใช้งาน Giveaway สำเร็จ' : 'เปิดการใช้งาน Giveaway สำเร็จ'}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button style={{ backgroundColor: '#009688' }} variant="contained" onClick={() => { setNotifySubmittedUnlockSuccessful(false); window.location.reload(false); }}>OK</Button>
                        </DialogActions>
                    </Dialog>

                </div>
                : console.log()
            }

            {unlockSuccessful == 2 ?
                <div style={{ marginTop: 20 }}>
                    <Dialog
                        open={notifySubmittedUnlockFailed}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description" minWidth='200px' align='center'>
                                เกิดข้อผิดพลาด!!!, โปรดลองใหม่อีกครั้ง
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button style={{ backgroundColor: '#009688' }} aligh='Center' variant="contained" onClick={() => { setNotifySubmittedUnlockFailed(false); }}>OK</Button>
                        </DialogActions>
                    </Dialog>
                </div>
                : console.log()
            }
        </>
    );
}

export default Unlock;