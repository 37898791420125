import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Box, Grid, IconButton, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Helmet } from "react-helmet";
import axios from 'axios';
import db from "firebase-config";
import moment from 'moment-timezone';
import { apiUrl } from "../constants";
import { useNavigate } from 'react-router-dom';
import { useContext } from "react";
import { ColorModeContext, tokens } from "../theme";
import LensIcon from '@mui/icons-material/Lens';
import Time from "../assets/images/time.png";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'transparent',
  padding: theme.spacing(0.8),
  textAlign: 'center',
  border: '0.5px dashed #4A4A49',
  borderRadius: 10,
  height: '100%'

}));

const ItemInstock = styled(Paper)(({ theme }) => ({
  backgroundColor: '#0e2c27',
  padding: theme.spacing(0.4),
  textAlign: 'center',
  //border: '0.5px dashed #4A4A49',
  borderRadius: 5

}));

const ItemReady = styled(Paper)(({ theme }) => ({
  backgroundColor: '#302c2c',
  ...theme.typography.body2,
  padding: theme.spacing(0.2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


export default function MarinationTrackingP2() {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const today = new Date();
  const hourago = new Date(today.getTime());
  const timestr = moment(hourago).tz('Asia/Bangkok').format('DD MMM YY');
  const timestrs = moment(hourago).tz('Asia/Bangkok').format('HH:mm:ss');

  const [marinate, setMarinate] = useState([])
  const [marinate_page1, setMarinate_page1] = useState([])

  setTimeout(() => {
    navigate('/marination_tracking/p1');
  }, 30000);

  useEffect(() => {
    const getPostFromFirebase = [];
    const subscriber = db
      .collection("line_monitoring_preparation_zone_page1")
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {

          getPostFromFirebase.push({
            ...doc.data(),
            id: doc.id,
          });
        });

        setMarinate_page1(getPostFromFirebase);

        sessionStorage.setItem('batch_line7_page1', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE7CURRENT_BATCH"))?.CURRENT_BATCH);
        sessionStorage.setItem('batch_line8_page1', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE8CURRENT_BATCH"))?.CURRENT_BATCH);
        sessionStorage.setItem('batch_line9_page1', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE9CURRENT_BATCH"))?.CURRENT_BATCH);
        sessionStorage.setItem('batch_line10_page1', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE10CURRENT_BATCH"))?.CURRENT_BATCH);
        sessionStorage.setItem('batch_line11_page1', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE11CURRENT_BATCH"))?.CURRENT_BATCH);
        sessionStorage.setItem('batch_line12_page1', getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE12CURRENT_BATCH"))?.CURRENT_BATCH);

      });

    // return cleanup function
    return () => subscriber();
  }, [marinate_page1]);

  useEffect(() => {
    const getPostFromFirebase = [];
    const startTime = performance.now();
    const subscriber = db
      .collection("line_monitoring_preparation_zone_page2")
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {

          getPostFromFirebase.push({
            ...doc.data(),
            id: doc.id,
          });
        });
        const endTime = performance.now();
        // console.log(`Query time: ${endTime - startTime} milliseconds`);
        setMarinate(getPostFromFirebase);
        let batchline7 = getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE7NUMBER_OF_BATCH"));
        sessionStorage.setItem('batch_line7', batchline7?.NUMBER_OF_BATCH);
        let batchline8 = getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE8NUMBER_OF_BATCH"));
        sessionStorage.setItem('batch_line8', batchline8?.NUMBER_OF_BATCH);
        let batchline9 = getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE9NUMBER_OF_BATCH"));
        sessionStorage.setItem('batch_line9', batchline9?.NUMBER_OF_BATCH);
        let batchline10 = getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE10NUMBER_OF_BATCH"));
        sessionStorage.setItem('batch_line10', batchline10?.NUMBER_OF_BATCH);
        let batchline11 = getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE11NUMBER_OF_BATCH"));
        sessionStorage.setItem('batch_line11', batchline11?.NUMBER_OF_BATCH);
        let batchline12 = getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE12NUMBER_OF_BATCH"));
        sessionStorage.setItem('batch_line12', batchline12?.NUMBER_OF_BATCH);

        let instockline7 = getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE7KG_IN_STOCK"));
        sessionStorage.setItem('kg_instock_line7', instockline7?.KG_IN_STOCK);
        let instockline8 = getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE8KG_IN_STOCK"));
        sessionStorage.setItem('kg_instock_line8', instockline8?.KG_IN_STOCK);
        let instockline9 = getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE9KG_IN_STOCK"));
        sessionStorage.setItem('kg_instock_line9', instockline9?.KG_IN_STOCK);
        let instockline10 = getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE10KG_IN_STOCK"));
        sessionStorage.setItem('kg_instock_line10', instockline10?.KG_IN_STOCK);
        let instockline11 = getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE11KG_IN_STOCK"));
        sessionStorage.setItem('kg_instock_line11', instockline11?.KG_IN_STOCK);
        let instockline12 = getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE12KG_IN_STOCK"));
        sessionStorage.setItem('kg_instock_line12', instockline12?.KG_IN_STOCK);

        let readyline7 = getPostFromFirebase.filter(item => item.id.includes("411700000000000000LINE7"));
        (sessionStorage.setItem('slice_ready_line7', JSON.stringify(readyline7.slice(0, 4))));
        let readyline8 = getPostFromFirebase.filter(item => item.id.includes("411700000000000000LINE8"));
        sessionStorage.setItem('slice_ready_line8', JSON.stringify(readyline8.slice(0, 4)));
        let readyline9 = getPostFromFirebase.filter(item => item.id.includes("411700000000000000LINE9"));
        sessionStorage.setItem('slice_ready_line9', JSON.stringify(readyline9.slice(0, 4)));
        let readyline10 = getPostFromFirebase.filter(item => item.id.includes("411700000000000000LINE10"));
        sessionStorage.setItem('slice_ready_line10', JSON.stringify(readyline10.slice(0, 4)));
        let readyline11 = getPostFromFirebase.filter(item => item.id.includes("411700000000000000LINE11"));
        sessionStorage.setItem('slice_ready_line11', JSON.stringify(readyline11.slice(0, 4)));
        let readyline12 = getPostFromFirebase.filter(item => item.id.includes("411700000000000000LINE12"));
        sessionStorage.setItem('slice_ready_line12', JSON.stringify(readyline12.slice(0, 4)));

        let hourline7 = getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE7HOUR"));
        sessionStorage.setItem('hour_line7', hourline7?.HOUR);
        let hourline8 = getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE8HOUR"));
        sessionStorage.setItem('hour_line8', hourline8?.HOUR);
        let hourline9 = getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE9HOUR"));
        sessionStorage.setItem('hour_line9', hourline9?.HOUR);
        let hourline10 = getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE10HOUR"));
        sessionStorage.setItem('hour_line10', hourline10?.HOUR);
        let hourline11 = getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE11HOUR"));
        sessionStorage.setItem('hour_line11', hourline11?.HOUR);
        let hourline12 = getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE12HOUR"));
        sessionStorage.setItem('hour_line12', hourline12?.HOUR);

        let minteline7 = getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE7MIN"));
        sessionStorage.setItem('minute_line7', minteline7?.MINUTE);
        let minteline8 = getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE8MIN"));
        sessionStorage.setItem('minute_line8', minteline8?.MINUTE);
        let minteline9 = getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE9MIN"));
        sessionStorage.setItem('minute_line9', minteline9?.MINUTE);
        let minteline10 = getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE10MIN"));
        sessionStorage.setItem('minute_line10', minteline10?.MINUTE);
        let minteline11 = getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE11MIN"));
        sessionStorage.setItem('minute_line11', minteline11?.MINUTE);
        let minteline12 = getPostFromFirebase.find((x) => x.id.includes("4117.00000000000000.LINE12MIN"));
        sessionStorage.setItem('minute_line12', minteline12?.MINUTE);
      });

    // return cleanup function
    return () => subscriber();
  }, [marinate]);

  const batch_line7 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE7NUMBER_OF_BATCH"));

  const batch_line8 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE8NUMBER_OF_BATCH"));

  const batch_line9 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE9NUMBER_OF_BATCH"));

  const batch_line10 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE10NUMBER_OF_BATCH"));

  const batch_line11 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE11NUMBER_OF_BATCH"));

  const batch_line12 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE12NUMBER_OF_BATCH"));

  const kg_instock_line7 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE7KG_IN_STOCK"));

  const kg_instock_line8 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE8KG_IN_STOCK"));

  const kg_instock_line9 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE9KG_IN_STOCK"));

  const kg_instock_line10 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE10KG_IN_STOCK"));

  const kg_instock_line11 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE11KG_IN_STOCK"));

  const kg_instock_line12 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE12KG_IN_STOCK"));

  ///How to filter data in an array that match a string? (https://stackoverflow.com/questions/68630938/how-to-filter-data-in-an-array-that-match-a-string)
  const ready_line7 = marinate === undefined ? '' : marinate.filter(item => item.id.includes("411700000000000000LINE7"));

  const ready_line8 = marinate === undefined ? '' : marinate.filter(item => item.id.includes("411700000000000000LINE8"));

  const ready_line9 = marinate === undefined ? '' : marinate.filter(item => item.id.includes("411700000000000000LINE9"));

  const ready_line10 = marinate === undefined ? '' : marinate.filter(item => item.id.includes("411700000000000000LINE10"));

  const ready_line11 = marinate === undefined ? '' : marinate.filter(item => item.id.includes("411700000000000000LINE11"));

  const ready_line12 = marinate === undefined ? '' : marinate.filter(item => item.id.includes("411700000000000000LINE12"));


  ///How do I get the last 5 elements, excluding the first element from an array?(https://stackoverflow.com/questions/6473858/how-do-i-get-the-last-5-elements-excluding-the-first-element-from-an-array)
  // const slice_ready_line7 = ready_line7 === undefined ? '' : ready_line7.slice((ready_line7.length - 4), ready_line7.length)

  // const slice_ready_line8 = ready_line8 === undefined ? '' : ready_line8.slice((ready_line8.length - 4), ready_line8.length)

  // const slice_ready_line9 = ready_line9 === undefined ? '' : ready_line9.slice((ready_line9.length - 4), ready_line9.length)

  // const slice_ready_line10 = ready_line10 === undefined ? '' : ready_line10.slice((ready_line10.length - 4), ready_line10.length)

  // const slice_ready_line11 = ready_line11 === undefined ? '' : ready_line11.slice((ready_line11.length - 4), ready_line11.length)

  // const slice_ready_line12 = ready_line12 === undefined ? '' : ready_line12.slice((ready_line12.length - 4), ready_line12.length)



  ///How to get first N number of elements from an array(https://stackoverflow.com/questions/34883068/how-to-get-first-n-number-of-elements-from-an-array)
  const slice_ready_line7 = ready_line7 === undefined ? '' : ready_line7.slice(0, 4)

  const slice_ready_line8 = ready_line8 === undefined ? '' : ready_line8.slice(0, 4)

  const slice_ready_line9 = ready_line9 === undefined ? '' : ready_line9.slice(0, 4)

  const slice_ready_line10 = ready_line10 === undefined ? '' : ready_line10.slice(0, 4)

  const slice_ready_line11 = ready_line11 === undefined ? '' : ready_line11.slice(0, 4)

  const slice_ready_line12 = ready_line12 === undefined ? '' : ready_line12.slice(0, 4)




  const hour_line7 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE7HOUR"));

  const hour_line8 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE8HOUR"));

  const hour_line9 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE9HOUR"));

  const hour_line10 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE10HOUR"));

  const hour_line11 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE11HOUR"));

  const hour_line12 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE12HOUR"));


  const minute_line7 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE7MIN"));

  const minute_line8 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE8MIN"));

  const minute_line9 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE9MIN"));

  const minute_line10 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE10MIN"));

  const minute_line11 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE11MIN"));

  const minute_line12 = marinate === undefined ? '' : marinate.find((x) => x.id.includes("4117.00000000000000.LINE12MIN"));


  var recipes_line7 = sessionStorage.getItem('slice_ready_line7') === 'undefined' || sessionStorage.getItem('slice_ready_line7') === '' || sessionStorage.getItem('slice_ready_line7') === null ? '' : JSON.parse(sessionStorage.getItem('slice_ready_line7'));

  var recipes_line8 = sessionStorage.getItem('slice_ready_line8') === 'undefined' || sessionStorage.getItem('slice_ready_line8') === '' || sessionStorage.getItem('slice_ready_line8') === null ? '' : JSON.parse(sessionStorage.getItem('slice_ready_line8'));

  var recipes_line9 = sessionStorage.getItem('slice_ready_line9') === 'undefined' || sessionStorage.getItem('slice_ready_line9') === '' || sessionStorage.getItem('slice_ready_line9') === null ? '' : JSON.parse(sessionStorage.getItem('slice_ready_line9'));

  var recipes_line10 = sessionStorage.getItem('slice_ready_line10') === 'undefined' || sessionStorage.getItem('slice_ready_line10') === '' || sessionStorage.getItem('slice_ready_line10') === null ? '' : JSON.parse(sessionStorage.getItem('slice_ready_line10'));

  var recipes_line11 = sessionStorage.getItem('slice_ready_line11') === 'undefined' || sessionStorage.getItem('slice_ready_line11') === '' || sessionStorage.getItem('slice_ready_line11') === null ? '' : JSON.parse(sessionStorage.getItem('slice_ready_line11'));

  var recipes_line12 = sessionStorage.getItem('slice_ready_line12') === 'undefined' || sessionStorage.getItem('slice_ready_line12') === '' || sessionStorage.getItem('slice_ready_line12') === null ? '' : JSON.parse(sessionStorage.getItem('slice_ready_line12'));


  const batch_line7_page1 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE7CURRENT_BATCH"));

  const batch_line8_page1 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE8CURRENT_BATCH"));

  const batch_line9_page1 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE9CURRENT_BATCH"));

  const batch_line10_page1 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE10CURRENT_BATCH"));

  const batch_line11_page1 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE11CURRENT_BATCH"));

  const batch_line12_page1 = marinate_page1 === undefined ? '' : marinate_page1.find((x) => x.id.includes("4117.00000000000000.LINE12CURRENT_BATCH"));

  return (
    <>
      <Helmet>
        <title>Marination Tracking | MES Korat Fur2</title>
      </Helmet>
      <Grid
        container spacing={1}
        direction="row"
        alignItems="top"
        justifyContent="center"
        style={{ minHeight: '78vh', maxHeight: '200vh', flexGrow: 1, paddingLeft: 15, paddingRight: 10, paddingTop: 45 }}
      >
        {/* Top row */}


        <Grid container rowSpacing={0.5} columnSpacing={{ xs: 1, sm: 0.5, md: 0.5 }}>
          <Grid item xs={6}>
            <Item>
              <Grid item xs={12} style={{ height: slice_ready_line7.length === 0 ? '82px' : slice_ready_line7.length >= 1 ? '84px' : '66px' }}>
                {batch_line7 !== undefined || sessionStorage.getItem('batch_line7') !== 'undefined' ?
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                    <Grid item xs={4.3}>
                      <Grid item xs={12} textAlign="left">
                        <Typography style={{ color: '#2e9281', fontSize: 20, fontWeight: 'bold', lineHeight: "10px", position: "relative", top: "-0.2vw" }} >
                          &nbsp;Line 7
                        </Typography>
                      </Grid>
                      {batch_line7?.NUMBER_OF_BATCH === 0 || sessionStorage.getItem('batch_line7') === '0' ? '' :
                        <ItemInstock>
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                            <Typography style={{ color: colors.black[100], fontSize: 24, fontWeight: 'bold', lineHeight: "7vh" }} >
                              {batch_line7 == undefined ? Number(sessionStorage.getItem('batch_line7')).toLocaleString() : (batch_line7?.NUMBER_OF_BATCH).toLocaleString()}
                            </Typography>
                            <Typography style={{ color: colors.grey[100], fontSize: 16, lineHeight: "7vh", position: "relative", top: "0.3vw" }} >
                              &nbsp;Batch
                            </Typography>
                          </Box>

                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                            <Typography style={{ color: colors.black[100], fontSize: 28, fontWeight: 'bold', lineHeight: "7vh" }} >
                              {kg_instock_line7 == undefined ? Math.round(Number(sessionStorage.getItem('kg_instock_line7'))).toLocaleString() : Math.round(kg_instock_line7?.KG_IN_STOCK).toLocaleString()}
                            </Typography>
                            <Typography style={{ color: colors.grey[100], fontSize: 16, lineHeight: "7vh", position: "relative", top: "0.3vw" }} >
                              &nbsp;kg
                            </Typography>
                          </Box>
                        </ItemInstock>
                      }

                      {batch_line7?.NUMBER_OF_BATCH === 0 || sessionStorage.getItem('batch_line7') === '0' ? '' :
                        <Grid item xs={12} style={{ paddingTop: 2 }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                            <img src={Time} height="14px" />
                            <Typography style={{ color: '#74dcbc', fontSize: 22, lineHeight: "17px", fontWeight: 'bold' }} >
                              &nbsp;{hour_line7 == undefined ? sessionStorage.getItem('hour_line7') : hour_line7?.HOUR}&nbsp;
                            </Typography>
                            <Typography style={{ color: colors.grey[100], fontSize: 16, lineHeight: "17px", position: "relative", top: "0.1vw" }} >
                              hr&nbsp;
                            </Typography>
                            <Typography style={{ color: '#74dcbc', fontSize: 22, lineHeight: "17px", fontWeight: 'bold' }} >
                              {minute_line7 == undefined ? sessionStorage.getItem('minute_line7') : minute_line7?.MINUTE}&nbsp;
                            </Typography>
                            <Typography style={{ color: colors.grey[100], fontSize: 16, lineHeight: "17px", position: "relative", top: "0.1vw" }} >
                              min
                            </Typography>
                          </Box>
                        </Grid>}
                    </Grid>

                    <Grid item xs={0.2}>

                    </Grid>

                    <Grid item xs={7.5}>
                      <Grid container rowSpacing={0.4} columnSpacing={{ xs: 0, sm: 0, md: 0 }} style={{ height: '78%' }}>
                        {slice_ready_line7.length === 0
                          ? (recipes_line7.length === 0 ? [] : recipes_line7).map((item) => {
                            return <Grid item xs={6} style={{ paddingRight: 3 }}>
                              <ItemReady>
                                <Grid container rowSpacing={0} columnSpacing={{ xs: 0.3, sm: 0, md: 0 }}>
                                  <Grid item xs={6} textAlign="left">
                                    <Typography style={{ color: hourago / 1000 >= item.READY_TIMESTAMP.seconds ? '#2e9281' : colors.grey[100], fontSize: 20, lineHeight: "17px", position: "relative", top: "0vw" }} >
                                      &nbsp;{item.BATCH}
                                    </Typography>
                                  </Grid>
                                  {(sessionStorage.getItem('batch_line7_page1') === '' ? 0 : sessionStorage.getItem('batch_line7_page1')) === item.BATCH ?
                                    <Grid item xs={6} style={{ position: "relative", top: "-0.2vw" }} textAlign="right">
                                      <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 8 }}></LensIcon>
                                      <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 8 }}></LensIcon>
                                      <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 8 }}></LensIcon>
                                    </Grid>
                                    : ''}

                                </Grid>
                                <Grid item xs={12} >
                                  <Grid container rowSpacing={0} columnSpacing={{ xs: 0.3, sm: 0, md: 0 }}>
                                    <Grid item xs={3} textAlign="right">
                                    </Grid>
                                    <Grid item xs={9} textAlign="right">
                                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }} textAlign="right" >
                                        <Typography style={{ color: colors.black[100], fontSize: 22, lineHeight: (sessionStorage.getItem('batch_line8_page1') === '' ? 0 : sessionStorage.getItem('batch_line8_page1')) === item.BATCH ? "15px" : "17px" }} >
                                          {Math.round(item.KG).toLocaleString()}&nbsp;
                                        </Typography>

                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </ItemReady>
                            </Grid>
                          })
                          : slice_ready_line7.map((item) => {
                            return <Grid item xs={6} style={{ paddingRight: 3 }}>
                              <ItemReady>
                                <Grid container rowSpacing={0} columnSpacing={{ xs: 0.3, sm: 0, md: 0 }}>
                                  <Grid item xs={6} textAlign="left">
                                    <Typography style={{ color: hourago / 1000 >= item.READY_TIMESTAMP.seconds ? '#2e9281' : colors.grey[100], fontSize: 20, lineHeight: "17px", position: "relative", top: "0vw" }} >
                                      &nbsp;{item.BATCH}
                                    </Typography>
                                  </Grid>
                                  {(batch_line7_page1?.CURRENT_BATCH === '' ? 0 : batch_line7_page1?.CURRENT_BATCH) === item.BATCH ?
                                    <Grid item xs={6} style={{ position: "relative", top: "-0.2vw" }} textAlign="right">
                                      <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 8 }}></LensIcon>
                                      <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 8 }}></LensIcon>
                                      <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 8 }}></LensIcon>
                                    </Grid>
                                    : ''}

                                </Grid>
                                <Grid item xs={12} >
                                  <Grid container rowSpacing={0} columnSpacing={{ xs: 0.3, sm: 0, md: 0 }}>
                                    <Grid item xs={3} textAlign="right">
                                    </Grid>
                                    <Grid item xs={9} textAlign="right">
                                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }} textAlign="right" >
                                        <Typography style={{ color: colors.black[100], fontSize: 22, lineHeight: (batch_line8_page1?.CURRENT_BATCH === '' ? 0 : batch_line8_page1?.CURRENT_BATCH) === item.BATCH ? "15px" : "17px" }} >
                                          {Math.round(item.KG).toLocaleString()}&nbsp;
                                        </Typography>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </ItemReady>
                            </Grid>
                          })}

                      </Grid>
                    </Grid>
                  </Grid>
                  : ''}
              </Grid>
            </Item>
          </Grid>

          <Grid item xs={6}>
            <Item>
              <Grid item xs={12} style={{ height: slice_ready_line8.length === 0 ? '82px' : slice_ready_line8.length >= 1 ? '84px' : '66px' }}>
                {batch_line8 !== undefined || sessionStorage.getItem('batch_line8') !== 'undefined' ?
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                    <Grid item xs={4.3}>
                      <Grid item xs={12} textAlign="left">
                        <Typography style={{ color: '#2e9281', fontSize: 20, fontWeight: 'bold', lineHeight: "10px", position: "relative", top: "-0.2vw" }} >
                          &nbsp;Line 8
                        </Typography>
                      </Grid>
                      {batch_line8?.NUMBER_OF_BATCH === 0 || sessionStorage.getItem('batch_line8') === '0' ? '' :
                        <ItemInstock>
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                            <Typography style={{ color: colors.black[100], fontSize: 24, fontWeight: 'bold', lineHeight: "7vh" }} >
                              {batch_line8 == undefined ? Number(sessionStorage.getItem('batch_line8')).toLocaleString() : (batch_line8?.NUMBER_OF_BATCH).toLocaleString()}
                            </Typography>
                            <Typography style={{ color: colors.grey[100], fontSize: 16, lineHeight: "7vh", position: "relative", top: "0.3vw" }} >
                              &nbsp;Batch
                            </Typography>
                          </Box>

                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                            <Typography style={{ color: colors.black[100], fontSize: 28, fontWeight: 'bold', lineHeight: "7vh" }} >
                              {kg_instock_line8 == undefined ? Math.round(Number(sessionStorage.getItem('kg_instock_line8'))).toLocaleString() : Math.round(kg_instock_line8?.KG_IN_STOCK).toLocaleString()}
                            </Typography>
                            <Typography style={{ color: colors.grey[100], fontSize: 16, lineHeight: "7vh", position: "relative", top: "0.3vw" }} >
                              &nbsp;kg
                            </Typography>
                          </Box>
                        </ItemInstock>
                      }

                      {batch_line8?.NUMBER_OF_BATCH === 0 || sessionStorage.getItem('batch_line8') === '0' ? '' :
                        <Grid item xs={12} style={{ paddingTop: 2 }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                            <img src={Time} height="14px" />
                            <Typography style={{ color: '#74dcbc', fontSize: 22, lineHeight: "17px", fontWeight: 'bold' }} >
                              &nbsp;{hour_line8 == undefined ? sessionStorage.getItem('hour_line8') : hour_line8?.HOUR}&nbsp;
                            </Typography>
                            <Typography style={{ color: colors.grey[100], fontSize: 16, lineHeight: "17px", position: "relative", top: "0.1vw" }} >
                              hr&nbsp;
                            </Typography>
                            <Typography style={{ color: '#74dcbc', fontSize: 22, lineHeight: "17px", fontWeight: 'bold' }} >
                              {minute_line8 == undefined ? sessionStorage.getItem('minute_line8') : minute_line8?.MINUTE}&nbsp;
                            </Typography>
                            <Typography style={{ color: colors.grey[100], fontSize: 16, lineHeight: "17px", position: "relative", top: "0.1vw" }} >
                              min
                            </Typography>
                          </Box>
                        </Grid>}
                    </Grid>

                    <Grid item xs={0.2}>

                    </Grid>

                    <Grid item xs={7.5}>
                      <Grid container rowSpacing={0.4} columnSpacing={{ xs: 0, sm: 0, md: 0 }} style={{ height: '78%' }}>
                        {slice_ready_line8.length === 0
                          ? (recipes_line8.length === 0 ? [] : recipes_line8).map((item) => {
                            return <Grid item xs={6} style={{ paddingRight: 3 }}>
                              <ItemReady>
                                <Grid container rowSpacing={0} columnSpacing={{ xs: 0.3, sm: 0, md: 0 }}>
                                  <Grid item xs={6} textAlign="left">
                                    <Typography style={{ color: hourago / 1000 >= item.READY_TIMESTAMP.seconds ? '#2e9281' : colors.grey[100], fontSize: 20, lineHeight: "17px", position: "relative", top: "0vw" }} >
                                      &nbsp;{item.BATCH}
                                    </Typography>
                                  </Grid>
                                  {(sessionStorage.getItem('batch_line8_page1') === '' ? 0 : sessionStorage.getItem('batch_line8_page1')) === item.BATCH ?
                                    <Grid item xs={6} style={{ position: "relative", top: "-0.2vw" }} textAlign="right">
                                      <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 8 }}></LensIcon>
                                      <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 8 }}></LensIcon>
                                      <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 8 }}></LensIcon>
                                    </Grid>
                                    : ''}

                                </Grid>
                                <Grid item xs={12} >
                                  <Grid container rowSpacing={0} columnSpacing={{ xs: 0.3, sm: 0, md: 0 }}>
                                    <Grid item xs={3} textAlign="right">
                                    </Grid>
                                    <Grid item xs={9} textAlign="right">
                                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }} textAlign="right" >
                                        <Typography style={{ color: colors.black[100], fontSize: 22, lineHeight: (sessionStorage.getItem('batch_line8_page1') === '' ? 0 : sessionStorage.getItem('batch_line8_page1')) === item.BATCH ? "15px" : "17px" }} >
                                          {Math.round(item.KG).toLocaleString()}&nbsp;
                                        </Typography>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </ItemReady>
                            </Grid>
                          })
                          : slice_ready_line8.map((item) => {
                            return <Grid item xs={6} style={{ paddingRight: 3 }}>
                              <ItemReady>
                                <Grid container rowSpacing={0} columnSpacing={{ xs: 0.3, sm: 0, md: 0 }}>
                                  <Grid item xs={6} textAlign="left">
                                    <Typography style={{ color: hourago / 1000 >= item.READY_TIMESTAMP.seconds ? '#2e9281' : colors.grey[100], fontSize: 20, lineHeight: "17px", position: "relative", top: "0vw" }} >
                                      &nbsp;{item.BATCH}
                                    </Typography>
                                  </Grid>
                                  {(batch_line8_page1?.CURRENT_BATCH === '' ? 0 : batch_line8_page1?.CURRENT_BATCH) === item.BATCH ?
                                    <Grid item xs={6} style={{ position: "relative", top: "-0.2vw" }} textAlign="right">
                                      <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 8 }}></LensIcon>
                                      <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 8 }}></LensIcon>
                                      <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 8 }}></LensIcon>
                                    </Grid>
                                    : ''}

                                </Grid>
                                <Grid item xs={12} >
                                  <Grid container rowSpacing={0} columnSpacing={{ xs: 0.3, sm: 0, md: 0 }}>
                                    <Grid item xs={3} textAlign="right">
                                    </Grid>
                                    <Grid item xs={9} textAlign="right">
                                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }} textAlign="right" >
                                        <Typography style={{ color: colors.black[100], fontSize: 22, lineHeight: (batch_line8_page1?.CURRENT_BATCH === '' ? 0 : batch_line8_page1?.CURRENT_BATCH) === item.BATCH ? "15px" : "17px" }} >
                                          {Math.round(item.KG).toLocaleString()}&nbsp;
                                        </Typography>

                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </ItemReady>
                            </Grid>
                          })}

                      </Grid>
                    </Grid>
                  </Grid>
                  : ''}
              </Grid>
            </Item>
          </Grid>

          <Grid item xs={6}>
            <Item>
              <Grid item xs={12} style={{ height: slice_ready_line9.length === 0 ? '82px' : slice_ready_line9.length >= 1 ? '84px' : '66px' }}>
                {batch_line9 !== undefined || sessionStorage.getItem('batch_line9') !== 'undefined' ?
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                    <Grid item xs={4.3}>
                      <Grid item xs={12} textAlign="left">
                        <Typography style={{ color: '#2e9281', fontSize: 20, fontWeight: 'bold', lineHeight: "10px", position: "relative", top: "-0.2vw" }} >
                          &nbsp;Line 9
                        </Typography>
                      </Grid>
                      {batch_line9?.NUMBER_OF_BATCH === 0 || sessionStorage.getItem('batch_line9') === '0' ? '' :
                        <ItemInstock>
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                            <Typography style={{ color: colors.black[100], fontSize: 24, fontWeight: 'bold', lineHeight: "7vh" }} >
                              {batch_line9 == undefined ? Number(sessionStorage.getItem('batch_line9')).toLocaleString() : (batch_line9?.NUMBER_OF_BATCH).toLocaleString()}
                            </Typography>
                            <Typography style={{ color: colors.grey[100], fontSize: 16, lineHeight: "7vh", position: "relative", top: "0.3vw" }} >
                              &nbsp;Batch
                            </Typography>
                          </Box>

                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                            <Typography style={{ ccolor: colors.black[100], fontSize: 28, fontWeight: 'bold', lineHeight: "7vh" }} >
                              {kg_instock_line9 == undefined ? Math.round(Number(sessionStorage.getItem('kg_instock_line9'))).toLocaleString() : Math.round(kg_instock_line9?.KG_IN_STOCK).toLocaleString()}
                            </Typography>
                            <Typography style={{ color: colors.grey[100], fontSize: 16, lineHeight: "7vh", position: "relative", top: "0.3vw" }} >
                              &nbsp;kg
                            </Typography>
                          </Box>
                        </ItemInstock>
                      }

                      {batch_line9?.NUMBER_OF_BATCH === 0 || sessionStorage.getItem('batch_line9') === '0' ? '' :
                        <Grid item xs={12} style={{ paddingTop: 2 }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                            <img src={Time} height="14px" />
                            <Typography style={{ color: '#74dcbc', fontSize: 22, lineHeight: "17px", fontWeight: 'bold' }} >
                              &nbsp;{hour_line9 == undefined ? sessionStorage.getItem('hour_line9') : hour_line9?.HOUR}&nbsp;
                            </Typography>
                            <Typography style={{ color: colors.grey[100], fontSize: 16, lineHeight: "17px", position: "relative", top: "0.1vw" }} >
                              hr&nbsp;
                            </Typography>
                            <Typography style={{ color: '#74dcbc', fontSize: 22, lineHeight: "17px", fontWeight: 'bold' }} >
                              {minute_line9 == undefined ? sessionStorage.getItem('minute_line9') : minute_line9?.MINUTE}&nbsp;
                            </Typography>
                            <Typography style={{ color: colors.grey[100], fontSize: 16, lineHeight: "17px", position: "relative", top: "0.1vw" }} >
                              min
                            </Typography>
                          </Box>
                        </Grid>}
                    </Grid>

                    <Grid item xs={0.2}>

                    </Grid>

                    <Grid item xs={7.5}>
                      <Grid container rowSpacing={0.4} columnSpacing={{ xs: 0, sm: 0, md: 0 }} style={{ height: '78%' }}>
                        {slice_ready_line9.length === 0
                          ? (recipes_line9.length === 0 ? [] : recipes_line9).map((item) => {
                            return <Grid item xs={6} style={{ paddingRight: 3 }}>
                              <ItemReady>
                                <Grid container rowSpacing={0} columnSpacing={{ xs: 0.3, sm: 0, md: 0 }}>
                                  <Grid item xs={6} textAlign="left">
                                    <Typography style={{ color: hourago / 1000 >= item.READY_TIMESTAMP.seconds ? '#2e9281' : colors.grey[100], fontSize: 20, lineHeight: "17px", position: "relative", top: "0vw" }} >
                                      &nbsp;{item.BATCH}
                                    </Typography>
                                  </Grid>
                                  {(sessionStorage.getItem('batch_line9_page1') === '' ? 0 : sessionStorage.getItem('batch_line9_page1')) === item.BATCH ?
                                    <Grid item xs={6} style={{ position: "relative", top: "-0.2vw" }} textAlign="right">
                                      <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 8 }}></LensIcon>
                                      <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 8 }}></LensIcon>
                                      <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 8 }}></LensIcon>
                                    </Grid>
                                    : ''}

                                </Grid>
                                <Grid item xs={12} >
                                  <Grid container rowSpacing={0} columnSpacing={{ xs: 0.3, sm: 0, md: 0 }}>
                                    <Grid item xs={3} textAlign="right">
                                    </Grid>
                                    <Grid item xs={9} textAlign="right">
                                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }} textAlign="right" >
                                        <Typography style={{ color: colors.black[100], fontSize: 22, lineHeight: (sessionStorage.getItem('batch_line9_page1') === '' ? 0 : sessionStorage.getItem('batch_line9_page1')) === item.BATCH ? "15px" : "17px" }} >
                                          {Math.round(item.KG).toLocaleString()}&nbsp;
                                        </Typography>
                                      </Box>
                                    </Grid>

                                  </Grid>
                                </Grid>
                              </ItemReady>
                            </Grid>
                          })
                          : slice_ready_line9.map((item) => {
                            return <Grid item xs={6} style={{ paddingRight: 3 }}>
                              <ItemReady>
                                <Grid container rowSpacing={0} columnSpacing={{ xs: 0.3, sm: 0, md: 0 }}>
                                  <Grid item xs={6} textAlign="left">
                                    <Typography style={{ color: hourago / 1000 >= item.READY_TIMESTAMP.seconds ? '#2e9281' : colors.grey[100], fontSize: 20, lineHeight: "17px", position: "relative", top: "0vw" }} >
                                      &nbsp;{item.BATCH}
                                    </Typography>
                                  </Grid>
                                  {(batch_line9_page1?.CURRENT_BATCH === '' ? 0 : batch_line9_page1?.CURRENT_BATCH) === item.BATCH ?
                                    <Grid item xs={6} style={{ position: "relative", top: "-0.2vw" }} textAlign="right">
                                      <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 8 }}></LensIcon>
                                      <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 8 }}></LensIcon>
                                      <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 8 }}></LensIcon>
                                    </Grid>
                                    : ''}

                                </Grid>
                                <Grid item xs={12} >
                                  <Grid container rowSpacing={0} columnSpacing={{ xs: 0.3, sm: 0, md: 0 }}>
                                    <Grid item xs={3} textAlign="right">
                                    </Grid>
                                    <Grid item xs={9} textAlign="right">
                                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }} textAlign="right" >
                                        <Typography style={{ color: colors.black[100], fontSize: 22, lineHeight: (batch_line9_page1?.CURRENT_BATCH === '' ? 0 : batch_line9_page1?.CURRENT_BATCH) === item.BATCH ? "15px" : "17px" }} >
                                          {Math.round(item.KG).toLocaleString()}&nbsp;
                                        </Typography>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </ItemReady>
                            </Grid>
                          })}

                      </Grid>
                    </Grid>
                  </Grid>
                  : ''}
              </Grid>
            </Item>
          </Grid>

          <Grid item xs={6}>
            <Item>
              <Grid item xs={12} style={{ height: slice_ready_line10.length === 0 ? '82px' : slice_ready_line10.length >= 1 ? '84px' : '66px' }}>
                {batch_line10 !== undefined || sessionStorage.getItem('batch_line10') !== 'undefined' ?
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                    <Grid item xs={4.3}>
                      <Grid item xs={12} textAlign="left">
                        <Typography style={{ color: '#2e9281', fontSize: 20, fontWeight: 'bold', lineHeight: "10px", position: "relative", top: "-0.2vw" }} >
                          &nbsp;Line 10
                        </Typography>
                      </Grid>
                      {batch_line10?.NUMBER_OF_BATCH === 0 || sessionStorage.getItem('batch_line10') === '0' ? '' :
                        <ItemInstock>
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                            <Typography style={{ color: colors.black[100], fontSize: 24, fontWeight: 'bold', lineHeight: "7vh" }} >
                              {batch_line10 == undefined ? Number(sessionStorage.getItem('batch_line10')).toLocaleString() : (batch_line10?.NUMBER_OF_BATCH).toLocaleString()}
                            </Typography>
                            <Typography style={{ color: colors.grey[100], fontSize: 16, lineHeight: "7vh", position: "relative", top: "0.3vw" }} >
                              &nbsp;Batch
                            </Typography>
                          </Box>

                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                            <Typography style={{ color: colors.black[100], fontSize: 28, fontWeight: 'bold', lineHeight: "7vh" }} >
                              {kg_instock_line10 == undefined ? Math.round(Number(sessionStorage.getItem('kg_instock_line10'))).toLocaleString() : Math.round(kg_instock_line10?.KG_IN_STOCK).toLocaleString()}
                            </Typography>
                            <Typography style={{ color: colors.grey[100], fontSize: 16, lineHeight: "7vh", position: "relative", top: "0.3vw" }} >
                              &nbsp;kg
                            </Typography>
                          </Box>
                        </ItemInstock>
                      }

                      {batch_line10?.NUMBER_OF_BATCH === 0 || sessionStorage.getItem('batch_line10') === '0' ? '' :
                        <Grid item xs={12} style={{ paddingTop: 2 }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                            <img src={Time} height="14px" />
                            <Typography style={{ color: '#74dcbc', fontSize: 22, lineHeight: "17px", fontWeight: 'bold' }} >
                              &nbsp;{hour_line10 == undefined ? sessionStorage.getItem('hour_line10') : hour_line10?.HOUR}&nbsp;
                            </Typography>
                            <Typography style={{ color: colors.grey[100], fontSize: 16, lineHeight: "17px", position: "relative", top: "0.1vw" }} >
                              hr&nbsp;
                            </Typography>
                            <Typography style={{ color: '#74dcbc', fontSize: 22, lineHeight: "17px", fontWeight: 'bold' }} >
                              {minute_line10 == undefined ? sessionStorage.getItem('minute_line10') : minute_line10?.MINUTE}&nbsp;
                            </Typography>
                            <Typography style={{ color: colors.grey[100], fontSize: 16, lineHeight: "17px", position: "relative", top: "0.1vw" }} >
                              min
                            </Typography>
                          </Box>
                        </Grid>}
                    </Grid>

                    <Grid item xs={0.2}>

                    </Grid>

                    <Grid item xs={7.5}>
                      <Grid container rowSpacing={0.4} columnSpacing={{ xs: 0, sm: 0, md: 0 }} style={{ height: '78%' }}>
                        {slice_ready_line10.length === 0
                          ? (recipes_line10.length === 0 ? [] : recipes_line10).map((item) => {
                            return <Grid item xs={6} style={{ paddingRight: 3 }}>
                              <ItemReady>
                                <Grid container rowSpacing={0} columnSpacing={{ xs: 0.3, sm: 0, md: 0 }}>
                                  <Grid item xs={6} textAlign="left">
                                    <Typography style={{ color: hourago / 1000 >= item.READY_TIMESTAMP.seconds ? '#2e9281' : colors.grey[100], fontSize: 20, lineHeight: "17px", position: "relative", top: "0vw" }} >
                                      &nbsp;{item.BATCH}
                                    </Typography>
                                  </Grid>
                                  {(sessionStorage.getItem('batch_line10_page1') === '' ? 0 : sessionStorage.getItem('batch_line10_page1')) === item.BATCH ?
                                    <Grid item xs={6} style={{ position: "relative", top: "-0.2vw" }} textAlign="right">
                                      <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 8 }}></LensIcon>
                                      <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 8 }}></LensIcon>
                                      <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 8 }}></LensIcon>
                                    </Grid>
                                    : ''}

                                </Grid>
                                <Grid item xs={12} >
                                  <Grid container rowSpacing={0} columnSpacing={{ xs: 0.3, sm: 0, md: 0 }}>
                                    <Grid item xs={3} textAlign="right">
                                    </Grid>
                                    <Grid item xs={9} textAlign="right">
                                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }} textAlign="right" >
                                        <Typography style={{ color: colors.black[100], fontSize: 22, lineHeight: (sessionStorage.getItem('batch_line10_page1') === '' ? 0 : sessionStorage.getItem('batch_line10_page1')) === item.BATCH ? "15px" : "17px" }} >
                                          {Math.round(item.KG).toLocaleString()}&nbsp;
                                        </Typography>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </ItemReady>
                            </Grid>
                          })
                          : slice_ready_line10.map((item) => {
                            return <Grid item xs={6} style={{ paddingRight: 3 }}>
                              <ItemReady>
                                <Grid container rowSpacing={0} columnSpacing={{ xs: 0.3, sm: 0, md: 0 }}>
                                  <Grid item xs={6} textAlign="left">
                                    <Typography style={{ color: hourago / 1000 >= item.READY_TIMESTAMP.seconds ? '#2e9281' : colors.grey[100], fontSize: 20, lineHeight: "17px", position: "relative", top: "0vw" }} >
                                      &nbsp;{item.BATCH}
                                    </Typography>
                                  </Grid>
                                  {(batch_line10_page1?.CURRENT_BATCH === '' ? 0 : batch_line10_page1?.CURRENT_BATCH) === item.BATCH ?
                                    <Grid item xs={6} style={{ position: "relative", top: "-0.2vw" }} textAlign="right">
                                      <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 8 }}></LensIcon>
                                      <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 8 }}></LensIcon>
                                      <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 8 }}></LensIcon>
                                    </Grid>
                                    : ''}

                                </Grid>
                                <Grid item xs={12} >
                                  <Grid container rowSpacing={0} columnSpacing={{ xs: 0.3, sm: 0, md: 0 }}>
                                    <Grid item xs={3} textAlign="right">
                                    </Grid>
                                    <Grid item xs={9} textAlign="right">
                                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }} textAlign="right" >
                                        <Typography style={{ color: colors.black[100], fontSize: 22, lineHeight: (batch_line10_page1?.CURRENT_BATCH === '' ? 0 : batch_line10_page1?.CURRENT_BATCH) === item.BATCH ? "15px" : "17px" }} >
                                          {Math.round(item.KG).toLocaleString()}&nbsp;
                                        </Typography>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </ItemReady>
                            </Grid>
                          })}

                      </Grid>
                    </Grid>
                  </Grid>
                  : ''}
              </Grid>
            </Item>
          </Grid>



          <Grid item xs={6}>
            <Item>
              <Grid item xs={12} style={{ height: slice_ready_line11.length === 0 ? '82px' : slice_ready_line11.length >= 1 ? '84px' : '66px' }}>
                {batch_line11 !== undefined || sessionStorage.getItem('batch_line11') !== 'undefined' ?
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                    <Grid item xs={4.3}>
                      <Grid item xs={12} textAlign="left">
                        <Typography style={{ color: '#2e9281', fontSize: 20, fontWeight: 'bold', lineHeight: "10px", position: "relative", top: "-0.2vw" }} >
                          &nbsp;Line 11
                        </Typography>
                      </Grid>
                      {batch_line11?.NUMBER_OF_BATCH === 0 || sessionStorage.getItem('batch_line11') === '0' ? '' :
                        <ItemInstock>
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                            <Typography style={{ color: colors.black[100], fontSize: 24, fontWeight: 'bold', lineHeight: "7vh" }} >
                              {batch_line11 == undefined ? Number(sessionStorage.getItem('batch_line11')).toLocaleString() : (batch_line11?.NUMBER_OF_BATCH).toLocaleString()}
                            </Typography>
                            <Typography style={{ color: colors.grey[100], fontSize: 16, lineHeight: "7vh", position: "relative", top: "0.3vw" }} >
                              &nbsp;Batch
                            </Typography>
                          </Box>

                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                            <Typography style={{ color: colors.black[100], fontSize: 28, fontWeight: 'bold', lineHeight: "7vh" }} >
                              {kg_instock_line11 == undefined ? Math.round(Number(sessionStorage.getItem('kg_instock_line11'))).toLocaleString() : Math.round(kg_instock_line11?.KG_IN_STOCK).toLocaleString()}
                            </Typography>
                            <Typography style={{ color: colors.grey[100], fontSize: 16, lineHeight: "7vh", position: "relative", top: "0.3vw" }} >
                              &nbsp;kg
                            </Typography>
                          </Box>
                        </ItemInstock>
                      }

                      {batch_line11?.NUMBER_OF_BATCH === 0 || sessionStorage.getItem('batch_line11') === '0' ? '' :
                        <Grid item xs={12} style={{ paddingTop: 2 }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                            <img src={Time} height="14px" />
                            <Typography style={{ color: '#74dcbc', fontSize: 22, lineHeight: "17px", fontWeight: 'bold' }} >
                              &nbsp;{hour_line11 == undefined ? sessionStorage.getItem('hour_line11') : hour_line11?.HOUR}&nbsp;
                            </Typography>
                            <Typography style={{ color: colors.grey[100], fontSize: 16, lineHeight: "17px", position: "relative", top: "0.1vw" }} >
                              hr&nbsp;
                            </Typography>
                            <Typography style={{ color: '#74dcbc', fontSize: 22, lineHeight: "17px", fontWeight: 'bold' }} >
                              {minute_line11 == undefined ? sessionStorage.getItem('minute_line11') : minute_line11?.MINUTE}&nbsp;
                            </Typography>
                            <Typography style={{ color: colors.grey[100], fontSize: 16, lineHeight: "17px", position: "relative", top: "0.1vw" }} >
                              min
                            </Typography>
                          </Box>
                        </Grid>}
                    </Grid>

                    <Grid item xs={0.2}>

                    </Grid>

                    <Grid item xs={7.5}>
                      <Grid container rowSpacing={0.4} columnSpacing={{ xs: 0, sm: 0, md: 0 }} style={{ height: '78%' }}>
                        {slice_ready_line11.length === 0
                          ? (recipes_line11.length === 0 ? [] : recipes_line11).map((item) => {
                            return <Grid item xs={6} style={{ paddingRight: 3 }}>
                              <ItemReady>
                                <Grid container rowSpacing={0} columnSpacing={{ xs: 0.3, sm: 0, md: 0 }}>
                                  <Grid item xs={6} textAlign="left">
                                    <Typography style={{ color: hourago / 1000 >= item.READY_TIMESTAMP.seconds ? '#2e9281' : colors.grey[100], fontSize: 20, lineHeight: "17px", position: "relative", top: "0vw" }} >
                                      &nbsp;{item.BATCH}
                                    </Typography>
                                  </Grid>
                                  {(sessionStorage.getItem('batch_line11_page1') === '' ? 0 : sessionStorage.getItem('batch_line11_page1')) === item.BATCH ?
                                    <Grid item xs={6} style={{ position: "relative", top: "-0.2vw" }} textAlign="right">
                                      <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 8 }}></LensIcon>
                                      <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 8 }}></LensIcon>
                                      <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 8 }}></LensIcon>
                                    </Grid>
                                    : ''}

                                </Grid>
                                <Grid item xs={12} >
                                  <Grid container rowSpacing={0} columnSpacing={{ xs: 0.3, sm: 0, md: 0 }}>
                                    <Grid item xs={3} textAlign="right">
                                    </Grid>
                                    <Grid item xs={9} textAlign="right">
                                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }} textAlign="right" >
                                        <Typography style={{ color: colors.black[100], fontSize: 22, lineHeight: (sessionStorage.getItem('batch_line11_page1') === '' ? 0 : sessionStorage.getItem('batch_line11_page1')) === item.BATCH ? "15px" : "17px" }} >
                                          {Math.round(item.KG).toLocaleString()}&nbsp;
                                        </Typography>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </ItemReady>
                            </Grid>
                          })
                          : slice_ready_line11.map((item) => {
                            return <Grid item xs={6} style={{ paddingRight: 3 }}>
                              <ItemReady>
                                <Grid container rowSpacing={0} columnSpacing={{ xs: 0.3, sm: 0, md: 0 }}>
                                  <Grid item xs={6} textAlign="left">
                                    <Typography style={{ color: hourago / 1000 >= item.READY_TIMESTAMP.seconds ? '#2e9281' : colors.grey[100], fontSize: 20, lineHeight: "17px", position: "relative", top: "0vw" }} >
                                      &nbsp;{item.BATCH}
                                    </Typography>
                                  </Grid>
                                  {(batch_line11_page1?.CURRENT_BATCH === '' ? 0 : batch_line11_page1?.CURRENT_BATCH) === item.BATCH ?
                                    <Grid item xs={6} style={{ position: "relative", top: "-0.2vw" }} textAlign="right">
                                      <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 8 }}></LensIcon>
                                      <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 8 }}></LensIcon>
                                      <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 8 }}></LensIcon>
                                    </Grid>
                                    : ''}

                                </Grid>
                                <Grid item xs={12} >
                                  <Grid container rowSpacing={0} columnSpacing={{ xs: 0.3, sm: 0, md: 0 }}>
                                    <Grid item xs={3} textAlign="right">
                                    </Grid>
                                    <Grid item xs={9} textAlign="right">
                                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }} textAlign="right" >
                                        <Typography style={{ color: colors.black[100], fontSize: 22, lineHeight: (batch_line11_page1?.CURRENT_BATCH === '' ? 0 : batch_line11_page1?.CURRENT_BATCH) === item.BATCH ? "15px" : "17px" }} >
                                          {Math.round(item.KG).toLocaleString()}&nbsp;
                                        </Typography>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </ItemReady>
                            </Grid>
                          })}

                      </Grid>
                    </Grid>
                  </Grid>
                  : ''}
              </Grid>
            </Item>
          </Grid>



          <Grid item xs={6}>
            <Item>
              <Grid item xs={12} style={{ height: slice_ready_line12.length === 0 ? '82px' : slice_ready_line12.length >= 1 ? '84px' : '66px' }}>
                {batch_line12 !== undefined || sessionStorage.getItem('batch_line12') !== 'undefined' ?
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                    <Grid item xs={4.3}>
                      <Grid item xs={12} textAlign="left">
                        <Typography style={{ color: '#2e9281', fontSize: 20, fontWeight: 'bold', lineHeight: "10px", position: "relative", top: "-0.2vw" }} >
                          &nbsp;Line 12
                        </Typography>
                      </Grid>
                      {batch_line12?.NUMBER_OF_BATCH === 0 || sessionStorage.getItem('batch_line12') === '0' ? '' :
                        <ItemInstock>
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                            <Typography style={{ color: colors.black[100], fontSize: 24, fontWeight: 'bold', lineHeight: "7vh" }} >
                              {batch_line12 == undefined ? Number(sessionStorage.getItem('batch_line12')).toLocaleString() : (batch_line12?.NUMBER_OF_BATCH).toLocaleString()}
                            </Typography>
                            <Typography style={{ color: colors.grey[100], fontSize: 16, lineHeight: "7vh", position: "relative", top: "0.3vw" }} >
                              &nbsp;Batch
                            </Typography>
                          </Box>

                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                            <Typography style={{ color: colors.black[100], fontSize: 28, fontWeight: 'bold', lineHeight: "7vh" }} >
                              {kg_instock_line12 == undefined ? Math.round(Number(sessionStorage.getItem('kg_instock_line12'))).toLocaleString() : Math.round(kg_instock_line12?.KG_IN_STOCK).toLocaleString()}
                            </Typography>
                            <Typography style={{ color: colors.grey[100], fontSize: 16, lineHeight: "7vh", position: "relative", top: "0.3vw" }} >
                              &nbsp;kg
                            </Typography>
                          </Box>
                        </ItemInstock>
                      }

                      {batch_line12?.NUMBER_OF_BATCH === 0 || sessionStorage.getItem('batch_line12') === '0' ? '' :
                        <Grid item xs={12} style={{ paddingTop: 2 }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                            <img src={Time} height="14px" />
                            <Typography style={{ color: '#74dcbc', fontSize: 22, lineHeight: "17px", fontWeight: 'bold' }} >
                              &nbsp;{hour_line12 == undefined ? sessionStorage.getItem('hour_line12') : hour_line12?.HOUR}&nbsp;
                            </Typography>
                            <Typography style={{ color: colors.grey[100], fontSize: 16, lineHeight: "17px", position: "relative", top: "0.1vw" }} >
                              hr&nbsp;
                            </Typography>
                            <Typography style={{ color: '#74dcbc', fontSize: 22, lineHeight: "17px", fontWeight: 'bold' }} >
                              {minute_line12 == undefined ? sessionStorage.getItem('minute_line12') : minute_line12?.MINUTE}&nbsp;
                            </Typography>
                            <Typography style={{ color: colors.grey[100], fontSize: 16, lineHeight: "17px", position: "relative", top: "0.1vw" }} >
                              min
                            </Typography>
                          </Box>
                        </Grid>}
                    </Grid>

                    <Grid item xs={0.2}>

                    </Grid>

                    <Grid item xs={7.5}>
                      <Grid container rowSpacing={0.4} columnSpacing={{ xs: 0, sm: 0, md: 0 }} style={{ height: '78%' }}>
                        {slice_ready_line12.length === 0
                          ? (recipes_line12.length === 0 ? [] : recipes_line12).map((item) => {
                            return <Grid item xs={6} style={{ paddingRight: 3 }}>
                              <ItemReady>
                                <Grid container rowSpacing={0} columnSpacing={{ xs: 0.3, sm: 0, md: 0 }}>
                                  <Grid item xs={6} textAlign="left">
                                    <Typography style={{ color: hourago / 1000 >= item.READY_TIMESTAMP.seconds ? '#2e9281' : colors.grey[100], fontSize: 20, lineHeight: "17px", position: "relative", top: "0vw" }} >
                                      &nbsp;{item.BATCH}
                                    </Typography>
                                  </Grid>
                                  {(sessionStorage.getItem('batch_line12_page1') === '' ? 0 : sessionStorage.getItem('batch_line12_page1')) === item.BATCH ?
                                    <Grid item xs={6} style={{ position: "relative", top: "-0.2vw" }} textAlign="right">
                                      <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 8 }}></LensIcon>
                                      <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 8 }}></LensIcon>
                                      <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 8 }}></LensIcon>
                                    </Grid>
                                    : ''}

                                </Grid>
                                <Grid item xs={12} >
                                  <Grid container rowSpacing={0} columnSpacing={{ xs: 0.3, sm: 0, md: 0 }}>
                                    <Grid item xs={3} textAlign="right">
                                    </Grid>
                                    <Grid item xs={9} textAlign="right">
                                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }} textAlign="right" >
                                        <Typography style={{ color: colors.black[100], fontSize: 22, lineHeight: (sessionStorage.getItem('batch_line12_page1') === '' ? 0 : sessionStorage.getItem('batch_line12_page1')) === item.BATCH ? "15px" : "17px" }} >
                                          {Math.round(item.KG).toLocaleString()}&nbsp;
                                        </Typography>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </ItemReady>
                            </Grid>
                          })
                          : slice_ready_line12.map((item) => {
                            return <Grid item xs={6} style={{ paddingRight: 3 }}>
                              <ItemReady>
                                <Grid container rowSpacing={0} columnSpacing={{ xs: 0.3, sm: 0, md: 0 }}>
                                  <Grid item xs={6} textAlign="left">
                                    <Typography style={{ color: hourago / 1000 >= item.READY_TIMESTAMP.seconds ? '#2e9281' : colors.grey[100], fontSize: 20, lineHeight: "17px", position: "relative", top: "0vw" }} >
                                      &nbsp;{item.BATCH}
                                    </Typography>
                                  </Grid>
                                  {(batch_line12_page1?.CURRENT_BATCH === '' ? 0 : batch_line12_page1?.CURRENT_BATCH) === item.BATCH ?
                                    <Grid item xs={6} style={{ position: "relative", top: "-0.2vw" }} textAlign="right">
                                      <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 8 }}></LensIcon>
                                      <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 8 }}></LensIcon>
                                      <LensIcon sx={{ color: "#8f9c32" }} style={{ fontSize: 8 }}></LensIcon>
                                    </Grid>
                                    : ''}

                                </Grid>
                                <Grid item xs={12} >
                                  <Grid container rowSpacing={0} columnSpacing={{ xs: 0.3, sm: 0, md: 0 }}>
                                    <Grid item xs={3} textAlign="right">
                                    </Grid>
                                    <Grid item xs={9} textAlign="right">
                                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }} textAlign="right" >
                                        <Typography style={{ color: colors.black[100], fontSize: 22, lineHeight: (batch_line12_page1?.CURRENT_BATCH === '' ? 0 : batch_line12_page1?.CURRENT_BATCH) === item.BATCH ? "15px" : "17px" }} >
                                          {Math.round(item.KG).toLocaleString()}&nbsp;
                                        </Typography>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </ItemReady>
                            </Grid>
                          })}

                      </Grid>
                    </Grid>
                  </Grid>
                  : ''}
              </Grid>
            </Item>
          </Grid>
        </Grid>

      </Grid>

    </>
  );
}