import React, { useEffect, useState } from "react";
import Backdrop from '@mui/material/Backdrop';
import { Box, Grid, Card, Container } from "@mui/material";
import Typography from "@mui/material/Typography";
import Warning from 'assets/images/warning.png';
import axios from 'axios';
import { apiUrl } from "constants";
import Disconnected from 'assets/images/disconnected.png';
import db from "firebase-config";

const test = [
    {
        CW: 'MN-F-3034'
    }
]






export default function PopupGiveawayDashboard() {
    const [giveaway_dashboard, setgiveaway_dashboard] = useState({
        WARNING: "",
        PO: "",
        MH1: "",
        MH2: "",
        CW: "",
        Xray: "",
        MD: ""
    });
    const [keeplive, setkeeplive] = useState([]);
    const [no_data, setNo_data] = useState(false);

    useEffect(() => {
        async function fetchMyAPI() {
            await axios.get(`${apiUrl.GIVEAWAY_DASHBOARG_PAGE1}`, {
                "headers": {
                    "authorizationToken": "lFao}A!iCSg3v'sT{=*p*irT2f@#.X(9.1B:M9R'z*Qy=y\\F--Jme7EAQ){-A2x)",
                }
            }
            )
                .then(response => {
                    //console.log(response.data)
                    if (response.status == 200) {
                        sessionStorage.setItem('warning', response.data[0].WARNING);
                        sessionStorage.setItem('po', response.data[0].PO);
                        sessionStorage.setItem('mh1', response.data[0].MH1);
                        sessionStorage.setItem('mh2', response.data[0].MH2);
                        sessionStorage.setItem('cw', response.data[0].CW);
                        sessionStorage.setItem('xray', response.data[0]['X-Ray']);
                        sessionStorage.setItem('md', response.data[0].MD);
                        setgiveaway_dashboard({
                            WARNING: response.data[0].WARNING,
                            PO: response.data[0].PO,
                            MH1: response.data[0].MH1,
                            MH2: response.data[0].MH2,
                            CW: response.data[0].CW,
                            Xray: response.data[0]['X-Ray'],
                            MD: response.data[0].MD,
                        })
                        setNo_data(1)


                    }
                })
                .catch(error => {
                    setNo_data(2)
                    console.log('error: ', error)

                })
        }
        fetchMyAPI()
        const interval = setInterval(() => fetchMyAPI(), 1000)
        return () => {
            clearInterval(interval);
        }


    }, []);

    useEffect(() => {
        const getPostFromFirebase = [];
        const subscriber = db
            .collection("meskr_keeplive")
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    getPostFromFirebase.push({
                        ...doc.data(),
                        id: doc.id,
                    });
                });
                setkeeplive(getPostFromFirebase);
                let mul1 = getPostFromFirebase.find((user) => user.ID.includes("Further.4117.Multihead.Multihead1.Line7_1.0NoError"));
                sessionStorage.setItem('multihead1', mul1?.IS_CONNECTED);
                let mul2 = getPostFromFirebase.find((user) => user.ID.includes("Further.4117.Multihead.Multihead2.Line7_2.0NoError"));
                sessionStorage.setItem('multihead2', mul2?.IS_CONNECTED);
                let c_w = getPostFromFirebase.find((user) => user.ID.includes("Further.4117.IFORT.CW.Line7.Status"));
                sessionStorage.setItem('c_w', c_w?.VALUE);
                let x_ray = getPostFromFirebase.find((user) => user.ID.includes("Further.4117.IFORT.X_RAY.Line7.Status"));
                sessionStorage.setItem('x_ray', x_ray?.VALUE);
                let m_d = getPostFromFirebase.find((user) => user.ID.includes("Further.4117.IFORT.MD_B.Line7.Status"));
                sessionStorage.setItem('m_d', m_d?.VALUE);


            });

        // return cleanup function
        return () => subscriber();
    }, [keeplive]);



    const multihead1 = keeplive.find((user) => user.ID.includes("Further.4117.Multihead.Multihead1.Line7_1.0NoError"));
    const multihead2 = keeplive.find((user) => user.ID.includes("Further.4117.Multihead.Multihead2.Line7_2.0NoError"));
    const check_weihgt = keeplive.find((user) => user.ID.includes("Further.4117.IFORT.CW.Line7.Status"));
    const xray = keeplive.find((user) => user.ID.includes("Further.4117.IFORT.X_RAY.Line7.Status"));
    const md = keeplive.find((user) => user.ID.includes("Further.4117.IFORT.MD_B.Line7.Status"));

    //console.log(sessionStorage.getItem('multihead1') == 'false')
    //console.log(multihead1?.IS_CONNECTED)



    //console.log(giveaway_dashboard.Xray)

    /*let arrToObjectGiveaway = Object.keys(popup_giveaway).map(key => {
        return { name: key, value: popup_giveaway[key] }
    }); // Convert object to array object of key-values (https://stackoverflow.com/questions/74191727/javascript-convert-object-to-array-object)



    let filter_name = arrToObjectGiveaway.filter(i => i.name == 'MH1' || i.name == 'MH2' || i.name == 'CW' || i.name == 'X-Ray' || i.name == 'MD');

    let filter_po = arrToObjectGiveaway.filter(i => i.name == 'PO');*/

    const po = "['MN-F-3016']"

    const po_replace = giveaway_dashboard.PO == undefined ? '-' : giveaway_dashboard.PO.replace(/['[\]]/gi, '')

    const po_split = po_replace.split(" ")



    const po_split0 = po_split[0] == undefined ? '-' : po_split[0]

    const po_split1 = po_split[1] == undefined ? '-' : po_split[1]

    const po_split2 = po_split[2] == undefined ? '-' : po_split[2]


    const po_replace_old = sessionStorage.getItem('po') == undefined ? '-' : sessionStorage.getItem('po').replace(/['[\]]/gi, '')

    const po_split_old = po_replace_old.split(" ")

    const po_split0_old = po_split_old[0] == 'undefined' ? '-' : po_split_old[0]

    const po_split1_old = po_split_old[1] == 'undefined' ? '-' : po_split_old[1]

    const po_split2_old = po_split_old[2] == 'undefined' ? '-' : po_split_old[2]
  









    return (
        <div>
            <Backdrop
                sx={{ backgroundColor: "rgb(0, 0, 0, 0.85)", zIndex: (theme) => theme.zIndex.drawer + 1 }}

                open
            >
                <Grid
                    container spacing={1}
                    direction="row"
                    alignItems="top"
                    justifyContent="center"
                    style={{ minHeight: '60vh', maxHeight: '0vh', flexGrow: 1, paddingLeft: 25, paddingTop: 0 }}
                >

                    <Grid item lg={12} md={12} sm={12} xl={12} xs={12} style={{ position: "relative", top: '-10vw', left: '0vw', height: '1px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                            <img src={Warning} height="35px" />
                            <Typography style={{ color: '#ffffff', fontWeight: "bold", fontSize: 32, position: "relative" }} textAlign="center">
                                Online Status & Preset Setting
                            </Typography>
                        </Box>
                    </Grid>




                    <Grid container spacing={2} style={{ position: "relative", top: '-8vw', left: '0vw' }}>
                        <Grid id="top-row" container xs={8} md={8} lg={8} xl={8} style={{ position: "relative", padding: 3 }}>
                            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '-4vw', height: '12px' }}>
                                <Typography style={{ color: '#ffffff', fontSize: 25, position: "relative", top: "0vw", fontWeight: 'bold' }} >
                                    Machine
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ border: "2px dashed #4A4A49", borderRadius: 20, height: '170%' }}>

                                <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '1vw', height: '12px' }}>
                                    {(giveaway_dashboard.MH1 == undefined || sessionStorage.getItem('mh1') == 'undefined') && (multihead1?.VALUE == true || (sessionStorage.getItem('multihead1') == 'true') == true) ? '' :
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="left" >
                                            <Typography style={{ color: '#ffffff', fontSize: 22, position: "relative", top: "0vw", fontWeight: 'bold', width: '5%' }} >
                                                {multihead1?.VALUE == undefined ? ((sessionStorage.getItem('multihead1') == 'true') == false ? <img src={Disconnected} height="25px" /> : '') : (multihead1?.VALUE == false ? <img src={Disconnected} height="25px" /> : '')}
                                            </Typography>
                                            <Typography style={{ color: '#ffffff', fontSize: 22, position: "relative", top: "0vw", fontWeight: 'bold', width: '40%' }} >
                                                &nbsp;&nbsp;Multihead L7:
                                            </Typography>
                                            <Typography style={{ color: (no_data == 1 ? (po_split0  == giveaway_dashboard.MH1) || (po_split1 == giveaway_dashboard.MH1) || (po_split2 == giveaway_dashboard.MH1) : (po_split0_old  == sessionStorage.getItem('mh1')) || (po_split1_old == sessionStorage.getItem('mh1')) || (po_split2_old == sessionStorage.getItem('mh1')))  ? '#2e9281' : '#8c0e0e', fontSize: 22, position: "relative", top: "0vw", fontWeight: 'bold', width: '40%' }} >
                                                &nbsp;{no_data == 1 ? giveaway_dashboard.MH1 : sessionStorage.getItem('mh1')}
                                            </Typography>
                                        </Box>
                                    }

                                    {(giveaway_dashboard.MH2 == undefined || sessionStorage.getItem('mh2') == 'undefined') && (multihead2?.VALUE == true || (sessionStorage.getItem('multihead2') == 'true') == true) ? '' :
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="left" >
                                            <Typography style={{ color: '#ffffff', fontSize: 22, position: "relative", top: "0vw", fontWeight: 'bold', width: '5%' }} >
                                                {multihead2?.VALUE == undefined ? ((sessionStorage.getItem('multihead2') == 'true') == false ? <img src={Disconnected} height="25px" /> : '') : (multihead2?.VALUE == false ? <img src={Disconnected} height="25px" /> : '')}
                                            </Typography>
                                            <Typography style={{ color: '#ffffff', fontSize: 22, position: "relative", top: "0vw", fontWeight: 'bold', width: '40%' }} >
                                                &nbsp;&nbsp;Multihead L8:
                                            </Typography>
                                            <Typography style={{ color: (no_data == 1 ? (po_split0  == giveaway_dashboard.MH2) || (po_split1 == giveaway_dashboard.MH2) || (po_split2 == giveaway_dashboard.MH2) : (po_split0_old  == sessionStorage.getItem('mh2')) || (po_split1_old == sessionStorage.getItem('mh2')) || (po_split2_old == sessionStorage.getItem('mh2'))) ? '#2e9281' : '#8c0e0e', fontSize: 22, position: "relative", top: "0vw", fontWeight: 'bold', width: '40%' }} >
                                                &nbsp;{no_data == 1 ? giveaway_dashboard.MH2 : sessionStorage.getItem('mh2')}
                                            </Typography>
                                        </Box>
                                    }

                                    {(giveaway_dashboard.CW == undefined || sessionStorage.getItem('cw') == 'undefined') && check_weihgt?.VALUE != 0 && Number(sessionStorage.getItem('c_w')) != 0 ? '' :
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="left" >
                                            <Typography style={{ color: '#ffffff', fontSize: 22, position: "relative", top: "0vw", fontWeight: 'bold', width: '5%' }} >
                                                {check_weihgt?.VALUE == undefined ? (Number(sessionStorage.getItem('c_w')) == 0 ? <img src={Disconnected} height="25px" /> : '') : (check_weihgt?.VALUE == 0 ? <img src={Disconnected} height="25px" /> : '')}
                                            </Typography>
                                            <Typography style={{ color: '#ffffff', fontSize: 22, position: "relative", top: "0vw", fontWeight: 'bold', width: '40%' }} >
                                                &nbsp;&nbsp;Check Weight:
                                            </Typography>
                                            <Typography style={{ color: (no_data == 1 ? (po_split0 == giveaway_dashboard.CW)|| (po_split1 == giveaway_dashboard.CW) || (po_split2 == giveaway_dashboard.CW) : (po_split0_old  == sessionStorage.getItem('cw')) || (po_split1_old == sessionStorage.getItem('cw')) || (po_split2_old == sessionStorage.getItem('cw'))) ? '#2e9281' : '#8c0e0e', fontSize: 22, position: "relative", top: "0vw", fontWeight: 'bold', width: '40%' }} >
                                                &nbsp;{no_data == 1 ? giveaway_dashboard.CW : sessionStorage.getItem('cw')}
                                            </Typography>
                                        </Box>
                                    }

                                    {(giveaway_dashboard.Xray == undefined || sessionStorage.getItem('xray') == 'undefined') && xray?.VALUE != 0 && Number(sessionStorage.getItem('x_ray')) != 0 ? '' :
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="left" >
                                            <Typography style={{ color: '#ffffff', fontSize: 22, position: "relative", top: "0vw", fontWeight: 'bold', width: '5%' }} >
                                                {xray?.VALUE == undefined ? (Number(sessionStorage.getItem('x_ray')) == 0 ? <img src={Disconnected} height="25px" /> : '') : (xray?.VALUE == 0 ? <img src={Disconnected} height="25px" /> : '')}
                                            </Typography>
                                            <Typography style={{ color: '#ffffff', fontSize: 22, position: "relative", top: "0vw", fontWeight: 'bold', width: '40%' }} >
                                                &nbsp;&nbsp;X-Ray:
                                            </Typography>
                                            <Typography style={{ color: (no_data == 1 ? (po_split0 == giveaway_dashboard.Xray) || (po_split1 == giveaway_dashboard.Xray) || (po_split2 == giveaway_dashboard.Xray) : (po_split0_old  == sessionStorage.getItem('xray')) || (po_split1_old == sessionStorage.getItem('xray')) || (po_split2_old == sessionStorage.getItem('xray'))) ? '#2e9281' : '#8c0e0e', fontSize: 22, position: "relative", top: "0vw", fontWeight: 'bold', width: '40%' }} >
                                                &nbsp;{no_data == 1 ? giveaway_dashboard.Xray : sessionStorage.getItem('xray')}
                                            </Typography>
                                        </Box>
                                    }

                                    {(giveaway_dashboard.MD == undefined || sessionStorage.getItem('md') == 'undefined') && md?.VALUE != 0 && Number(sessionStorage.getItem('m_d')) != 0 ? '' :
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="left" >
                                            <Typography style={{ color: '#ffffff', fontSize: 22, position: "relative", top: "0vw", fontWeight: 'bold', width: '5%' }} >
                                                {md?.VALUE == undefined ? (Number(sessionStorage.getItem('m_d')) == 0 ? <img src={Disconnected} height="25px" /> : '') : (md?.VALUE == 0 ? <img src={Disconnected} height="25px" /> : '')}
                                            </Typography>
                                            <Typography style={{ color: '#ffffff', fontSize: 22, position: "relative", top: "0vw", fontWeight: 'bold', width: '40%' }} >
                                                &nbsp;&nbsp;Metal Detector:
                                            </Typography>
                                            <Typography style={{ color: (no_data == 1 ? (po_split0 == giveaway_dashboard.MD) || (po_split1 == giveaway_dashboard.MD) || (po_split2 == giveaway_dashboard.MD) : (po_split0_old  == sessionStorage.getItem('md')) || (po_split1_old == sessionStorage.getItem('md')) || (po_split2_old == sessionStorage.getItem('md'))) ? '#2e9281' : '#8c0e0e', fontSize: 22, position: "relative", top: "0vw", fontWeight: 'bold', width: '40%' }} >
                                                &nbsp;{no_data == 1 ? giveaway_dashboard.MD : sessionStorage.getItem('md')}
                                            </Typography>
                                        </Box>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid id="top-row" container xs={4} md={4} lg={4} xl={4} style={{ position: "relative", padding: 3 }}>
                            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '-4vw', height: '12px' }}>
                                <Typography style={{ color: '#ffffff', fontSize: 25, position: "relative", top: "0vw", fontWeight: 'bold' }} >
                                    PO
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ border: "2px dashed #4A4A49", borderRadius: 20, height: '170%' }}>
                                <Grid id="top-row" container xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '3.3vw' }}>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", top: '-2vw', height: '12px' }}>
                                        {giveaway_dashboard.PO == undefined || sessionStorage.getItem('po') == 'undefined' ? '' :
                                            (no_data == 1 ? po_split : po_split_old).map((item) => {
                                                const getColor = () => {
                                                    let color;
                                                    try {
                                                        if ((item == giveaway_dashboard.MH1) || (item == giveaway_dashboard.MH2) || (item == giveaway_dashboard.CW) || (item == giveaway_dashboard.Xray) || (item == giveaway_dashboard.MD)) {
                                                            color = "#2e9281"
                                                        } else 
                                                            color = "#ffffff"
                                                        return color;
                                                    }
                                                    catch {
                                                        console.log()
                                                    }
                                                }


                                                return (
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                        <Typography style={{ color: getColor(), fontSize: 22, position: "relative", top: "0vw", fontWeight: 'bold', width: '100%' }} >
                                                            {item}
                                                        </Typography>
                                                    </Box>
                                                )
                                            })
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>

            </Backdrop>
        </div>
    );
}