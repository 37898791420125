import { Navigate } from "react-router-dom";
import Home from "./pages/Home";
import MainLayout from "./components/layout/MainLayout";
import Layout from "./components/layout/Before/Layout";
import LayoutOnMobile from "./components/layout/LayoutOnMobile";
import Login from './pages/Login';
import Production from './pages/Production';
import ProductionLine from './pages/ProductionLine';
import Monitor from './pages/Monitor'; 
import Giveaway from './pages/Giveaway';
import Tumbler from './pages/Tumbler';
import Multihead from './pages/Multihead';
import Report from './pages/ReportYesterdayLine7';
import MainLayoutReportLine7 from "./components/layout/MainLayoutReportLine7";
import TargetLine7 from './pages/TargetLine7';
import TargetLine7Page2 from './pages/TargetLine7Page2';
import MainLayoutUnlock from "./components/layout/MainLayoutUnlock";
import Unlock from "./pages/Unlock";
import Setting from './pages/Setting';
import UserEdit from './components/setting/UserEdit.jsx';
import MainLayoutTargetLine7 from "./components/layout/MainLayoutTargetLine7";
import Register from "./pages/Register";
import Email from './pages/Email';
import Resetpw from './pages/Resetpw';
import Sent from './pages/Sent'; 
import CorrelationTest from './pages/CorrelationTest';
import TargetLine7inmonitor from './pages/TargetLine7inmonitor';
import TargetLine7Page2inmonitor from './pages/TargetLine7Page2inmonitor';
import GiveawayPage1 from './pages/GiveawayPage1';
import GiveawayPage2 from './pages/GiveawayPage2';
import GiveawayPage1_ver1 from './pages/GiveawayPage1_ver1';
import Qrcode from './pages/Qrcode';
import NotiGiveawayFunction from "./pages/NotiGiveawayFunction";
import HeatingYield from "./pages/HeatingYield";
import Preparation from "./pages/Preparation";
import MockupTargetLine7P1 from "./pages/MockupTargetLine7P1";
import MarinationTrackingP1 from "./pages/MarinationTrackingP1";
import MarinationTrackingP2 from "./pages/MarinationTrackingP2";
import MainLayoutMarinationTracking from "./components/layout/MainLayoutMarinationTracking"
import RTSP from "./pages/RTSP";
import MockupTargetLine7P2 from "./pages/MockupTargetLine7P2";
import MockupGiveawayP1 from "./pages/MockupGiveawayP1";
import MockupGiveawayP2 from "./pages/MockupGiveawayP2";


const routes = (isLoggedIn) => [
    // Need authen pages
    {
        path: "/",
        element: isLoggedIn ? <MainLayout /> : <Navigate to="/login" />,
        children: [
        { path: "home", element: <Home /> },
        { path: "production", element: <Production /> },
        { path: "productionLine", element: <ProductionLine /> },
        { path: "monitor", element: <Monitor /> },
        { path: "giveaway", element: <Giveaway /> },
        { path: (`${localStorage.getItem('rb_Admin')}` == 'true' && `${localStorage.getItem('rb_Staff')}` == 'true') ? "settings" : 'a', element: <Setting />},
        { path: "user-edit", element: <UserEdit /> },
        { path: (`${localStorage.getItem('rb_Admin')}` == 'true' && `${localStorage.getItem('rb_Staff')}` == 'true') ? "register" : 'b', element: <Register /> },
        
        { path: "/", element: <Navigate to="/home" /> },
        ],
    },

    // Need authen pages in report line7
    {
        path: "/",
        element: isLoggedIn ? <MainLayoutReportLine7 /> : <Navigate to="/login" />,
        children: [
        { path: "reportLine7", element: <Report /> },

        ],
    },

    /// On Mobile
    {
        path: "/",
        element: isLoggedIn ? <LayoutOnMobile /> : <Navigate to="/login" />,
        children: [
        //{ path: "preparation", element: <Tumbler /> },
        //{ path: "multihead", element: <Multihead /> },
        //{ path: "preparation_zone", element: <Qrcode /> },
        { path: "preparation", element: <Preparation /> },
        ],
    },

    // Authorize
    {
        path: "/",
        element: isLoggedIn ? <MainLayoutUnlock /> : <Navigate to="/login" />,
        children: [
        { path: "unlock", element: <Unlock /> },
        { path: "check_list", element: <CorrelationTest /> },
        ],
    },

    // No need authen pages
    {
        path: "/",
        element: !isLoggedIn ? <Layout /> : <Navigate to="/home" />,
        children: [
        { path: "login", element: <Login /> },
        { path: 'email', element: <Email /> },
        { path: 'reset-pw', element: <Resetpw /> },
        { path: 'sent', element: <Sent /> },
        { path: "/", element: <Navigate to="/home" /> },
        ],
    },

    // TargetLine7
    {
        path: "/",
        element: /*!isLoggedIn ?*/ <MainLayoutTargetLine7 /> /*: <Navigate to="/login" />*/,
        children: [
            { path: "target-line7/p1", element: <TargetLine7inmonitor />},
            { path: "target-line7/p2", element: <TargetLine7Page2inmonitor />},
            { path: "giveaway/p1", element: <GiveawayPage1 />},
            { path: "giveaway/p2", element: <GiveawayPage2 />},
            { path: "giveaway_v1/p1", element: <GiveawayPage1_ver1 />},
            { path: "status_giveaway_function", element: <NotiGiveawayFunction />},
            { path: "heating_yield", element: <HeatingYield />},
            { path: "monitor-target-line7/p1", element: <MockupTargetLine7P1 />},
            { path: "monitor-target-line7/p2", element: <MockupTargetLine7P2 />},
            { path: "monitor-giveaway/p1", element: <MockupGiveawayP1 />},
            { path: "monitor-giveaway/p2", element: <MockupGiveawayP2 />},
        ],
    },

    // TargetLine7
    {
        path: "/",
        element: /*!isLoggedIn ?*/ <MainLayoutMarinationTracking /> /*: <Navigate to="/login" />*/,
        children: [
            { path: "marination_tracking/p1", element: <MarinationTrackingP1 />},
            { path: "marination_tracking/p2", element: <MarinationTrackingP2 />},
            { path: "rtsp_stream", element: <RTSP />},
        ],
    },
];


export default routes;